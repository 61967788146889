import { Outlet, useLocation } from "react-router-dom"
import ScrollToTop from "../UI/ScrollToTop/ScrollToTop"
import Footer from "./RootComponents/Footer/Footer"
import Navbar from "./RootComponents/Navbar/Navbar"

const Layout = () => {
	let location = useLocation()
	let HeaderFooter = !(
		location.pathname === "/login" ||
		location.pathname === "/signup" ||
		location.pathname === "/signup/DEV" ||
		location.pathname.includes("/admin")
	)

	return (
		<>
			{HeaderFooter && <Navbar />}
			<ScrollToTop />
			<main className="App">
				<Outlet />
				{HeaderFooter && <Footer />}
			</main>
		</>
	)
}

export default Layout
