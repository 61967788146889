import React from "react";
import { NavLink } from "react-router-dom";
import "./NavItem.css";

const resolveLinkPath = (childTo, parentTo) => `${parentTo}/${childTo}`;

const NavItemHeader = (props) => {
	const { item, expanded, onExpandChange } = props;
	const { label, Icon, to: headerToPath, children } = item;

	return (
		<div>
			<button className=" navItemHeaderButton navItem" onClick={onExpandChange}>
				<div>
					<span className="navIcon">{Icon}</span>
					<span className="navLabel">{label}</span>
				</div>
			</button>

			{expanded && (
				<div className="navChildrenBlock">
					{children.map((item, index) => {
						const key = `${item.label}-${index}`;

						const { label, Icon, children } = item;

						if (children) {
							return (
								<div key={key}>
									<NavItemHeader
										item={{
											...item,
											to: resolveLinkPath(item.to, props.item.to),
										}}
									/>
								</div>
							);
						}

						return (
							<NavLink
								exact={`true`}
								key={key}
								to={resolveLinkPath(item.to, props.item.to)}
								className={({ isActive }) =>
									isActive ? "childActiveItem" : "childItem"
								}>
								<span className="navIcon">{Icon}</span>
								<span className="navLabel">{label}</span>
							</NavLink>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default NavItemHeader;
