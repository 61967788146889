export const options = [
	{ value: "figma", label: "figma" },
	{ value: "invision", label: "invision" },
	{ value: "adobexd", label: "adobexd" },
]

export const highlightOptions = [
	{ value: "Mobile Responsive", label: "Mobile Responsive" },
	{ value: "Pixel perfect", label: "Pixel perfect" },
	{ value: "Organized layers", label: "Organized layers" },
]

export const formOptions = {
	title: {
		required: "title is required",
		maxLength: {
			value: 55,
			message: "title cannot be more than 55 characters",
		},
		minLength: {
			value: 10,
			message: "title cannot be less than 10 characters",
		},
	},
	subtitle: {
		required: "subtitle is required",
		maxLength: {
			value: 55,
			message: "subtitle cannot be more than 55 characters",
		},
		minLength: {
			value: 10,
			message: "subtitle cannot be less than 10 characters",
		},
	},
	compatibility: { required: "compatibility is required" },
	highlights: { required: "highlights is required" },
	featured: { required: "featured is required" },
	license: {
		required: "license is required",
		maxLength: {
			value: 15,
			message: "license cannot be more than 15 characters",
		},
	},
	price: {
		required: "price is required",
	},
	discount: { required: "discount is required" },
	description: {
		required: "description is required",
		maxLength: {
			value: 90,
			message: "description cannot be more than 90 characters",
		},
		minLength: {
			value: 60,
			message: "subtitle cannot be less than 60 characters",
		},
	},
}
