import "./ProductCard.css"
import RedHeartSvg from "../../../../Assets/CollectionsAssets/ProductCardAssets/RedHeart.svg"
import DummyImage from "../../../../Assets/CollectionsAssets/ProductCardAssets/DummyImage.png"
import { Rating } from "../../../../UI/Rating/Rating.tsx"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { Average, formatNumber } from "../../../../helpers/utils"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import {
	addToStoreCart,
	addToStoreFavCart,
	getVisitorsCount,
	increaseVisitorCount,
	removeStoreFavCartItem,
} from "../../../../services/CartService"
import { useCart } from "../../../../hooks/useCart"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext.jsx"
import HeartSvg from "../../../../Assets/CollectionsAssets/ProductCardAssets/Heart.svg"
import bag from "../../../../Assets/CollectionsAssets/ProductCardAssets/bag.svg"
import eye from "../../../../Assets/CollectionsAssets/ProductCardAssets/eye.svg"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../../../services/firebase"
import { Timestamp } from "firebase/firestore"
import { useEffect } from "react"
import { useState } from "react"

const ProductCard = ({ product }) => {
	let navigate = useNavigate()
	const { currentUser } = useAuthValue()
	const { enqueueSnackbar } = useSnackbar()
	const { addToCart, addFavItem, favItems, removeFavItem, cartItems } =
		useCart()

	const [geoCoordinates, setGeoCoordinates] = useState({})

	const isInCart = () =>
		!!cartItems?.find((item) => item.productId === product.productId)

	const isInFavCart = () =>
		!!favItems?.find((item) => item.productId === product.productId)

	const onAddClick = () => {
		logEvent(analytics, `add ${product.title} clicked`)

		if (currentUser) {
			enqueueSnackbar("Item added successfully", {
				variant: "success",
			})
			addToStoreCart({
				...product,
				userId: currentUser?.uid,
			})
			addToCart({ ...product, userId: currentUser?.uid })
		} else {
			navigate("/login")
		}
	}

	const onAddFavClick = () => {
		logEvent(analytics, `add favorite ${product.title} clicked`)

		if (currentUser) {
			enqueueSnackbar("Item added to favourites", {
				variant: "success",
			})
			addFavItem({ ...product, userId: currentUser?.uid })
			addToStoreFavCart({
				...product,
				userId: currentUser?.uid,
			})
		} else {
			navigate("/login")
		}
	}

	const onRemoveFavClick = () => {
		removeFavItem(product)
		removeStoreFavCartItem(product.productId)
		enqueueSnackbar("Item removed from favourites", {
			variant: "info",
		})
	}

	// useEffect(() => {
	// 	if (navigator?.geolocation) {
	// 		navigator.geolocation.getCurrentPosition((location) => {
	// 			if (location) setGeoCoordinates(location.coords)
	// 		})
	// 	}
	// }, [])

	const onViewClick = () => {
		getVisitorsCount().then((res) => {
			let visitorsArray = res
			let visitorsCount = visitorsArray.find(
				(item) => item.productId === product.productId
			)?.totalVisitors

			let visitors = visitorsArray.find(
				(item) => item.productId === product.productId
			)?.visitedAt

			if (visitorsCount === undefined) {
				increaseVisitorCount({
					productId: product.productId,
					totalVisitors: 1,
					visitedAt: [
						{
							user: currentUser?.uid ? currentUser?.uid : null,
							time: Timestamp.fromDate(new Date()),
							location: {
								lat: geoCoordinates?.latitude
									? geoCoordinates?.latitude
									: null,
								long: geoCoordinates?.longitude
									? geoCoordinates?.longitude
									: null,
							},
						},
					],
				})
			} else {
				increaseVisitorCount({
					productId: product.productId,
					totalVisitors: visitorsCount + 1,
					visitedAt: [
						...visitors,
						{
							user: currentUser?.uid ? currentUser?.uid : null,
							time: Timestamp.fromDate(new Date()),
							location: {
								lat: geoCoordinates?.latitude
									? geoCoordinates?.latitude
									: null,
								long: geoCoordinates?.longitude
									? geoCoordinates?.longitude
									: null,
							},
						},
					],
				})
			}
		})

		navigate(`/buycollection/${product.productId}`)
	}

	return (
		<div className="card-container">
			<div className="card-media" style={{ padding: "0px" }}>
				<LazyLoadImage
					loading="lazy"
					className="card-image"
					alt={"ProductImage"}
					placeholder={<img src={DummyImage} alt="dummy-pic" />}
					height="100%"
					delayTime={1500}
					width="100%"
					src={product.thumbnail}
				/>
				<div className="hover_buttons">
					<button
						title="View Product"
						className="hover_eye"
						onClick={() => onViewClick(product)}
					>
						<img className="eye_image" src={eye} alt={"eye"} />
					</button>

					{!isInCart(product) && (
						<button
							title="Add Product"
							className="hover_cart"
							onClick={() => onAddClick()}
						>
							<img className="cart_image" src={bag} alt="cart" />
						</button>
					)}
				</div>
			</div>
			<div className="card-header">
				<Rating
					readonly={true}
					ratingValue={
						product?.productRatings
							? Average(
									product?.productRatings?.map(
										(item) => item.rating
									)
							  )
							: 0
					}
					size={20}
					transition
				/>
			</div>
			<div
				className="card-content"
				onClick={() => navigate(`/buycollection/${product.productId}`)}
			>
				<div className="card-title">{product.title}</div>
				<button>{formatNumber(product?.price)}</button>
			</div>
			<div className="card-actions">
				{product?.compatibility?.map((data) => (
					<div
						className="card-tags"
						key={data}
						style={{
							backgroundColor: "#8ad0711f",
						}}
					>
						{data}
					</div>
				))}
			</div>

			{!isInFavCart(product) && (
				<img
					onClick={() => onAddFavClick()}
					title="Add to favorites"
					className="product-heart"
					alt="HeartSvg"
					src={HeartSvg}
				/>
			)}
			{isInFavCart(product) && (
				<img
					onClick={() => onRemoveFavClick()}
					title="remove from favorites"
					className="product-heart"
					alt="RedHeartSvg"
					src={RedHeartSvg}
				/>
			)}
		</div>
	)
}

export default ProductCard
