import "./CartList.css"
import { useSnackbar } from "notistack"
import { useCart } from "../../../../hooks/useCart"
import { formatNumber } from "../../../../helpers/utils"
import { TrashIcon } from "../../../../Assets/CartAssets/icons"
import { removeStoreCartItem } from "../../../../services/CartService"
import Loader from "../../../../UI/Loader/Loader"

const CartList = () => {
	const { removeFromCart, cartItems } = useCart()
	const { enqueueSnackbar } = useSnackbar()

	const onDeleteClick = (product) => {
		removeFromCart(product)
		removeStoreCartItem(product.productId)
		enqueueSnackbar("Item removed from cart", {
			variant: "info",
		})
	}

	return (
		<div>
			{cartItems.map((product) => (
				<div className="cart-item" key={product.productId}>
					<img
						className="cart-item-image-1"
						alt="ProductImage"
						src={
							product?.thumbnail ||
							"https://picsum.photos/500/300"
						}
					/>
					<div className="item-details">
						<p className="cart-item-text">{product?.title}</p>
						<p className="cart-item-secondary-text">
							{product?.subtitle}
						</p>
						<p className="cart-item-secondary-text">
							{
								"Published by Hello Picko in Figma Figma Figma Figma sdffd Published by Hello Picko Hello d"
							}
						</p>
						<p className="license">
							License:
							<span>{product?.license}</span>
						</p>
					</div>

					<div className="list-item-price">
						{formatNumber(product?.price)}
					</div>

					<div className="action-button">
						<button
							className="decrease"
							onClick={() => onDeleteClick(product)}
						>
							<TrashIcon width={"2rem"} />
						</button>
					</div>
				</div>
			))}
		</div>
	)
}

export default CartList
