import { useState } from "react"

export const ReadMore = ({ children, size = 40 }) => {
	const text = children
	const [isReadMore, setIsReadMore] = useState(true)
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore)
	}

	return (
		<p className="text">
			{text.length > size ? (
				<>
					{isReadMore ? text.slice(0, size) : text}
					<span onClick={toggleReadMore} className="read-or-hide">
						{isReadMore ? " ...read more" : " show less"}
					</span>
				</>
			) : (
				text
			)}
		</p>
	)
}
