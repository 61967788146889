import {
	addDoc,
	collection,
	doc,
	Timestamp,
	updateDoc,
} from "firebase/firestore"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { db } from "../../../services/firebase"
import FileField from "../Dashboard/Projects/FileField"

const CreateBlog = () => {
	const { enqueueSnackbar } = useSnackbar()
	let navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [coverImageUrl, setCoverImageUrl] = useState("")

	const params = useParams()
	const { id } = params
	const isAddMode = !id

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({ mode: "onChange" })

	const { state } = useLocation()

	useEffect(() => {
		if (!isAddMode) {
			if (state) {
				const { blog } = state

				const fields = ["title", "subtitle", "blogLink"]

				fields.forEach((field) => {
					setValue(field, blog[field])
				})

				setCoverImageUrl(blog["coverImageUrl"])
			}
		}
	}, [isAddMode, params.id, setValue, state])

	const onFormSubmit = async (blog) => {
		setLoading(true)

		let blogData = {
			...blog,
			coverImageUrl,
			publish: false,
			created: Timestamp.fromDate(new Date()),
		}

		if (!isAddMode) {
			blogData = {
				...blog,
				coverImageUrl,
				publish: true,
				updated: Timestamp.fromDate(new Date()),
			}

			try {
				await updateDoc(doc(db, "blogs", id), blogData).then(() => {
					setLoading(false)
					enqueueSnackbar("blog updated successfully", {
						variant: "success",
					})
					navigate("/admin/sub/blogsList")
				})
			} catch (err) {
				setLoading(false)
				enqueueSnackbar(err, {
					variant: "error",
				})
			}
		} else {
			try {
				await addDoc(collection(db, "blogs"), blogData).then(() => {
					setLoading(false)
					enqueueSnackbar("blog added successfully", {
						variant: "success",
					})
					navigate("/admin/sub/blogsList")
				})
			} catch (err) {
				setLoading(false)
				enqueueSnackbar(err, {
					variant: "error",
				})
			}
		}
	}

	const blogFormOptions = {
		title: {
			required: "title is required",
			maxLength: {
				value: 55,
				message: "title cannot be more than 55 characters",
			},
			minLength: {
				value: 10,
				message: "title cannot be less than 10 characters",
			},
		},
		subtitle: {
			required: "subtitle is required",
			maxLength: {
				value: 55,
				message: "subtitle cannot be more than 55 characters",
			},
			minLength: {
				value: 10,
				message: "subtitle cannot be less than 10 characters",
			},
		},
		blogLink: {
			required: "blogLink is required",
		},
	}

	return (
		<div className="container">
			<form onSubmit={handleSubmit(onFormSubmit)}>
				<div className="row">
					<div className="col-25">
						<label htmlFor="title">Title</label>
						<input
							type="text"
							id="title"
							name="title"
							placeholder="Enter Product title"
							{...register("title", blogFormOptions.title)}
						/>
						<small className="text-danger">
							{errors?.title && errors.title.message}
						</small>
					</div>
					<div className="col-25">
						<label htmlFor="subtitle">Subtitle</label>
						<input
							type="text"
							id="subtitle"
							name="subtitle"
							placeholder="Enter Subtitle"
							{...register("subtitle", blogFormOptions.subtitle)}
						/>
						<small className="text-danger">
							{errors?.subtitle && errors.subtitle.message}
						</small>
					</div>
				</div>

				<div className="row">
					<div className="col-25">
						<label htmlFor="blogLink">Blog Link</label>
						<input
							type="url"
							id="blogLink"
							name="blogLink"
							placeholder="Enter blog link"
							{...register("blogLink", blogFormOptions.blogLink)}
						/>
						<small className="text-danger">
							{errors?.blogLink && errors.blogLink.message}
						</small>
					</div>
					<div className="col-25">
						<FileField
							label="Upload Cover File"
							accept={".png, .jpeg, .jpg"}
							multiple={false}
							setProjectZip={setCoverImageUrl}
							uploadButton={true}
						/>
						{!isAddMode ? (
							<img
								src={coverImageUrl}
								alt="cover-img"
								width={200}
							/>
						) : null}
					</div>
				</div>

				<div className="row" style={{ textAlign: "center" }}>
					<button
						type="submit"
						className={`submit-button ${
							loading ? `button--loading` : ``
						}`}
					>
						<span className="button__text">
							{isAddMode ? "ADD" : "SAVE"}
						</span>
					</button>
				</div>
			</form>
		</div>
	)
}

export default CreateBlog
