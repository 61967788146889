import "./ProductHeading.css"
import figma from "../../../../Assets/BuyCollectionAssets/ProductHeadingAssets/figma.svg"
import invision from "../../../../Assets/BuyCollectionAssets/CompatibilityAssets/invision.png"
import { formatNumber } from "../../../../helpers/utils"
import { useSnackbar } from "notistack"
import { useCart } from "../../../../hooks/useCart"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"
import { useNavigate } from "react-router-dom"

const ProductHeading = ({ product }) => {
	const { enqueueSnackbar } = useSnackbar()
	const { currentUser } = useAuthValue()
	const { addToCart, cartItems } = useCart()
	let navigate = useNavigate()

	const isInCart = (product) => {
		return !!cartItems.find(
			(item) => item?.productId === product?.productId
		)
	}

	return (
		<div className="ProductHeading">
			<div className="product_name">
				<p className="name">{product?.title}</p>
				<p className="desc_title">{product?.subtitle}</p>
				<div className="product_desc">
					<p>{product?.description}</p>
					<p className="product-tags">
						{product?.compatibility?.includes("figma") && (
							<img src={figma} alt="figma" />
						)}
						{product?.compatibility?.includes("invision") && (
							<img src={invision} alt="invision" />
						)}
					</p>
				</div>

				{product?.featured && (
					<button
						className="featured_product"
						style={{ cursor: "default" }}
					>
						Featured Product
					</button>
				)}
			</div>

			<div className="product_price">
				<p>{formatNumber(product?.price)}</p>

				<div className="product_buttons">
					{/* <button title="comments" className="btn_black">
						<img src={comment} alt="comments" />
						{product?.comments}
					</button> */}
					{/* <button title="likes" className="btn_black">
						<img src={likes} alt="likes" />
						{product?.likes}
					</button> */}
					{/* <button title="preview" className="btn_preview">
						Preview
					</button> */}
					<button
						style={{
							cursor: isInCart(product) ? "default" : "cursor",
						}}
						className="btn_addToCart"
						disabled={isInCart(product)}
						onClick={() => {
							if (currentUser) {
								if (isInCart(product)) {
									enqueueSnackbar("product already in cart", {
										variant: "info",
									})
								} else {
									enqueueSnackbar("Item added successfully", {
										variant: "success",
									})
									addToCart(product, currentUser?.uid)
								}
							} else {
								navigate("/login")
							}
						}}
					>
						{isInCart(product) ? `Added to cart` : `Add to cart`}
					</button>
				</div>
			</div>
		</div>
	)
}

export default ProductHeading
