import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { FiEdit2, FiPlusCircle } from "react-icons/fi"
import { RiDeleteBinLine } from "react-icons/ri"
import DefaultImage from "../../../Assets/HomePageAssets/TeamsAssets/DefaultMemberImage.svg"
import Input from "../../../Pages/SignupSignin/Components/InputField/Input"
import { storage } from "../../../services/firebase"
import CustomModal from "../../../UI/CustomModal/CustomModal"
import Loader from "../../../UI/Loader/Loader"
import Paginations from "../../../UI/Pagination/Pagination"
import {
	createMember,
	deleteMember,
	getTeamMembers,
	updateMember,
} from "../../AdminServices/AdminServices"
import "./Teams.css"

const tableHeaders = ["Name", "Designation", "Actions"]

const Teams = () => {
	const [imageUrl, setImageUrl] = useState("")
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [modalData, setModalData] = useState(false)
	const [tableData, setTableData] = useState([])
	const [loading, setLoading] = useState(false)
	const [imageRequired, setImageRequired] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onChange" })

	const [currentPage, setCurrentPage] = useState(1)
	let NUM_OF_RECORDS = tableData.length
	let LIMIT = 5

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = tableData.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	const onFormSubmit = async (memberData) => {
		if (!imageUrl) {
			setImageRequired(true)
			return
		}
		memberData["imageUrl"] = imageUrl
		try {
			setLoading(true)
			if (modalData) {
				updateMember(modalData.memberId, memberData).then(() => {
					setEditModal(false)
					setModalData(false)
					setImageUrl(false)
					getSetMembers()
					enqueueSnackbar("team member updated successfully", {
						variant: "success",
					})
					setLoading(false)
				})
			} else {
				createMember(memberData).then(() => {
					getSetMembers()
					setEditModal(false)
					setModalData(false)
					setImageUrl(false)
					enqueueSnackbar("team member added successfully", {
						variant: "success",
					})
					setLoading(false)
				})
			}
		} catch (err) {
			enqueueSnackbar("error sending message", {
				variant: "error",
			})
		}
	}

	useEffect(() => {
		getSetMembers()
	}, [])

	const getSetMembers = () => {
		let tempArray = getTeamMembers()
		tempArray.then((res) => setTableData(res))
	}

	const onErrors = (errors) => console.error(errors)
	const hiddenFileInput = useRef(null)

	const handleClick = (event) => {
		event.preventDefault()
		hiddenFileInput.current.click()
	}

	const onEditModal = (memberData) => {
		reset(memberData)
		setImageUrl(memberData.imageUrl)
		setModalData(memberData)
		setEditModal(true)
	}

	const onDeleteModal = (id) => {
		setDeleteModal(true)
		setModalData(id)
	}

	const UploadFile = (file) => {
		if (!file) return
		const storageRef = ref(storage, `/files/${file.name}`)
		const uploadTask = uploadBytesResumable(storageRef, file)

		uploadTask.on(
			"state_changed",
			() => {},
			(err) => {
				console.log(err)
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((url) => {
					setImageUrl(url)
					console.log(url)
				})
			}
		)
	}

	const handleFileChange = (event) => {
		const fileUploaded = event.target.files[0]
		UploadFile(fileUploaded)
	}

	let renderEditModal = (editData = false) => {
		return (
			<CustomModal
				title={false}
				footer={false}
				onClose={() => setEditModal(false)}
				show={editModal}
			>
				<div className="input_conatiner">
					<form
						className="teams_form"
						onSubmit={handleSubmit(onFormSubmit, onErrors)}
					>
						<div className="custom_modal_image_container">
							<img
								alt="custom_modal_image"
								className="custom_modal_image"
								src={
									editData.imageUrl
										? editData.imageUrl
										: imageUrl
										? imageUrl
										: DefaultImage
								}
							/>

							<button
								className="edit-photo-btn"
								onClick={handleClick}
							>
								<a>
									{imageUrl
										? "Edit Profile Photo"
										: "Add Profile photo"}
								</a>
							</button>
							{imageRequired ? (
								<small
									style={{
										fontSize: "0.65rem",
										color: "red",
									}}
								>
									Image Required
								</small>
							) : null}
							<input
								onChange={handleFileChange}
								ref={hiddenFileInput}
								type="file"
								style={{ display: "none" }}
							/>
						</div>

						<Input
							label="Name"
							defaultValue={editData?.name}
							rules={{
								required: "Name is required",
								maxLength: 30,
							}}
							control={control}
							name="name"
							placeholder="Enter name"
							error={errors?.name && errors.name.message}
						/>
						<Input
							label="Designation"
							defaultValue={editData?.designation}
							rules={{
								required: "Designation is required",
								maxLength: 30,
							}}
							control={control}
							name="designation"
							placeholder="Enter Designation"
							error={
								errors?.designation &&
								errors.designation.message
							}
						/>
						<Input
							label="Medium"
							defaultValue={editData ? editData.medium : ""}
							control={control}
							rules={{
								pattern: {
									value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
									message: "Not Valid",
								},
							}}
							name="medium"
							placeholder="Enter medium"
							error={errors?.medium && errors.medium.message}
						/>
						<Input
							label="LinkedIn"
							defaultValue={editData ? editData.linkedin : ""}
							control={control}
							rules={{
								pattern: {
									value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
									message: "Not Valid",
								},
							}}
							name="linkedin"
							placeholder="Enter LinkedIn"
							error={errors?.linkedin && errors.linkedin.message}
						/>
						<Input
							label="Stack Overflow"
							defaultValue={
								editData ? editData.stackoverflow : ""
							}
							control={control}
							rules={{
								pattern: {
									value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
									message: "Not Valid",
								},
							}}
							name="stackoverflow"
							placeholder="Enter Stack Overflow"
							error={
								errors?.stackoverflow &&
								errors.stackoverflow.message
							}
						/>
						<Input
							label="Github"
							defaultValue={editData ? editData.github : ""}
							control={control}
							rules={{
								pattern: {
									value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
									message: "Not Valid",
								},
							}}
							name="github"
							placeholder="Enter Github"
							error={errors?.github && errors.github.message}
						/>
						<button
							className={`teams_form-btn ${
								loading ? `button--loading` : ``
							}`}
						>
							<span className="button__text">Save</span>
						</button>
					</form>
				</div>
			</CustomModal>
		)
	}

	return (
		<div className="teams_container">
			<div className="teams_actions">
				<h2>Team Members</h2>
				<button
					onClick={() => {
						setEditModal(true)
						reset({})
						setImageUrl("")
						setModalData(false)
					}}
				>
					<FiPlusCircle className="action_btn_icon" />
					Add Member
				</button>
			</div>
			<div className="teams_table">
				<table>
					<thead style={{ backgroundColor: "#454FDEA1" }}>
						<tr>
							{tableHeaders.map((header, index) => (
								<th key={index}>{header}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentData.length ? (
							currentData.map((member) => (
								<tr key={member.memberId}>
									<td>{member.name}</td>
									<td>{member.designation}</td>
									<td>
										<button
											onClick={() => {
												onEditModal(member)
											}}
										>
											<FiEdit2
												size={16}
												color="#6A8DF3"
											/>
										</button>
										<button
											onClick={() => {
												onDeleteModal(member.memberId)
											}}
										>
											<RiDeleteBinLine
												size={16}
												color="#6A8DF3"
											/>
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={3}>
									<Loader />
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<div className="pagination-wrapper">
				<Paginations
					totalRecords={NUM_OF_RECORDS}
					pageLimit={LIMIT}
					pageNeighbours={2}
					onPageChanged={onPageChanged}
					currentPage={currentPage}
				/>
			</div>
			{renderEditModal(modalData)}
			<CustomModal
				footer={true}
				show={deleteModal}
				primaryActionName="Yes"
				primaryAction={() => {
					deleteMember(modalData)
						.then(() => {
							getSetMembers()
							setDeleteModal(false)
							enqueueSnackbar(
								"team member deleted successfully",
								{
									variant: "success",
								}
							)
						})
						.catch((err) => {
							alert(err)
						})
				}}
				secondaryAction={() => {
					setDeleteModal(false)
				}}
				secondaryActionName="No"
			>
				<div className="delete-modal-content">
					<h2>Are you sure want delete?</h2>
				</div>
			</CustomModal>
		</div>
	)
}

export default Teams
