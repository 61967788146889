import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore"
import { db } from "../../services/firebase"

export const getMessages = async () => {
	const q = query(collection(db, "messages"))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		tempArray.push(obj)
	})

	return tempArray
}

export const getSubscriptions = async () => {
	const q = query(collection(db, "subscriptions"))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		tempArray.push(obj)
	})

	return tempArray
}

export const getPayments = async () => {
	const q = query(collection(db, "payments"))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		tempArray.push(obj)
	})

	return tempArray
}

export const getBlogs = async () => {
	const q = query(collection(db, "blogs"))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		obj["id"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}

export const getBlogsByUserId = async (userId) => {
	const q = query(collection(db, "products"), where("userId", "==", userId))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		obj["blogId"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}

export const removeBlog = async (id) => {
	const taskDocRef = doc(db, "blogs", id)
	try {
		await deleteDoc(taskDocRef)
	} catch (err) {
		alert(err)
	}
}

export const getUsers = async () => {
	const q = query(collection(db, "users"))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		obj["id"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}

//team services

export const getTeamMembers = async () => {
	const q = query(collection(db, "team_members"))
	const querySnapshot = await getDocs(q)
	let tempArray = []
	querySnapshot.forEach((doc) => {
		let obj = doc.data()
		obj["memberId"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}

export const createMember = async (memberData) => {
	try {
		await addDoc(collection(db, "team_members"), memberData)
	} catch (err) {
		alert(err)
	}
}

export const deleteMember = async (id) => {
	const taskDocRef = doc(db, "team_members", id)
	try {
		await deleteDoc(taskDocRef)
	} catch (err) {
		alert(err)
	}
}

export const updateMember = async (id, memberData) => {
	const taskRef = doc(db, "team_members", id)
	try {
		await updateDoc(taskRef, memberData)
	} catch (err) {
		alert(err)
	}
}
