import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	setDoc,
	where,
} from "firebase/firestore"
import { db } from "./firebase"

export const addToStoreCart = async (product) => {
	try {
		await setDoc(doc(db, "cartItems", product.productId), product)
	} catch (err) {
		alert(err)
	}
}

export const removeStoreCartItem = async (id) => {
	const taskDocRef = doc(db, "cartItems", id)
	try {
		await deleteDoc(taskDocRef)
	} catch (err) {
		alert(err)
	}
}

export const removeProduct = async (id) => {
	const taskDocRef = doc(db, "products", id)
	try {
		await deleteDoc(taskDocRef)
	} catch (err) {
		alert(err)
	}
}

export const getCartItems = async (userId) => {
	const q = query(collection(db, "cartItems"), where("userId", "==", userId))
	const querySnapshot = await getDocs(q)
	let tempArray = []

	querySnapshot.forEach((doc) => {
		let obj = doc.data()
		tempArray.push(obj)
	})

	return tempArray
}

export const deleteStoreCart = async (db, collectionPath, userId) => {
	const paymentsRef = collection(db, collectionPath)
	const q = query(paymentsRef, where("userId", "==", userId))
	const querySnapshot = await getDocs(q)

	querySnapshot.forEach((doc) => {
		removeStoreCartItem(doc.id)
	})
}

export const increaseVisitorCount = async (product) => {
	try {
		await setDoc(doc(db, "visitorsArray", product.productId), product, {
			merge: true,
		})
	} catch (err) {
		alert(err)
	}
}

export const getVisitorsCount = async () => {
	const q = query(collection(db, "visitorsArray"))
	const querySnapshot = await getDocs(q)
	let tempArray = []

	querySnapshot.forEach((doc) => {
		let obj = doc.data()
		tempArray.push(obj)
	})

	return tempArray
}

//favItems

export const addToStoreFavCart = async (product) => {
	try {
		await setDoc(doc(db, "favItems", product.productId), product)
	} catch (err) {
		alert(err)
	}
}

export const removeStoreFavCartItem = async (id) => {
	const taskDocRef = doc(db, "favItems", id)
	try {
		await deleteDoc(taskDocRef)
	} catch (err) {
		alert(err)
	}
}

export const getFavItems = async (userId) => {
	const q = query(collection(db, "favItems"), where("userId", "==", userId))
	const querySnapshot = await getDocs(q)
	let tempArray = []

	querySnapshot.forEach((doc) => {
		let obj = doc.data()
		tempArray.push(obj)
	})

	return tempArray
}
