import { addDoc, collection, Timestamp } from "firebase/firestore"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthValue } from "../../contexts/AuthContext/AuthContext"
import { formatNumber } from "../../helpers/utils"
import { useCart } from "../../hooks/useCart"
import { deleteStoreCart } from "../../services/CartService"
import { db } from "../../services/firebase"
import { sendEmail } from "../../services/EmailService"
import "./PaymentComponent.css"
import { getUser } from "../../services/OtherServices"

const PaymentComponent = () => {
	const [processing, setProcessing] = useState(false)

	const { currentUser } = useAuthValue()
	const { enqueueSnackbar } = useSnackbar()
	const { total, handleCheckout, cartItems } = useCart()

	let projectLinks = cartItems.map((item) => item.projectZip)
	let projectStrings = projectLinks.join(" ")
	const [user, setUser] = useState()

	let navigate = useNavigate()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		getUser(currentUser?.uid)
			.then((data) => {
				setUser(data[0])
			})
			.catch((err) => err)
	}, [currentUser?.uid])

	const paymentProcess = () => {
		let options = {
			key: `${process.env.REACT_APP_RZP_ID}`,
			amount: Math.round(total) * 100,
			currency: "INR",
			name: "Flutterion",
			description: "Flutterion payment gateway",
			image: "logo.png",

			handler: (response) => {
				savetoDB(response)
			},
			prefill: {
				name: currentUser.displayName,
				email: currentUser.email,
				contact: currentUser.phoneNumber,
			},
			notes: {
				address: "note value",
			},
		}
		let propay = new window.Razorpay(options)
		propay.open()
		propay.on("payment.failed", (response) => {
			enqueueSnackbar(response.error.reason, {
				variant: "error",
			})
		})
	}

	const savetoDB = async (response) => {
		setProcessing(true)
		await addDoc(collection(db, "payments"), {
			payment_id: response.razorpay_payment_id,
			date: Timestamp.fromDate(new Date()),
			userId: currentUser?.uid,
			products: cartItems,
		})
			.then(() => {
				handleCheckout()
				sendEmail({
					subject: "Payment successfull! download the product now!",
					firstName: user ? user?.firstName : currentUser.displayName,
					email: user ? user?.email : currentUser.email,
					projectLinks: projectStrings,
				})
				setProcessing(false)
				deleteStoreCart(db, "cartItems", currentUser?.uid)
				navigate("/marketplace")
				enqueueSnackbar(
					"Payment Done Successfully, please check your email",
					{
						variant: "success",
					}
				)
			})
			.catch((error) => {
				setProcessing(false)
				enqueueSnackbar(error, {
					variant: "error",
				})
			})
	}

	return processing ? (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				padding: "135px",
			}}
		>
			<div className="payment-loader"></div>
			<div>Processing payment....</div>
		</div>
	) : (
		<main className="page payment-page">
			<section className="payment-form dark">
				<div className="container">
					<div className="block-heading">
						<h2>Payment</h2>
					</div>
					<form>
						<div className="products">
							<div
								style={{ fontSize: "24px", fontWeight: "bold" }}
							>
								Total
								<span className="price">
									{formatNumber(total)}
								</span>
							</div>
						</div>
						<div
							className="card-details"
							style={{
								textAlign: "center",
							}}
						>
							<div className="row">
								<div>
									<button
										type="button"
										onClick={(e) => paymentProcess(e)}
										style={{
											backgroundColor: "green",
											padding: "10px",
											fontSize: "large",
										}}
									>
										Proceed
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
		</main>
	)
}

export default PaymentComponent
