import emailjs from "@emailjs/browser"

export const sendEmail = (templateParams) => {
	emailjs
		.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID,
			process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
			templateParams,
			process.env.REACT_APP_EMAILJS_PUBLIC_KEY
		)
		.then(
			(result) => {
				console.log("mail sent sucessfully!")
			},
			(error) => {
				console.log("error in sending mail!", error.text)
			}
		)
}
