export const fields = [
	"title",
	"subtitle",
	"compatibility",
	"highlights",
	"featured",
	"license",
	"price",
	"description",
]
