import React, { useEffect, useState } from "react"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { FaGithub, FaLinkedin, FaMedium, FaStackOverflow } from "react-icons/fa"
import { useLocation } from "react-router-dom"
import { getTeamMembers } from "../../../../admin/AdminServices/AdminServices"
import DefaultMemberImage from "../../../../Assets/HomePageAssets/ServiceAssets/ServicePNG.webp"
import Loader from "../../../../UI/Loader/Loader"
import Headline from "../Headline/Headline"
import "./Team.css"

const Team = () => {
	let location = useLocation()
	const [teamsArr, setTeamsArr] = useState([])
	const [firstMember, setFirstMember] = useState(0)
	const [lastMember, setlastMember] = useState(4)

	let teams = Object.values(teamsArr)
	let totalMembers = teams.length
	let currentMembers

	const handlePrevious = () => {
		if (firstMember !== 0) {
			setFirstMember((firstMember) => firstMember - 1)
			setlastMember((lastMember) => lastMember - 1)
		}
	}

	const handleNext = () => {
		if (lastMember !== totalMembers) {
			setFirstMember((firstMember) => firstMember + 1)
			setlastMember((lastMember) => lastMember + 1)
		}
	}

	useEffect(() => {
		getTeamMembers()
			.then((res) => {
				setTeamsArr(res)
			})
			.catch((err) => {
				alert(err)
			})
	}, [])

	currentMembers =
		location.pathname === "/team"
			? teamsArr
			: teams.slice(firstMember, lastMember)

	return (
		<section id="team">
			<Headline bgText="team" mainText="Creative Team" subText="" />
			<div className={location.pathname === "/team" ? "teams2" : "teams"}>
				{location.pathname !== "/team" && (
					<button className="btn_previous" onClick={handlePrevious}>
						<AiOutlineArrowLeft />
					</button>
				)}

				{currentMembers.length ? (
					<div
						className={
							location.pathname === "/team"
								? "teamGrid"
								: "teamList"
						}
					>
						{currentMembers.length &&
							currentMembers.map((member, i) => (
								<div key={i} className="member">
									<div className="member_image">
										<img
											src={
												member.imageUrl
													? member.imageUrl
													: DefaultMemberImage
											}
											alt="member_image"
										/>
									</div>
									<p className="member_name">{member.name}</p>
									<span className="member_designation">
										{member.designation}
									</span>
									<div className="socials">
										{member.medium && (
											<FaMedium
												onClick={() => {
													window.open(
														member.medium,
														"_blank"
													)
												}}
												size={18}
												color="gray"
											/>
										)}
										{member.stackoverflow && (
											<FaStackOverflow
												onClick={() => {
													window.open(
														member.stackoverflow,
														"_blank"
													)
												}}
												size={18}
												color="gray"
											/>
										)}
										{member.linkedin && (
											<FaLinkedin
												onClick={() => {
													window.open(
														member.linkedin,
														"_blank"
													)
												}}
												size={18}
												color="gray"
											/>
										)}
										{member.github && (
											<FaGithub
												onClick={() => {
													window.open(
														member.github,
														"_blank"
													)
												}}
												size={18}
												color="gray"
											/>
										)}
									</div>
								</div>
							))}
					</div>
				) : (
					<Loader />
				)}

				{location.pathname !== "/team" && (
					<button onClick={handleNext} className="btn_next">
						<AiOutlineArrowRight />
					</button>
				)}
			</div>
		</section>
	)
}

export default Team
