import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { storage } from "../../../../services/firebase"

const FileField = ({
	label,
	multiple,
	setMdFileContent,
	setProjectZip,
	accept,
	uploadButton,
}) => {
	const [preview, setPreview] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([])
	const [progress, setProgress] = useState(0)
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		if (selectedFiles.length === 0) {
			setPreview(undefined)
			return
		}

		if (selectedFiles.length === 1) {
			setPreview([URL.createObjectURL(selectedFiles[0])])
		}

		if (selectedFiles.length > 1) {
			let tempArray = []
			for (let i = 0; i < selectedFiles.length; i++) {
				tempArray.push(URL.createObjectURL(selectedFiles[i]))
			}
			setPreview(tempArray)
		}

		return () => {
			if (selectedFiles.length === 1) {
				URL.revokeObjectURL(selectedFiles[0])
			}

			if (selectedFiles.length > 1) {
				for (let i = 0; i < selectedFiles.length; i++) {
					URL.revokeObjectURL(selectedFiles[i])
				}
			}
		}
	}, [selectedFiles, setPreview])

	const UploadControl = ({
		children,
		value,
		onChange,
		disabled,
		accept,
		multiple,
	}) => {
		return (
			<>
				<label htmlFor="contained-button-file">
					<input
						value={value}
						accept={accept}
						disabled={disabled}
						style={{ display: "none" }}
						id="contained-button-file"
						type="file"
						onChange={disabled ? () => {} : onChange}
						multiple={multiple}
					/>
					{children}
				</label>
			</>
		)
	}

	const handleChange = async (e) => {
		e.preventDefault()
		let files = e.target.files

		if (!files || files.length === 0) {
			setSelectedFiles(undefined)
			return
		} else {
			setSelectedFiles(files[0])
		}

		const reader = new FileReader()

		reader.onload = async (e) => {
			setMdFileContent(e.target.result)
		}

		reader.readAsText(files[0])
	}

	useEffect(() => {
		if (progress === 100) {
			enqueueSnackbar("Uploaded Successfully", {
				variant: "success",
			})
		}
	}, [enqueueSnackbar, progress])

	const uploadProjectFile = () => {
		const storageRef = ref(storage, `files/${selectedFiles.name}`)
		const uploadTask = uploadBytesResumable(storageRef, selectedFiles)

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const prog = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				)
				setProgress(prog)
			},
			(err) => {
				enqueueSnackbar(err, {
					variant: "error",
				})
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((url) => {
					setProjectZip(url)
				})
			}
		)
	}

	return (
		<>
			<div className="row">
				<label htmlFor="Product Thumbnail">{label}</label>
			</div>

			<div className="row">
				<label className="custom-file-input">
					<UploadControl
						onChange={(e) => handleChange(e)}
						accept={accept}
						multiple={multiple}
					/>
				</label>
				<a
					style={{ paddingBottom: "1rem" }}
					href={preview}
					target="_blank"
					rel="noreferrer"
				>
					{selectedFiles?.name}
				</a>
				{uploadButton && (
					<>
						<button
							style={{ backgroundColor: "black" }}
							onClick={uploadProjectFile}
							type="button"
						>
							Upload
						</button>

						<progress value={progress} max="100" />
					</>
				)}
			</div>
		</>
	)
}

export default FileField
