import "./FavBadge.css"
import { useNavigate } from "react-router-dom"
import heart from "../../../../Assets/heart-svg.svg"

export default function FavBadge({ count }) {
	let navigate = useNavigate()

	return (
		<div
			className="notification"
			onClick={() => navigate("/favCart")}
			title="Cart"
		>
			<span className="favCart">
				<img src={heart} alt="favCart" />
			</span>
			{count ? <span className="badge">{count}</span> : null}
		</div>
	)
}
