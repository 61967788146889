import { useNavigate } from "react-router-dom";
import Headline from "../HomePage/Components/Headline/Headline";
import HandDollar from "../../Assets/AuthorAssets/HandDollar.svg";
import Audience from "../../Assets/AuthorAssets/Audience.svg";
import Secure from "../../Assets/AuthorAssets/Secure.svg";
import Analytic from "../../Assets/AuthorAssets/Analytic.svg";
import "./AuthorPage.css";

const AuthorPage = () => {
	const navigate = useNavigate();
	return (
		<section id="team">
			<Headline
				bgText="Author"
				mainText="Become an author"
				subText="There are many variations of passages of Lorem Ipsum available."
			/>
			<div className="author-content">
				<h1>More ways to earn.</h1>
				<div className="author-benifit-layout">
					<div className="author-benifits">
						<div>
							<img alt="HandDollar" src={HandDollar} />
						</div>
						<div>
							<h2>Sell more, Earn more</h2>
							<p>
								Hit new earnings milestones to make more out of every sale of
								your product.
							</p>
						</div>
					</div>
					<div className="author-benifits">
						<div>
							<img alt="Audience" src={Audience} />
						</div>
						<div>
							<h2>Multiple markets</h2>
							<p>
								Get as creative as you like! We have a range of products and
								platforms.
							</p>
						</div>
					</div>
					<div className="author-benifits">
						<div>
							<img alt="Secure" src={Secure} />
						</div>
						<div style={{ width: "116%" }}>
							<h2>Payment & product delivery</h2>
							<p>
								You don't have to worry about accepting payments or delivering
								your product.
							</p>
						</div>
					</div>
					<div className="author-benifits">
						<div>
							<img alt="Analytic" src={Analytic} />
						</div>
						<div>
							<h2>Analytics</h2>
							<p>
								You don't have to worry about accepting payments or delivering
								your product.
							</p>
						</div>
					</div>
				</div>
				{/* <button className="cta_btn" onClick={() => navigate("/admin/login")}>
					Author Login
				</button> */}
				<button className="cta_btn" onClick={() => navigate("/signup/DEV")}>
					SignUp Now !
				</button>
			</div>
		</section>
	);
};

export default AuthorPage;
