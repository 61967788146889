import "./Compatibility.css"
import figma from "../../../../Assets/BuyCollectionAssets/CompatibilityAssets/figma.png"
import invision from "../../../../Assets/BuyCollectionAssets/CompatibilityAssets/invision.png"
import { useSnackbar } from "notistack"
import { useCart } from "../../../../hooks/useCart"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"
import { useNavigate } from "react-router-dom"

const Compatibility = ({ data }) => {
	const { enqueueSnackbar } = useSnackbar()
	const { currentUser } = useAuthValue()
	const { addToCart, cartItems } = useCart()
	let navigate = useNavigate()

	const isInCart = (product) => {
		return !!cartItems.find(
			(item) => item?.productId === product?.productId
		)
	}

	return (
		<div className="compatibility">
			<p className="name">Compatibility</p>
			<div className="compatible_icons">
				{data?.compatibility?.includes("figma") && (
					<img src={figma} alt="figma" />
				)}
				{data?.compatibility?.includes("invision") && (
					<img src={invision} alt="invision" />
				)}
			</div>
			<div className="compatible-product-buttons">
				<button
					style={{
						cursor: isInCart(data) ? "default" : "cursor",
					}}
					className="btn_addToCart"
					disabled={isInCart(data)}
					onClick={() => {
						if (currentUser) {
							if (isInCart(data)) {
								enqueueSnackbar("product already in cart", {
									variant: "info",
								})
							} else {
								enqueueSnackbar("Item added successfully", {
									variant: "success",
								})
								addToCart(data)
							}
						} else {
							navigate("/login")
						}
					}}
				>
					{isInCart(data) ? `Added to cart` : `Add to cart`}
				</button>
			</div>
		</div>
	)
}

export default Compatibility
