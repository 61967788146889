import { useEffect } from "react"
import { useCallback } from "react"
import { useState } from "react"
import { messageHeaders } from "../../../constants/constants"
import Loader from "../../../UI/Loader/Loader"
import Paginations from "../../../UI/Pagination/Pagination"
import { ReadMore } from "../../../UI/ReadMore/ReadMore"
import { getMessages } from "../../AdminServices/AdminServices"
import "./Messages.css"

const Messages = () => {
	const [tableData, setTableData] = useState([])

	const [currentPage, setCurrentPage] = useState(1)
	let NUM_OF_RECORDS = tableData.length
	let LIMIT = 7

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = tableData.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	useEffect(() => {
		getMessages()
			.then((res) => {
				setTableData(res)
			})
			.catch((err) => {
				alert(err)
			})
	}, [])

	return (
		<div className="teams_container">
			<div className="teams_actions">
				<h2>Messages</h2>
			</div>
			<div className="teams_table">
				<table>
					<tr>
						{messageHeaders.map((header) => (
							<th key={header}>{header}</th>
						))}
					</tr>
					{currentData.length ? (
						currentData.map((message) => (
							<tr key={message.phone}>
								<td>{message.name}</td>
								<td>{message.email}</td>
								<td>{message.phone}</td>
								<td>{message.subject}</td>
								<td>
									{" "}
									<ReadMore size={20}>
										{message.message}
									</ReadMore>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={3}>
								<Loader />
							</td>
						</tr>
					)}
				</table>
			</div>
			{NUM_OF_RECORDS > 5 ? (
				<div className="pagination-wrapper">
					<Paginations
						totalRecords={NUM_OF_RECORDS}
						pageLimit={LIMIT}
						pageNeighbours={2}
						onPageChanged={onPageChanged}
						currentPage={currentPage}
					/>
				</div>
			) : null}
		</div>
	)
}

export default Messages
