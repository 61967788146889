import "./Contact.css";
import phone from "../../../../Assets/HomePageAssets/ContactAssets/phone.svg";
import location from "../../../../Assets/HomePageAssets/ContactAssets/location.svg";
import Getintouch from "./GetInTouch/Getintouch";

const Contact = () => {
	return (
		<section id="contact">
			<div className="contactUs">
				<div>
					<div className="card" style={{ marginBottom: 10 }}>
						<div className="card-icon-phone">
							<img src={phone} alt="phone-icon" />
						</div>
						<div className="content">
							<p className="title">Contact</p>
							<p className="details">
								+919849689798 <br />
								support@gytworkz.com
							</p>
						</div>
					</div>

					<div className="card">
						<div className="card-icon-location">
							<img src={location} alt="location-icon" />
						</div>
						<div className="content">
							<p className="title">Address</p>
							<p className="details">
								The Platina, A Block , 3rd floor, 303, Gachibowli, Hyderabad,
								Telangana 500032
							</p>
						</div>
					</div>
				</div>
				<Getintouch />
			</div>
		</section>
	);
};

export default Contact;
