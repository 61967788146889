import "./ServiceCard.css"

function ServiceCards(props) {
	return (
		<div className="serviceColumn">
			<div className="serviceCard">
				<div className="service-img">{props.elem.svg}</div>
				<h1>{props.elem.title}</h1>
				<h5>{props.elem.description}</h5>
			</div>
		</div>
	)
}

export default ServiceCards
