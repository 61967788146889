import React, { useState } from "react";
import "./ComingSoon.css";
import GytLogo from "../../Assets/GytWorkz.svg";

const ComingSoon = () => {
	const [email, setEmail] = useState(0);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="coming-soon-container">
			<div className="coming-soon-content">
				<h1>
					Coming soon...
					<img src={GytLogo} />
				</h1>
				<p>Be the first person to get notified</p>
				<div className="email-input">
					<input placeholder="Enter your email address" />
					<button>Notify me</button>
				</div>
			</div>
			<div className="side-rectangle-right"></div>
			<div className="side-rectangle-left"></div>
		</div>
	);
};

export default ComingSoon;
