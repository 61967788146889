import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "./firebase"

export const getProducts = async () => {
	const q = query(collection(db, "products"))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		obj["productId"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}

export const getProductsByUserId = async (userId) => {
	const q = query(collection(db, "products"), where("userId", "==", userId))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		obj["productId"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}

export const getUser = async (uid) => {
	const q = query(collection(db, "users"), where("uid", "==", uid))
	const docs = await getDocs(q)

	let tempArray = []

	docs.forEach((doc) => {
		let obj = doc.data()
		obj["userId"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}
