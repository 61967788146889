import { FaCartArrowDown } from "react-icons/fa"
import { GrAlert } from "react-icons/gr"

export const ROLES = {
	USER: "USER",
	DEV: "DEV",
	ADMIN: "ADMIN",
}

export const navItems = {
	caseStudy: {
		path: "casestudy",
		title: "Case Study",
	},
	ourServices: {
		path: "services",
		title: "Our Services",
	},
	creativeTeam: {
		path: "team",
		title: "Creative Team",
	},
	contact: {
		path: "contact",
		title: "Contact",
	},
	author: {
		path: "author-page",
		title: "Become an Author",
	},
}

export const teamAndContact = {
	creativeTeam: {
		path: "team",
		title: "Creative Team",
	},
	contact: {
		path: "contact",
		title: "Contact",
	},
}

export const tooltipArray = [
	"Terrible",
	"Terrible+",
	"Bad",
	"Bad+",
	"Average",
	"Average+",
	"Great",
	"Great+",
	"Awesome",
	"Awesome+",
]
export const fillColorArray = [
	"#f17a45",
	"#f17a45",
	"#f19745",
	"#f19745",
	"#f1a545",
	"#f1a545",
	"#f1b345",
	"#f1b345",
	"#f1d045",
	"#f1d045",
]

export const firebaseErrorsMsg = (error) => {
	switch (error.code) {
		case "auth/email-already-in-use":
			return "Email already in use!"
		case "auth/wrong-password":
			return "Invalid password!"
		case "auth/user-not-found":
			return "User not Found!"
		default:
			return "Something went wrong"
	}
}

export const aboutFlutter = [
	{ title: "Career", path: "coming-soon" },
	{ title: "Blogs", path: "blogs" },
	{ title: "Brand", path: "coming-soon" },
	{ title: "Culture", path: "coming-soon" },
	{ title: "Press", path: "coming-soon" },
]
export const learnFlutter = [
	{ title: "Learning Journey", path: "coming-soon" },
	{ title: "Codelabs", path: "coming-soon" },
	{ title: "Samples", path: "coming-soon" },
	{ title: "Cookbook", path: "coming-soon" },
]
export const multiPlatform = [
	{ title: "Mobile", path: "coming-soon" },
	{ title: "Web", path: "coming-soon" },
	{ title: "Destop", path: "coming-soon" },
	{ title: "Embedded", path: "coming-soon" },
]

export const tableHeaders = ["Title", "SubTitle", "Status", "Actions"]
export const messageHeaders = ["Name", "Email", "Phone", "Subject", "Message"]
export const subscriptionsHeader = ["Subscritption Date", "Email"]
export const blogsTableHeaders = [
	"Title",
	"SubTitle",
	"Cover Image",
	"Pusblished",
	"Created",
	"Updated",
	"Actions",
]
export const ordersTableHeader = [
	"Photo",
	"Product Name",
	"Product Id",
	"Customer",
	"Price",
]

export const userTableHeader = ["User Name", "Email", "Phone", "Roles"]

export const piedata = [
	{ name: "Group A", value: 100 },
	{ name: "Group B", value: 300 },
	{ name: "Group C", value: 800 },
]

export const COLORS = ["#68D1C7", "#6B58E2", "#19115E"]

export const graphData = [
	{
		name: "Jan",
		pv: 2400,
	},
	{
		name: "Feb",
		pv: 1398,
		amt: 2210,
	},
	{
		name: "Mar",
		uv: 2000,
		pv: 9800,
		amt: 2290,
	},
	{
		name: "Apr",
		uv: 2780,
		pv: 3908,
		amt: 2000,
	},
	{
		name: "May",
		uv: 1890,
		pv: 4800,
		amt: 2181,
	},
	{
		name: "Jun",
		uv: 2390,
		pv: 3800,
		amt: 2500,
	},
	{
		name: "Jul",
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
	{
		name: "Aug",
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
	{
		name: "Sep",
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
	{
		name: "Oct",
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
	{
		name: "Nov",
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
	{
		name: "Dec",
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
]

export const analyticsData = [
	// {
	// 	label: "Total Orders",
	// 	value: "12,124.00k",
	// 	icon: <FiShoppingCart />,
	// 	percent: "+12%",
	// 	color: "#398061",
	// },
	{
		label: "Return Orders",
		value: "0",
		icon: <FaCartArrowDown />,
		percent: "0%",
		color: "#DB445B",
	},
	// {
	// 	label: "Total Visitors",
	// 	value: "0",
	// 	icon: <GrGroup />,
	// 	percent: "0%",
	// 	color: "#F2AC3C",
	// },
	{
		label: "Total Complaints",
		value: "0",
		icon: <GrAlert />,
		percent: "0%",
		color: "#6A49E5",
	},
]

export const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
]

export const getMonthName = (monthNumber) => {
	const date = new Date()
	date.setMonth(monthNumber)

	return date.toLocaleString("en-US", { month: "long" })
}
export const mergeArrayObjects = (arr1, arr2) => {
	return arr1.map((item, i) => {
		if (item.id === arr2[i].id) {
			//merging two objects
			return Object.assign({}, item, arr2[i])
		}
	})
}
