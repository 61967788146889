import { Route, Routes } from "react-router-dom"
import Admin from "./admin/Admin"
import AdminTab from "./admin/AdminTab"
import BlogList from "./admin/Pages/BlogList/BlogList"
import CreateBlog from "./admin/Pages/CreateBlog/CreateBlog"
import Analytics from "./admin/Pages/Dashboard/Analytics/Analytics"
import AddEditProject from "./admin/Pages/Dashboard/Projects/AddEditProject"
import ProjectsTable from "./admin/Pages/Dashboard/Projects/ProjectsTable"
import Sales from "./admin/Pages/Dashboard/Sales/Sales"
import AdminLogin from "./admin/Pages/Login/AdminLogin"
import Messages from "./admin/Pages/Messages/Messages"
import Subscriptions from "./admin/Pages/Subscriptions/Subscriptions"
import Teams from "./admin/Pages/Teams/Teams"
import UserList from "./admin/Pages/UserList/UserList"
import "./App.css"
import { ROLES } from "./constants/constants"
import AuthorPage from "./Pages/AuthorPage/AuthorPage"
import Blogs from "./Pages/Blogs/Blogs"
import BuyCollection from "./Pages/BuyCollection/Components"
import CartPage from "./Pages/CartPage/CartPage"
import CollectionPage from "./Pages/CollectionsPage/CollectionPage"
import FavCartPage from "./Pages/FavCartPage/FavCartPage"
import HomePage from "./Pages/HomePage/Components"
import Contact from "./Pages/HomePage/Components/Contact/Contact"
import Team from "./Pages/HomePage/Components/Team/Team"
import Layout from "./Pages/Layout"
import Orders from "./Pages/OrderSummary/Orders"
import PaymentComponent from "./Pages/Payment/PaymentComponent"
import Profile from "./Pages/Profile/Profile"
import RequireAdminAuth from "./Pages/RequireAdminAuth"
import RequireAuth from "./Pages/RequireAuth"
import Login from "./Pages/SignupSignin/Components/Login/Login"
import SingUp from "./Pages/SignupSignin/Components/SignUp/SingUp"
import VerifyEmail from "./Pages/SignupSignin/Components/VerifyEmail/VerifyEmail"
import ComingSoon from "./Pages/Unauthorized404Page/ComingSoon"
import Error404 from "./Pages/Unauthorized404Page/error404"
import Unauthorized from "./Pages/Unauthorized404Page/Unauthorized"

const App = () => {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				{/* public routes */}
				<Route path="/" element={<HomePage />} />
				<Route path="login" element={<Login />} />
				<Route path="signup" element={<SingUp />} />
				<Route path="verify-email" element={<VerifyEmail />} />
				<Route path="marketplace" element={<CollectionPage />} />
				<Route path="contact" element={<Contact />} />
				<Route path="team" element={<Team />} />
				<Route path="unauthorized" element={<Unauthorized />} />
				<Route path="buycollection/:id" element={<BuyCollection />} />
				<Route path="blogs" element={<Blogs />} />
				<Route path="coming-soon" element={<ComingSoon />} />

				<Route path="author-page" element={<AuthorPage />} />
				<Route path="signup/:role" element={<SingUp />} />
				{/* admin and dev routes */}

				<Route path="admin" element={<Admin />}>
					<Route index element={<AdminLogin />} />
					<Route path="login" element={<AdminLogin />} />
					<Route
						element={
							<RequireAdminAuth
								allowedRoles={[ROLES.DEV, ROLES.ADMIN]}
							/>
						}
					>
						<Route path="sub" element={<AdminTab />}>
							<Route index element={<Analytics />} />
							<Route path="dashboard" element={<Analytics />} />

							<Route path="sales" element={<Sales />} />
							<Route
								path="projects"
								element={<ProjectsTable />}
							/>

							<Route
								path="addproject"
								element={<AddEditProject />}
							/>
							<Route
								path="editProject/:id"
								element={<AddEditProject />}
							/>
							<Route path="blogsList" element={<BlogList />} />
							<Route path="createBlog" element={<CreateBlog />} />
							<Route
								path="editblog/:id"
								element={<CreateBlog />}
							/>

							<Route
								element={
									<RequireAdminAuth
										allowedRoles={[ROLES.ADMIN]}
									/>
								}
							>
								{/* admin routes */}
								<Route path="teams" element={<Teams />} />
								<Route path="messages" element={<Messages />} />
								<Route
									path="subscriptions"
									element={<Subscriptions />}
								/>
								<Route
									path="blogsList"
									element={<BlogList />}
								/>
								<Route path="users" element={<UserList />} />
							</Route>
						</Route>
					</Route>
				</Route>

				{/* user protected routes */}
				<Route element={<RequireAuth allowedRoles={[ROLES.USER]} />}>
					<Route exact path="cart" element={<CartPage />} />
					<Route exact path="favCart" element={<FavCartPage />} />
					<Route exact path="profile" element={<Profile />} />
					<Route exact path="orders" element={<Orders />} />
					<Route
						exact
						path="payment"
						element={<PaymentComponent />}
					/>
				</Route>

				<Route path="*" element={<Error404 />} />
			</Route>
		</Routes>
	)
}

export default App
