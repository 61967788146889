import React from "react"
import { useAuthValue } from "../../contexts/AuthContext/AuthContext"
import Sidebar from "../Sidebar/Sidebar"
import "./Layout.css"

const Layout = (props) => {
	const { children } = props
	const { currentUser } = useAuthValue()

	return (
		<div className="layout">
			<aside className="aside">
				<div className="admin_user">
					{currentUser?.displayName ? (
						<h3>{currentUser?.displayName}</h3>
					) : (
						<img
							alt="profilepic"
							style={{ borderRadius: "50%" }}
							src={
								currentUser?.photoURL
									? currentUser?.photoURL
									: require("../../Assets/AdminAssets/Ellipse 209.png")
							}
						/>
					)}
					<p>{currentUser?.roles?.[currentUser?.roles - 1]}</p>
				</div>
				<Sidebar />
			</aside>
			<main className="main">{children}</main>
		</div>
	)
}

export default Layout
