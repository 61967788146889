import "./CollectionPage.css"
import React, { useState } from "react"
import { useProducts } from "../../hooks/useProducts"
import ProductCard from "./Components/ProductCard/ProductCard"
import SearchBar from "./Components/SearchBar/SearchBar"
import Loader from "../../UI/Loader/Loader"
import AuthorStrip from "./Components/AuthorStrip/AuthorStrip"

const CollectionPage = () => {
	const { products } = useProducts()
	const [search, setSearch] = useState("")

	const filteredProducts = products?.filter((product) => {
		if (
			(product.subtitle.toLowerCase().includes(search) ||
				product.title.toLowerCase().includes(search) ||
				product.description.toLowerCase().includes(search)) &&
			product?.status === "APPROVED"
		) {
			return product
		}
	})

	return (
		<>
			<SearchBar setSearch={setSearch} />
			{products.length ? (
				<div className="product-collection-grid">
					{filteredProducts?.map((product) => (
						<ProductCard
							key={product.productId}
							product={product ? product : []}
						/>
					))}
				</div>
			) : (
				<Loader />
			)}
			<AuthorStrip />
		</>
	)
}

export default CollectionPage
