import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAuthValue } from "../contexts/AuthContext/AuthContext"

const RequireAdminAuth = ({ allowedRoles, children }) => {
	const { currentUser } = useAuthValue()
	const location = useLocation()

	return currentUser?.roles?.find((role) => allowedRoles?.includes(role)) ? (
		<Outlet />
	) : currentUser ? (
		<Navigate to="/unauthorized" state={{ from: location }} replace />
	) : (
		<Navigate to="/admin" state={{ from: location }} replace />
	)
}

export default RequireAdminAuth
