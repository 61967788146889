import React from "react"
import "./Sidebar.css"
import { useNavigate } from "react-router-dom"
import NavItem from "./NavItem/NavItem"
import { sideMenu, sideMenuDev } from "./menu.config.js"
import { logout } from "../../services/AuthService"
import { useAuthValue } from "../../contexts/AuthContext/AuthContext"

const Sidebar = () => {
	const navigate = useNavigate()
	const { currentUser, setCurrentUser } = useAuthValue()

	const handleLogout = () => {
		setCurrentUser(null)
		sessionStorage.removeItem("Auth Token")
		localStorage.clear()
		logout()
		setTimeout(() => {
			navigate("/admin/login")
		}, 1000)
	}

	let sideMenuList = currentUser?.roles.includes("ADMIN")
		? sideMenu
		: sideMenuDev
	return (
		<nav className="sidebar">
			{sideMenuList.map((item, index) => {
				return <NavItem key={`${item.label}-${index}`} item={item} />
			})}
			{<button onClick={handleLogout}>Sign Out</button>}
		</nav>
	)
}

export default Sidebar
