import "./Landing.css"
import rectangle from "../../../../Assets/HomePageAssets/LandingPageAssets/rectangle.svg"
import ScreenShot from "../../../../Assets/HomePageAssets/LandingPageAssets/ScreenShot.webp"
import { useNavigate } from "react-router-dom"

const Landing = () => {
	let navigate = useNavigate()
	return (
		<div className="landing_container">
			<div className="section-1">
				<div className="hero_heading">
					<p className="headline">
						Build with the world's most
						<br />
						<span className="text_highlight">
							intuitive platform
						</span>
					</p>
					<p className="summary">
						Create better app & websites with Flutter 💙
					</p>
					<button
						className="cta_btn"
						onClick={() => navigate("/marketplace")}
					>
						Market place
					</button>
				</div>
			</div>
			<div className="image_section">
				<img
					className="screenshots"
					src={ScreenShot}
					alt="background_graphic"
				/>
				<img
					className="rectangle"
					src={rectangle}
					alt="background_graphic"
				/>
			</div>
		</div>
	)
}

export default Landing
