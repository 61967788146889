export const data = [
    {
      id: 1,
      svg: <svg className='ServiceCard' width="122" height="108" viewBox="0 0 122 108" xmlns="http://www.w3.org/2000/svg">
        <path d="M119.973 18.4386H1.28295C0.599924 18.4386 0.046875 17.8855 0.046875 17.2025V9.44553C0.0487724 6.94196 1.04486 4.54111 2.81589 2.76998C4.58692 0.998856 6.98811 0.00267139 9.49241 0H111.763C114.267 0.002846 116.668 0.998929 118.439 2.76998C120.21 4.54104 121.207 6.9422 121.208 9.44553V17.2025C121.208 17.8855 120.655 18.4386 119.972 18.4386H119.973ZM2.51907 15.9654H118.736V9.44524C118.735 7.59727 117.999 5.8243 116.692 4.51702C115.385 3.20979 113.612 2.47458 111.763 2.47268H9.4926C7.64369 2.47458 5.87069 3.20979 4.56341 4.51702C3.25618 5.82425 2.52097 7.59737 2.51907 9.44524V15.9654Z" />
        <path d="M16.2797 10.456H11.3769C10.6939 10.456 10.1399 9.90298 10.1399 9.21996C10.1399 8.53694 10.6939 7.98389 11.3769 7.98389H16.2804H16.2794C16.9625 7.98389 17.5165 8.53694 17.5165 9.21996C17.5165 9.90298 16.9625 10.456 16.2794 10.456H16.2797Z" />
        <path d="M29.3917 10.456H24.491C23.8079 10.456 23.2549 9.90298 23.2549 9.21996C23.2549 8.53694 23.8079 7.98389 24.491 7.98389H29.3917C30.0748 7.98389 30.6278 8.53694 30.6278 9.21996C30.6278 9.90298 30.0748 10.456 29.3917 10.456Z" />
        <path d="M42.5028 10.456H37.5994C36.9163 10.456 36.3633 9.90298 36.3633 9.21996C36.3633 8.53694 36.9163 7.98389 37.5994 7.98389H42.5028C43.1849 7.98389 43.7389 8.53694 43.7389 9.21996C43.7389 9.90298 43.1849 10.456 42.5028 10.456Z" />
        <path d="M111.765 107.311H9.49436C6.98909 107.308 4.58703 106.311 2.8159 104.539C1.04477 102.767 0.0495554 100.365 0.048584 97.8593V9.44858C0.0504814 6.94501 1.04656 4.54416 2.8176 2.77304C4.58863 1.00191 6.98981 0.00572314 9.49412 0.00305176H111.764C114.269 0.00589775 116.67 1.00198 118.441 2.77304C120.212 4.54409 121.208 6.94525 121.21 9.44858V97.8593C121.209 100.365 120.214 102.767 118.443 104.539C116.672 106.311 114.269 107.308 111.764 107.311H111.765ZM9.49436 2.47578C7.64545 2.47768 5.87245 3.21289 4.56517 4.52012C3.25794 5.82735 2.52272 7.60047 2.52083 9.44834V97.859C2.52273 99.707 3.25794 101.48 4.56517 102.787C5.8724 104.094 7.64552 104.83 9.49436 104.832H111.765C113.614 104.83 115.387 104.094 116.694 102.787C118.001 101.48 118.736 99.7069 118.738 97.859V9.44834C118.736 7.60037 118.001 5.8274 116.694 4.52012C115.387 3.21289 113.614 2.47768 111.765 2.47578H9.49436Z" />
        <path d="M79.6129 100.105H41.6356C39.6292 100.102 37.7042 99.3046 36.2853 97.8855C34.8663 96.4663 34.0683 94.5414 34.0654 92.5352V86.9874C34.0654 86.3044 34.6194 85.7513 35.3025 85.7513C35.9855 85.7513 36.5386 86.3044 36.5386 86.9874V92.5352C36.5404 93.886 37.0774 95.1818 38.0336 96.1372C38.9889 97.0934 40.2847 97.6303 41.6356 97.6322H79.613C80.9231 97.6369 82.1848 97.1342 83.1334 96.2301C84.082 95.3261 84.6446 94.09 84.7024 92.7809C84.7404 92.1016 85.3143 91.578 85.9935 91.6017C86.3208 91.6159 86.6291 91.7601 86.8502 92.002C87.0721 92.2449 87.1879 92.5646 87.1727 92.8919C87.0883 94.8375 86.2534 96.675 84.8447 98.0202C83.435 99.3644 81.5606 100.112 79.6129 100.105V100.105Z" />
        <path d="M79.6129 100.105H41.6356C39.6292 100.102 37.7042 99.3046 36.2853 97.8854C34.8663 96.4663 34.0683 94.5413 34.0654 92.5351V30.7387C34.0683 28.7314 34.8661 26.8074 36.2853 25.3884C37.7044 23.9694 39.6293 23.1705 41.6356 23.1686H79.6129C81.6203 23.1695 83.5459 23.9673 84.9671 25.3865C86.3873 26.8057 87.1869 28.7306 87.1907 30.7387L87.1746 92.8387V92.8928C87.0892 94.8394 86.2544 96.677 84.8447 98.0211C83.436 99.3654 81.5606 100.113 79.6129 100.106L79.6129 100.105ZM41.6356 25.6392C40.2847 25.6411 38.9889 26.179 38.0336 27.1342C37.0773 28.0905 36.5404 29.3854 36.5385 30.7372V92.5336C36.5404 93.8845 37.0773 95.1802 38.0336 96.1356C38.9888 97.0919 40.2847 97.6288 41.6356 97.6307H79.6129C80.9183 97.6354 82.1753 97.1364 83.1229 96.239C84.0706 95.3406 84.636 94.1131 84.7014 92.8087L84.7176 30.7451C84.7147 29.3924 84.1759 28.0966 83.2187 27.1412C82.2625 26.185 80.9647 25.648 79.6128 25.648L41.6356 25.6392Z" />
        <path d="M85.9536 37.5244H35.3042C34.6212 37.5244 34.0671 36.9704 34.0671 36.2874V30.7416C34.07 28.7333 34.8687 26.8086 36.2889 25.3884C37.708 23.9682 39.6337 23.1695 41.6411 23.1676H79.6185C81.6258 23.1695 83.5515 23.9683 84.9707 25.3884C86.3909 26.8085 87.1896 28.7332 87.1924 30.7416V36.2874C87.1924 36.6156 87.0615 36.9296 86.83 37.162C86.5976 37.3935 86.2836 37.5244 85.9554 37.5244H85.9536ZM36.5403 35.0512H84.7175V30.7415C84.7166 29.3888 84.1787 28.092 83.2215 27.1357C82.2653 26.1794 80.9685 25.6415 79.6166 25.6396H41.6392C40.2874 25.6415 38.9906 26.1794 38.0343 27.1357C37.0771 28.0919 36.5392 29.3887 36.5383 30.7415L36.5403 35.0512Z" />
        <path d="M79.6185 100.105H41.6411C39.6338 100.102 37.7081 99.3036 36.2889 97.8835C34.8687 96.4643 34.07 94.5387 34.0671 92.5312V86.9845C34.0671 86.3015 34.6211 85.7484 35.3042 85.7484H85.9536C86.2819 85.7484 86.5959 85.8784 86.8274 86.1108C87.0598 86.3423 87.1897 86.6572 87.1897 86.9845V92.5313C87.1878 94.5386 86.3891 96.4633 84.9699 97.8835C83.5498 99.3037 81.6251 100.102 79.6177 100.105L79.6185 100.105ZM36.5412 88.2214V92.5311C36.5421 93.8829 37.08 95.1797 38.0372 96.137C38.9934 97.0932 40.2902 97.6311 41.6421 97.632H79.6195C80.9713 97.6301 82.2681 97.0923 83.2244 96.136C84.1797 95.1798 84.7176 93.883 84.7195 92.5311V88.2214H36.5412Z" />
        <path d="M67.2982 31.5818H53.9612C53.2781 31.5818 52.7251 31.0278 52.7251 30.3457C52.7251 29.6627 53.2781 29.1086 53.9612 29.1086H67.2982C67.9803 29.1086 68.5343 29.6626 68.5343 30.3457C68.5343 31.0278 67.9803 31.5818 67.2982 31.5818Z" />
        <path d="M60.6287 97.0696C59.5292 97.0696 58.4752 96.6332 57.6984 95.8563C56.9215 95.0794 56.4841 94.0245 56.4841 92.926C56.4841 91.8266 56.9215 90.7726 57.6984 89.9958C58.4753 89.2179 59.5293 88.7815 60.6287 88.7815C61.728 88.7815 62.7821 89.2179 63.5589 89.9958C64.3359 90.7727 64.7732 91.8267 64.7732 92.926C64.7713 94.0246 64.334 95.0776 63.558 95.8546C62.7811 96.6315 61.7271 97.0689 60.6287 97.0698L60.6287 97.0696ZM60.6287 91.2591C59.9523 91.2591 59.3432 91.6661 59.0843 92.2912C58.8253 92.9164 58.9685 93.6355 59.4476 94.1126C59.9257 94.5908 60.6448 94.734 61.269 94.4741C61.8941 94.2151 62.3011 93.6051 62.3002 92.9297C62.3002 92.4857 62.1247 92.0598 61.8107 91.7458C61.4976 91.4318 61.0726 91.2553 60.6287 91.2553V91.2591Z" />
        <path d="M60.6295 78.4307C55.4888 78.4477 50.6278 76.0875 47.4612 72.0378C47.1321 71.6413 46.8275 71.2248 46.5486 70.7913C44.3412 67.3952 43.4353 63.315 43.9987 59.3041C44.5622 55.2921 46.5562 51.619 49.6137 48.9619C52.6712 46.3049 56.5853 44.8429 60.637 44.8439C64.6878 44.8458 68.6009 46.3114 71.6565 48.9714C74.7121 51.6304 76.7032 55.3045 77.263 59.3174C77.8237 63.3294 76.9139 67.4083 74.7036 70.8027C74.4199 71.241 74.1107 71.6622 73.7768 72.0634C70.6112 76.1009 65.7597 78.4498 60.6294 78.4308L60.6295 78.4307ZM60.6295 47.3139C57.1754 47.3148 53.8381 48.5642 51.2323 50.8304C48.6264 53.0977 46.9272 56.229 46.4483 59.6499C45.9692 63.0707 46.7424 66.5493 48.6245 69.4446C48.8559 69.8042 49.1092 70.1504 49.3824 70.4796C52.0871 73.9317 56.227 75.9503 60.6125 75.9552C64.9972 75.9609 69.1417 73.9516 71.8539 70.5053C72.1337 70.1714 72.3918 69.8213 72.628 69.4561C74.5139 66.5608 75.2899 63.0812 74.8118 59.6585C74.3346 56.2367 72.6356 53.1024 70.0298 50.8341C67.4239 48.565 64.0847 47.3157 60.6296 47.3137L60.6295 47.3139Z" />
        <path d="M60.6291 78.4306C55.4874 78.4439 50.6275 76.0856 47.4573 72.0377C47.1025 71.589 47.1025 70.9553 47.4573 70.5057C50.6372 66.4702 55.4913 64.1147 60.6291 64.1147C65.7669 64.1147 70.6213 66.4702 73.801 70.5057C74.1558 70.9553 74.1558 71.589 73.801 72.0377C70.6305 76.0856 65.7708 78.444 60.6291 78.4306ZM50.0451 71.2713C52.7544 74.2557 56.5982 75.9586 60.6291 75.9586C64.66 75.9586 68.5039 74.2558 71.2132 71.2713C68.5029 68.2878 64.66 66.5869 60.6291 66.5869C56.5982 66.5869 52.7553 68.2878 50.0451 71.2713Z" />
        <path d="M60.6292 63.3712C58.7935 63.3684 57.0347 62.6361 55.7379 61.3364C54.442 60.0367 53.7144 58.276 53.7163 56.4405C53.7182 54.6049 54.4496 52.845 55.7483 51.5482C57.047 50.2504 58.8088 49.5229 60.6442 49.5238C62.4797 49.5248 64.2397 50.2543 65.5372 51.553C66.835 52.8517 67.5636 54.6115 67.5636 56.4469C67.5617 58.2845 66.8303 60.046 65.5297 61.3448C64.2291 62.6435 62.4666 63.3721 60.6289 63.3711L60.6292 63.3712ZM60.6292 51.9865C59.45 51.9894 58.3202 52.4608 57.4871 53.2956C56.6552 54.1314 56.1884 55.2622 56.1894 56.4413C56.1903 57.6205 56.6599 58.7513 57.4938 59.5851C58.3276 60.4189 59.4584 60.8876 60.6375 60.8885C61.8167 60.8895 62.9475 60.4218 63.7832 59.5888C64.618 58.7569 65.0885 57.6261 65.0914 56.4468C65.0904 55.2639 64.6199 54.1293 63.7832 53.2926C62.9465 52.4569 61.8119 51.9864 60.629 51.9864L60.6292 51.9865Z" />
        <path d="M107.677 47.3138H13.5808C12.8978 47.3138 12.3447 46.7608 12.3447 46.0777C12.3447 45.3947 12.8978 44.8417 13.5808 44.8417H107.677C108.36 44.8417 108.913 45.3947 108.913 46.0777C108.913 46.7608 108.36 47.3138 107.677 47.3138Z" />
        <path d="M107.677 78.4306H13.5808C12.8978 78.4306 12.3447 77.8776 12.3447 77.1946C12.3447 76.5115 12.8978 75.9585 13.5808 75.9585H107.677C108.36 75.9585 108.913 76.5115 108.913 77.1946C108.913 77.8776 108.36 78.4306 107.677 78.4306Z" />
        <path d="M107.677 78.4306H13.5808C12.8978 78.4306 12.3447 77.8776 12.3447 77.1946C12.3447 76.5115 12.8978 75.9585 13.5808 75.9585H107.677C108.36 75.9585 108.913 76.5115 108.913 77.1946C108.913 77.8776 108.36 78.4306 107.677 78.4306Z" />
        <path d="M20.0549 88.3221C19.3728 88.3221 18.8188 87.769 18.8188 87.086V36.1864C18.8188 35.5034 19.3728 34.9493 20.0549 34.9493C20.7379 34.9493 21.292 35.5033 21.292 36.1864V87.086C21.292 87.4142 21.1611 87.7282 20.9296 87.9606C20.6981 88.1921 20.3832 88.3221 20.0549 88.3221V88.3221Z" />
        <path d="M101.203 88.3221C100.52 88.3221 99.9658 87.769 99.9658 87.086V36.1864C99.9658 35.5034 100.52 34.9493 101.203 34.9493C101.885 34.9493 102.439 35.5033 102.439 36.1864V87.086C102.439 87.4142 102.308 87.7282 102.077 87.9606C101.845 88.1921 101.53 88.3221 101.203 88.3221L101.203 88.3221Z" />
      </svg>,
      title: 'App Development',
      description: 'Create better websites with mobile'
    },
    {
      id: 2,
      title: 'UI/UX',
      svg: <svg width="95" className='ServiceCard' height="144" viewBox="0 0 95 144" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.93089 92.4808C2.14272 92.9068 1.40862 93.4279 0.745883 94.0301C0.352335 94.4809 0.186933 95.0864 0.296121 95.6734C0.389102 96.2162 0.668056 97.812 7.37993 101.532C7.61238 106.88 8.2481 117.512 9.5498 119.605C11.1769 122.193 18.105 124.472 23.9801 123.913L23.0189 141.707C22.9995 142.133 23.1562 142.547 23.4525 142.854C23.7433 143.159 24.1466 143.333 24.5682 143.334H56.7296C57.1404 143.334 57.5351 143.171 57.8248 142.88C58.1156 142.59 58.2789 142.196 58.2789 141.785C57.9535 133.431 58.1243 123.511 58.8519 122.178L59.7352 121.248C66.4783 114.18 84.3489 95.7349 70.3997 73.2772C70.1813 72.9226 69.8288 72.6707 69.4234 72.5788L24.1483 62.6592C23.6856 62.5554 23.2002 62.67 22.8315 62.9695C21.2811 64.2247 8.01428 75.3693 10.4316 83.785C11.5009 87.2878 5.70486 90.7907 2.92962 92.4807L2.93089 92.4808ZM13.4235 82.9483C11.8731 77.709 20.2283 69.2781 24.2728 65.8984L68.1834 75.4925C80.1949 95.6421 63.7821 112.567 57.5665 118.891L56.6519 119.836C54.9015 121.666 54.9015 131.555 55.1188 140.156H26.2123L27.1734 122.147C27.1853 121.669 26.9756 121.212 26.6069 120.91C26.2371 120.607 25.7484 120.491 25.2825 120.596C19.7956 121.836 13.0221 119.325 12.1854 117.993C11.3486 116.66 10.7442 107.965 10.4339 100.556C10.4134 100.01 10.1074 99.5157 9.62846 99.2541C7.61101 98.1967 5.69188 96.9621 3.89283 95.5652L4.55881 95.162C8.23246 92.884 15.0838 88.6987 13.4253 82.9481L13.4235 82.9483Z" />
        <path d="M91.372 42.5116C91.7828 42.5116 92.1764 42.3483 92.4672 42.0575C92.758 41.7667 92.9213 41.372 92.9213 40.9612L94.1149 15.9918C94.1387 15.4653 93.8932 14.9637 93.464 14.6588C93.0153 14.3269 92.4239 14.262 91.9136 14.488L81.9162 18.5953L81.8394 2.21265C81.8351 1.65046 81.527 1.13366 81.0329 0.864443C80.5539 0.587666 79.9625 0.587666 79.4836 0.864443L54.1726 17.3553C53.7261 17.6494 53.4623 18.1532 53.4753 18.6884C53.4829 19.2214 53.764 19.7133 54.2191 19.9901L90.5516 42.2166C90.7916 42.3918 91.0759 42.4934 91.3733 42.5118L91.372 42.5116ZM58.0011 18.6266L78.7701 5.04824L78.8944 20.9197V20.9208C78.8988 21.43 79.1539 21.9057 79.5766 22.1911C80.008 22.4787 80.5551 22.5306 81.0329 22.3306L90.9061 18.285L89.9449 38.2021L58.0011 18.6266Z" />
        <path d="M10.4786 25.941L22.088 45.1294C23.2762 47.1068 25.4071 48.322 27.7143 48.3383C29.9944 48.3426 32.1145 47.1706 33.3245 45.2387L46.7158 24.0351H46.7169C47.6056 22.6318 47.9375 20.9452 47.6467 19.3095C47.3548 17.6737 46.4617 16.2054 45.1416 15.1957C43.8226 14.1859 42.1718 13.7059 40.5164 13.8518L15.5158 15.8671C13.2205 16.0574 11.1856 17.4197 10.1358 19.4707C9.08491 21.5206 9.16817 23.9672 10.3542 25.9415L10.4786 25.941ZM13.0518 20.811C13.6053 19.7136 14.693 18.9849 15.9189 18.8898L40.9508 16.9361C42.2936 16.8085 43.5899 17.4745 44.2677 18.6411C44.9867 19.7828 44.9867 21.2359 44.2677 22.3765L30.7823 43.58C30.1412 44.6147 29.0092 45.2428 27.7906 45.2385C26.5603 45.2331 25.4229 44.579 24.799 43.5173L13.1744 24.329C12.5073 23.2629 12.4608 21.9212 13.0511 20.8109L13.0518 20.811Z" />
        <path d="M77.763 43.0541L70.1993 48.8977L61.1002 55.8885V54.7566C60.7132 51.6711 59.5012 48.7464 57.5918 46.2922C55.6825 43.8369 53.1462 41.9416 50.2509 40.8076C47.7253 39.7492 44.8322 40.0649 42.5942 41.6444C38.5798 44.744 38.9053 51.6418 43.3229 57.3612V57.3601C45.1868 59.8716 47.7394 61.7874 50.6693 62.8784C51.7256 63.2536 52.8349 63.4525 53.956 63.4677C55.5313 63.4979 57.0709 62.9952 58.3261 62.0416L70.7259 52.4162L77.9177 61.7164C78.4409 62.3911 79.4107 62.5154 80.0875 61.9953L87.6978 56.1213C88.0254 55.8673 88.2373 55.4932 88.287 55.0823C88.3486 54.678 88.2362 54.2672 87.9767 53.9515L79.855 43.4124V43.4113C79.3609 42.7583 78.4451 42.6026 77.7629 43.0545L77.763 43.0541ZM56.4512 59.5614C55.0306 60.4847 53.2392 60.6252 51.6921 59.9333C49.3319 59.0348 47.2819 57.4747 45.7868 55.439C42.5012 51.1921 41.9747 45.9843 44.5164 44.0621C45.2311 43.5302 46.1057 43.2566 46.9966 43.2869C47.7729 43.2945 48.5426 43.4307 49.2746 43.6902C51.5958 44.6384 53.6252 46.1822 55.1572 48.1662C56.6903 50.1501 57.6709 52.5038 58.0005 54.9893C58.3043 56.6791 57.7194 58.4058 56.4512 59.5616L56.4512 59.5614ZM79.4992 58.5851L73.3 50.5099L78.4613 46.5107L84.6605 54.6009L79.4992 58.5851Z" />
        <path d="M51.8014 90.3579C52.8869 88.9167 56.4515 84.7933 59.0712 84.979C59.5513 84.979 60.28 85.1963 61.04 86.5294C63.2877 90.3265 62.2336 98.0297 58.9783 101.517H58.9772C57.9803 102.913 56.0981 103.346 54.5908 102.525C53.8254 102.144 52.8956 102.456 52.5139 103.222C52.1334 103.988 52.4458 104.918 53.2113 105.299C54.1367 105.777 55.1617 106.032 56.2029 106.043C58.1058 105.934 59.8788 105.042 61.1005 103.578C65.2693 99.0987 66.4943 89.8453 63.5644 84.9012L63.5655 84.9023C62.7092 83.1778 61.0064 82.0341 59.0862 81.8946C54.436 81.6308 49.7093 87.7999 49.1817 88.4971V88.4981C48.6682 89.1825 48.8066 90.1534 49.492 90.668C50.1764 91.1816 51.1484 91.0421 51.6619 90.3577L51.8014 90.3579Z" />
      </svg>,
      description: 'Create better websites with mobile'
    },
    {
      id: 3,
      title: 'Web development',
      svg: <svg className='ServiceCard' width="122" height="108" viewBox="0 0 122 108" xmlns="http://www.w3.org/2000/svg">
        <path d="M119.973 18.4386H1.28295C0.599924 18.4386 0.046875 17.8855 0.046875 17.2025V9.44553C0.0487724 6.94196 1.04486 4.54111 2.81589 2.76998C4.58692 0.998856 6.98811 0.00267139 9.49241 0H111.763C114.267 0.002846 116.668 0.998929 118.439 2.76998C120.21 4.54104 121.207 6.9422 121.208 9.44553V17.2025C121.208 17.8855 120.655 18.4386 119.972 18.4386H119.973ZM2.51907 15.9654H118.736V9.44524C118.735 7.59727 117.999 5.8243 116.692 4.51702C115.385 3.20979 113.612 2.47458 111.763 2.47268H9.4926C7.64369 2.47458 5.87069 3.20979 4.56341 4.51702C3.25618 5.82425 2.52097 7.59737 2.51907 9.44524V15.9654Z" />
        <path d="M16.2797 10.456H11.3769C10.6939 10.456 10.1399 9.90298 10.1399 9.21996C10.1399 8.53694 10.6939 7.98389 11.3769 7.98389H16.2804H16.2794C16.9625 7.98389 17.5165 8.53694 17.5165 9.21996C17.5165 9.90298 16.9625 10.456 16.2794 10.456H16.2797Z" />
        <path d="M29.3917 10.456H24.491C23.8079 10.456 23.2549 9.90298 23.2549 9.21996C23.2549 8.53694 23.8079 7.98389 24.491 7.98389H29.3917C30.0748 7.98389 30.6278 8.53694 30.6278 9.21996C30.6278 9.90298 30.0748 10.456 29.3917 10.456Z" />
        <path d="M42.5028 10.456H37.5994C36.9163 10.456 36.3633 9.90298 36.3633 9.21996C36.3633 8.53694 36.9163 7.98389 37.5994 7.98389H42.5028C43.1849 7.98389 43.7389 8.53694 43.7389 9.21996C43.7389 9.90298 43.1849 10.456 42.5028 10.456Z" />
        <path d="M111.765 107.311H9.49436C6.98909 107.308 4.58703 106.311 2.8159 104.539C1.04477 102.767 0.0495554 100.365 0.048584 97.8593V9.44858C0.0504814 6.94501 1.04656 4.54416 2.8176 2.77304C4.58863 1.00191 6.98981 0.00572314 9.49412 0.00305176H111.764C114.269 0.00589775 116.67 1.00198 118.441 2.77304C120.212 4.54409 121.208 6.94525 121.21 9.44858V97.8593C121.209 100.365 120.214 102.767 118.443 104.539C116.672 106.311 114.269 107.308 111.764 107.311H111.765ZM9.49436 2.47578C7.64545 2.47768 5.87245 3.21289 4.56517 4.52012C3.25794 5.82735 2.52272 7.60047 2.52083 9.44834V97.859C2.52273 99.707 3.25794 101.48 4.56517 102.787C5.8724 104.094 7.64552 104.83 9.49436 104.832H111.765C113.614 104.83 115.387 104.094 116.694 102.787C118.001 101.48 118.736 99.7069 118.738 97.859V9.44834C118.736 7.60037 118.001 5.8274 116.694 4.52012C115.387 3.21289 113.614 2.47768 111.765 2.47578H9.49436Z" />
        <path d="M79.6129 100.105H41.6356C39.6292 100.102 37.7042 99.3046 36.2853 97.8855C34.8663 96.4663 34.0683 94.5414 34.0654 92.5352V86.9874C34.0654 86.3044 34.6194 85.7513 35.3025 85.7513C35.9855 85.7513 36.5386 86.3044 36.5386 86.9874V92.5352C36.5404 93.886 37.0774 95.1818 38.0336 96.1372C38.9889 97.0934 40.2847 97.6303 41.6356 97.6322H79.613C80.9231 97.6369 82.1848 97.1342 83.1334 96.2301C84.082 95.3261 84.6446 94.09 84.7024 92.7809C84.7404 92.1016 85.3143 91.578 85.9935 91.6017C86.3208 91.6159 86.6291 91.7601 86.8502 92.002C87.0721 92.2449 87.1879 92.5646 87.1727 92.8919C87.0883 94.8375 86.2534 96.675 84.8447 98.0202C83.435 99.3644 81.5606 100.112 79.6129 100.105V100.105Z" />
        <path d="M79.6129 100.105H41.6356C39.6292 100.102 37.7042 99.3046 36.2853 97.8854C34.8663 96.4663 34.0683 94.5413 34.0654 92.5351V30.7387C34.0683 28.7314 34.8661 26.8074 36.2853 25.3884C37.7044 23.9694 39.6293 23.1705 41.6356 23.1686H79.6129C81.6203 23.1695 83.5459 23.9673 84.9671 25.3865C86.3873 26.8057 87.1869 28.7306 87.1907 30.7387L87.1746 92.8387V92.8928C87.0892 94.8394 86.2544 96.677 84.8447 98.0211C83.436 99.3654 81.5606 100.113 79.6129 100.106L79.6129 100.105ZM41.6356 25.6392C40.2847 25.6411 38.9889 26.179 38.0336 27.1342C37.0773 28.0905 36.5404 29.3854 36.5385 30.7372V92.5336C36.5404 93.8845 37.0773 95.1802 38.0336 96.1356C38.9888 97.0919 40.2847 97.6288 41.6356 97.6307H79.6129C80.9183 97.6354 82.1753 97.1364 83.1229 96.239C84.0706 95.3406 84.636 94.1131 84.7014 92.8087L84.7176 30.7451C84.7147 29.3924 84.1759 28.0966 83.2187 27.1412C82.2625 26.185 80.9647 25.648 79.6128 25.648L41.6356 25.6392Z" />
        <path d="M85.9536 37.5244H35.3042C34.6212 37.5244 34.0671 36.9704 34.0671 36.2874V30.7416C34.07 28.7333 34.8687 26.8086 36.2889 25.3884C37.708 23.9682 39.6337 23.1695 41.6411 23.1676H79.6185C81.6258 23.1695 83.5515 23.9683 84.9707 25.3884C86.3909 26.8085 87.1896 28.7332 87.1924 30.7416V36.2874C87.1924 36.6156 87.0615 36.9296 86.83 37.162C86.5976 37.3935 86.2836 37.5244 85.9554 37.5244H85.9536ZM36.5403 35.0512H84.7175V30.7415C84.7166 29.3888 84.1787 28.092 83.2215 27.1357C82.2653 26.1794 80.9685 25.6415 79.6166 25.6396H41.6392C40.2874 25.6415 38.9906 26.1794 38.0343 27.1357C37.0771 28.0919 36.5392 29.3887 36.5383 30.7415L36.5403 35.0512Z" />
        <path d="M79.6185 100.105H41.6411C39.6338 100.102 37.7081 99.3036 36.2889 97.8835C34.8687 96.4643 34.07 94.5387 34.0671 92.5312V86.9845C34.0671 86.3015 34.6211 85.7484 35.3042 85.7484H85.9536C86.2819 85.7484 86.5959 85.8784 86.8274 86.1108C87.0598 86.3423 87.1897 86.6572 87.1897 86.9845V92.5313C87.1878 94.5386 86.3891 96.4633 84.9699 97.8835C83.5498 99.3037 81.6251 100.102 79.6177 100.105L79.6185 100.105ZM36.5412 88.2214V92.5311C36.5421 93.8829 37.08 95.1797 38.0372 96.137C38.9934 97.0932 40.2902 97.6311 41.6421 97.632H79.6195C80.9713 97.6301 82.2681 97.0923 83.2244 96.136C84.1797 95.1798 84.7176 93.883 84.7195 92.5311V88.2214H36.5412Z" />
        <path d="M67.2982 31.5818H53.9612C53.2781 31.5818 52.7251 31.0278 52.7251 30.3457C52.7251 29.6627 53.2781 29.1086 53.9612 29.1086H67.2982C67.9803 29.1086 68.5343 29.6626 68.5343 30.3457C68.5343 31.0278 67.9803 31.5818 67.2982 31.5818Z" />
        <path d="M60.6287 97.0696C59.5292 97.0696 58.4752 96.6332 57.6984 95.8563C56.9215 95.0794 56.4841 94.0245 56.4841 92.926C56.4841 91.8266 56.9215 90.7726 57.6984 89.9958C58.4753 89.2179 59.5293 88.7815 60.6287 88.7815C61.728 88.7815 62.7821 89.2179 63.5589 89.9958C64.3359 90.7727 64.7732 91.8267 64.7732 92.926C64.7713 94.0246 64.334 95.0776 63.558 95.8546C62.7811 96.6315 61.7271 97.0689 60.6287 97.0698L60.6287 97.0696ZM60.6287 91.2591C59.9523 91.2591 59.3432 91.6661 59.0843 92.2912C58.8253 92.9164 58.9685 93.6355 59.4476 94.1126C59.9257 94.5908 60.6448 94.734 61.269 94.4741C61.8941 94.2151 62.3011 93.6051 62.3002 92.9297C62.3002 92.4857 62.1247 92.0598 61.8107 91.7458C61.4976 91.4318 61.0726 91.2553 60.6287 91.2553V91.2591Z" />
        <path d="M60.6295 78.4307C55.4888 78.4477 50.6278 76.0875 47.4612 72.0378C47.1321 71.6413 46.8275 71.2248 46.5486 70.7913C44.3412 67.3952 43.4353 63.315 43.9987 59.3041C44.5622 55.2921 46.5562 51.619 49.6137 48.9619C52.6712 46.3049 56.5853 44.8429 60.637 44.8439C64.6878 44.8458 68.6009 46.3114 71.6565 48.9714C74.7121 51.6304 76.7032 55.3045 77.263 59.3174C77.8237 63.3294 76.9139 67.4083 74.7036 70.8027C74.4199 71.241 74.1107 71.6622 73.7768 72.0634C70.6112 76.1009 65.7597 78.4498 60.6294 78.4308L60.6295 78.4307ZM60.6295 47.3139C57.1754 47.3148 53.8381 48.5642 51.2323 50.8304C48.6264 53.0977 46.9272 56.229 46.4483 59.6499C45.9692 63.0707 46.7424 66.5493 48.6245 69.4446C48.8559 69.8042 49.1092 70.1504 49.3824 70.4796C52.0871 73.9317 56.227 75.9503 60.6125 75.9552C64.9972 75.9609 69.1417 73.9516 71.8539 70.5053C72.1337 70.1714 72.3918 69.8213 72.628 69.4561C74.5139 66.5608 75.2899 63.0812 74.8118 59.6585C74.3346 56.2367 72.6356 53.1024 70.0298 50.8341C67.4239 48.565 64.0847 47.3157 60.6296 47.3137L60.6295 47.3139Z" />
        <path d="M60.6291 78.4306C55.4874 78.4439 50.6275 76.0856 47.4573 72.0377C47.1025 71.589 47.1025 70.9553 47.4573 70.5057C50.6372 66.4702 55.4913 64.1147 60.6291 64.1147C65.7669 64.1147 70.6213 66.4702 73.801 70.5057C74.1558 70.9553 74.1558 71.589 73.801 72.0377C70.6305 76.0856 65.7708 78.444 60.6291 78.4306ZM50.0451 71.2713C52.7544 74.2557 56.5982 75.9586 60.6291 75.9586C64.66 75.9586 68.5039 74.2558 71.2132 71.2713C68.5029 68.2878 64.66 66.5869 60.6291 66.5869C56.5982 66.5869 52.7553 68.2878 50.0451 71.2713Z" />
        <path d="M60.6292 63.3712C58.7935 63.3684 57.0347 62.6361 55.7379 61.3364C54.442 60.0367 53.7144 58.276 53.7163 56.4405C53.7182 54.6049 54.4496 52.845 55.7483 51.5482C57.047 50.2504 58.8088 49.5229 60.6442 49.5238C62.4797 49.5248 64.2397 50.2543 65.5372 51.553C66.835 52.8517 67.5636 54.6115 67.5636 56.4469C67.5617 58.2845 66.8303 60.046 65.5297 61.3448C64.2291 62.6435 62.4666 63.3721 60.6289 63.3711L60.6292 63.3712ZM60.6292 51.9865C59.45 51.9894 58.3202 52.4608 57.4871 53.2956C56.6552 54.1314 56.1884 55.2622 56.1894 56.4413C56.1903 57.6205 56.6599 58.7513 57.4938 59.5851C58.3276 60.4189 59.4584 60.8876 60.6375 60.8885C61.8167 60.8895 62.9475 60.4218 63.7832 59.5888C64.618 58.7569 65.0885 57.6261 65.0914 56.4468C65.0904 55.2639 64.6199 54.1293 63.7832 53.2926C62.9465 52.4569 61.8119 51.9864 60.629 51.9864L60.6292 51.9865Z" />
        <path d="M107.677 47.3138H13.5808C12.8978 47.3138 12.3447 46.7608 12.3447 46.0777C12.3447 45.3947 12.8978 44.8417 13.5808 44.8417H107.677C108.36 44.8417 108.913 45.3947 108.913 46.0777C108.913 46.7608 108.36 47.3138 107.677 47.3138Z" />
        <path d="M107.677 78.4306H13.5808C12.8978 78.4306 12.3447 77.8776 12.3447 77.1946C12.3447 76.5115 12.8978 75.9585 13.5808 75.9585H107.677C108.36 75.9585 108.913 76.5115 108.913 77.1946C108.913 77.8776 108.36 78.4306 107.677 78.4306Z" />
        <path d="M107.677 78.4306H13.5808C12.8978 78.4306 12.3447 77.8776 12.3447 77.1946C12.3447 76.5115 12.8978 75.9585 13.5808 75.9585H107.677C108.36 75.9585 108.913 76.5115 108.913 77.1946C108.913 77.8776 108.36 78.4306 107.677 78.4306Z" />
        <path d="M20.0549 88.3221C19.3728 88.3221 18.8188 87.769 18.8188 87.086V36.1864C18.8188 35.5034 19.3728 34.9493 20.0549 34.9493C20.7379 34.9493 21.292 35.5033 21.292 36.1864V87.086C21.292 87.4142 21.1611 87.7282 20.9296 87.9606C20.6981 88.1921 20.3832 88.3221 20.0549 88.3221V88.3221Z" />
        <path d="M101.203 88.3221C100.52 88.3221 99.9658 87.769 99.9658 87.086V36.1864C99.9658 35.5034 100.52 34.9493 101.203 34.9493C101.885 34.9493 102.439 35.5033 102.439 36.1864V87.086C102.439 87.4142 102.308 87.7282 102.077 87.9606C101.845 88.1921 101.53 88.3221 101.203 88.3221L101.203 88.3221Z" />
      </svg>,
      description: 'Create better websites with mobile'
    }
  
  ]