import App from "./App"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { SnackbarProvider } from "notistack"
import { AuthProvider } from "./contexts/AuthContext/AuthContext"
import CartState from "./contexts/CartContext/CartState"
import ProductsContextProvider from "./contexts/ProductContext/ProductContext"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
	<React.StrictMode>
		<SnackbarProvider maxSnack={3}>
			<BrowserRouter>
				<AuthProvider>
					<CartState>
						<ProductsContextProvider>
							<Routes>
								<Route path="/*" element={<App />} />
							</Routes>
						</ProductsContextProvider>
					</CartState>
				</AuthProvider>
			</BrowserRouter>
		</SnackbarProvider>
	</React.StrictMode>
)
