import "./Analytics.css"
import React, { useEffect, useState } from "react"
import DashboardCards from "../Components/DashboardCards"
import { FaCartArrowDown } from "react-icons/fa"
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	BarChart,
	Bar,
	PieChart,
	Pie,
	Cell,
	Legend,
} from "recharts"
import { getPayments } from "../../../AdminServices/AdminServices"
import { useProducts } from "../../../../hooks/useProducts"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"
import { getVisitorsCount } from "../../../../services/CartService"
import {
	analyticsData,
	COLORS,
	getMonthName,
	mergeArrayObjects,
	months,
} from "../../../../constants/constants"
import { GrGroup } from "react-icons/gr"
import { useSnackbar } from "notistack"
import { getOrderSummary } from "../../../../Pages/OrderSummary/OrderServices"
import { getProductsByUserId } from "../../../../services/OtherServices"

const Analytics = () => {
	const { products } = useProducts()
	const { currentUser } = useAuthValue()
	const { enqueueSnackbar } = useSnackbar()
	const [orders, setOrders] = useState([])
	const [visitorsSum, setVisitorsSum] = useState(0)
	const [barGraphData, setBarGraphData] = useState([])
	const [userProducts, setUserProducts] = useState([])
	const [pieChartData, setPieChartData] = useState([])
	const [areaChartData, setAreaChartData] = useState([])

	useEffect(() => {
		// if (currentUser?.roles.includes("ADMIN", "DEV")) {
		getPayments().then((res) => {
			let mergedOrders = [].concat.apply(
				[],
				res.map((item) => item.products)
			)

			if (currentUser?.roles?.includes("ADMIN")) {
				setOrders(mergedOrders)
			} else {
				let currentUserOrders = mergedOrders.filter((item) => {
					if (item.userId === currentUser?.uid) {
						return item
					}
				})

				setOrders(currentUserOrders)
			}
		})
		// }
	}, [currentUser?.roles, currentUser?.uid, products])

	useEffect(() => {
		let ordersIdArray = orders?.map((item) => item.productId)
		getVisitorsCount().then((res) => {
			let visitors = res
				.filter((item) => {
					if (ordersIdArray?.find((id) => id === item?.productId)) {
						return item
					}
				})
				.map((item) => item?.totalVisitors)
				.reduce((a, b) => {
					return a + b
				}, 0)

			let visitorstemp = res
				.filter((item) => {
					if (ordersIdArray?.find((id) => id === item?.productId)) {
						return item
					}
				})
				.map((item) => item?.visitedAt)
				.flat()
				.map((item) => {
					return getMonthName(item?.time?.toDate().getMonth()).slice(
						0,
						3
					)
				})

			const count = {}

			for (const element of visitorstemp) {
				if (count[element]) {
					count[element] += 1
				} else {
					count[element] = 1
				}
			}

			let tempGraphData = months.map((item) => {
				return {
					month: item,
					visitors: count[item] ? count[item] : 0,
				}
			})
			setBarGraphData(tempGraphData)
			setVisitorsSum(visitors)
		})
	}, [orders])

	useEffect(() => {
		let ordersIdArray = orders?.map((item) => item.productId)
		getVisitorsCount().then((res) => {
			let productVisitors = res
				.filter((item) => {
					if (ordersIdArray?.find((id) => id === item?.productId)) {
						return item
					}
				})
				.map((item) => {
					return {
						productId: item?.productId,
						value: item?.visitedAt?.length,
					}
				})
			setPieChartData(mergeArrayObjects(userProducts, productVisitors))
		})
	}, [orders, userProducts])

	useEffect(() => {
		// if (currentUser?.roles?.includes("ADMIN")) {
		getProductsByUserId(currentUser?.uid)
			.then((res) => {
				let tempUserProducts = res.map((item) => {
					return {
						productId: item.productId,
						name: item.title,
					}
				})
				setUserProducts(tempUserProducts)
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				})
			})
		// }
	}, [currentUser?.roles, currentUser?.uid, enqueueSnackbar])

	useEffect(() => {
		getOrderSummary(currentUser?.uid).then((res) => {
			let tempAreaData = res.map((item) => {
				return {
					month: getMonthName(item?.date?.toDate().getMonth()).slice(
						0,
						3
					),
					amount: item.products
						.map((item) => Number(item.price))
						.reduce((a, b) => {
							return a + b
						}, 0),
				}
			})

			let holder = {}
			let tempArray = []

			tempAreaData.forEach((d) => {
				if (holder.hasOwnProperty(d.month)) {
					holder[d.month] = holder[d.month] + d.amount
				} else {
					holder[d.month] = d.amount
				}
			})

			for (let prop in holder) {
				tempArray.push({ month: prop, amount: holder[prop] })
			}

			let tempAreaChartData = months.map((item) => {
				const index = tempArray.findIndex(
					(object) => object.month === item
				)

				if (tempArray[index]) return tempArray[index]
				else return { month: item, amount: 0 }
			})

			setAreaChartData(tempAreaChartData)
		})
	}, [currentUser?.uid])

	return (
		<div className="analytics_container">
			<div className="analytics_cards">
				<DashboardCards
					color={"#398061"}
					value={"Total Orders"}
					label={orders.length}
					icon={<FaCartArrowDown />}
				/>
				<DashboardCards
					color={"#F2AC3C"}
					value={"Total Visitors"}
					label={visitorsSum}
					icon={<GrGroup />}
				/>

				{/* {analyticsData.map((e, i) => (
					<DashboardCards
						color={e.color}
						key={i}
						value={e.label}
						label={e.value}
						icon={e.icon}
						// percent={e.percent}
					/>
				))} */}
			</div>
			<div className="area_graph_card">
				<div className="area_graph_button_container">
					<button>Income</button>
					{/* <button>Expenses</button> */}
				</div>

				<AreaChart
					width={1150}
					height={300}
					data={areaChartData}
					margin={{ top: 25, right: 25, left: 25, bottom: 25 }}
				>
					<defs>
						<linearGradient
							id="colorPv"
							x1="0"
							y1="0"
							x2="0"
							y2="1"
						>
							<stop
								offset="5%"
								stopColor="rgba(166, 50, 223, 0.2)"
								stopOpacity={0.8}
							/>
							<stop
								offset="95%"
								stopColor="#8884d8"
								stopOpacity={0}
							/>
						</linearGradient>
					</defs>
					<XAxis dataKey="month" />
					<YAxis />
					<CartesianGrid horizontal vertical={false} />
					<Tooltip
						content={CustomTooltip}
						contentStyle={{
							backgroundColor: "#7A1FDD",
							borderRadius: "25%",
						}}
					/>
					<Area
						type="monotone"
						dataKey="amount"
						stroke="#8884d8"
						fillOpacity={1}
						fill="url(#colorPv)"
					/>
				</AreaChart>
			</div>
			<div className="bar_donut_container">
				<div className="bar_graph_container">
					<BarChart
						width={540}
						height={250}
						barSize={15}
						data={barGraphData}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="month" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Bar dataKey="visitors" fill="#454FDE" />
					</BarChart>
				</div>

				<div className="donut_graph_container">
					<PieChart width={540} height={300}>
						<Tooltip />
						<Pie
							data={pieChartData}
							innerRadius={80}
							outerRadius={110}
							fill="#8884d8"
							dataKey="value"
						>
							{analyticsData.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={COLORS[index % COLORS.length]}
								/>
							))}
						</Pie>
						<Legend
							layout={"vertical"}
							verticalAlign="middle"
							align="right"
							iconType="circle"
						/>
					</PieChart>
				</div>
			</div>
		</div>
	)
}

const CustomTooltip = ({ active, payload }) => {
	if (active) {
		return (
			<div className="custom-tooltip">
				<p>{`${payload ? payload[0]?.value : null}`}</p>
			</div>
		)
	}

	return null
}

export default Analytics
