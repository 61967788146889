import { addDoc, collection, getDocs, query } from "firebase/firestore"
import { db } from "../../../services/firebase"

export const getSubscriptions = async () => {
	const querySnapshot = await getDocs(query(collection(db, "subscriptions")))

	let tempArray = []
	querySnapshot.forEach((doc) => {
		let obj = doc.data()
		tempArray.push(obj)
	})

	return tempArray
}

export const addSubscription = async (data) => {
	try {
		await addDoc(collection(db, "subscriptions"), data)
	} catch (err) {
		alert(err)
	}
}
