import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { getBlogs } from "../../admin/AdminServices/AdminServices"
import Loader from "../../UI/Loader/Loader"
import Headline from "../HomePage/Components/Headline/Headline"

const Blogs = () => {
	const { enqueueSnackbar } = useSnackbar()
	const [tableData, setTableData] = useState([])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		getBlogs()
			.then((res) => {
				setTableData(res)
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				})
			})
	}, [enqueueSnackbar])

	return (
		<div>
			<Headline bgText="Blogs" mainText="Blogs" />
			<div className="order-list">
				{tableData?.length > 0 ? (
					tableData?.map((blog) => (
						<>
							{blog.publish ? (
								<div className="order-item" key={blog?.blogId}>
									<img
										className="order-item-image"
										alt="blogImage"
										src={
											blog?.coverImageUrl ||
											"https://picsum.photos/500/300"
										}
									/>

									<div className="item-details">
										<p className="order-item-text">
											{blog?.title}
										</p>
										<p className="order-item-secondary-text">
											{blog?.subtitle}
										</p>
										<p className="order-item-secondary-text">
											<a href={blog?.blogLink}>
												Read Here
											</a>
										</p>
										<p className="order-item-secondary-text">
											Published:{" "}
											{new Date(
												blog.created?.toDate()
											).toLocaleDateString("en-us", {
												weekday: "short",
												year: "numeric",
												month: "short",
												day: "numeric",
											})}
										</p>
										{blog.updated ? (
											<p className="order-item-secondary-text">
												Updated:{" "}
												{new Date(
													blog.updated?.toDate()
												).toLocaleDateString("en-us", {
													weekday: "short",
													year: "numeric",
													month: "short",
													day: "numeric",
												})}
											</p>
										) : null}
									</div>
								</div>
							) : (
								<h3 style={{ textAlign: "center" }}>
									No Blogs Published
								</h3>
							)}
						</>
					))
				) : tableData?.length === 0 ? (
					<div style={{ textAlign: "center", fontSize: "1rem" }}>
						No Blogs Found
					</div>
				) : (
					<Loader />
				)}
			</div>
		</div>
	)
}

export default Blogs
