export const MinusCircleIcon = ({ width }) => (
	<svg width={width} fill="currentColor" viewBox="0 0 20 20">
		<path
			d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
			clipRule="evenodd"
			fillRule="evenodd"
		></path>
	</svg>
)

export const PlusCircleIcon = ({ width }) => (
	<svg width={width} fill="currentColor" viewBox="0 0 20 20">
		<path
			d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
			clipRule="evenodd"
			fillRule="evenodd"
		></path>
	</svg>
)

export const CartIcon = ({ width }) => (
	<svg width={width} fill="currentColor" viewBox="0 0 20 20">
		<path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
	</svg>
)

export const TrashIcon = ({ width }) => (
	<svg width={width} fill="currentColor" viewBox="0 0 20 20">
		<path
			d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
			clipRule="evenodd"
			fillRule="evenodd"
		></path>
	</svg>
)
