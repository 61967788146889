import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavItem.css";
import NavItemHeader from "./NavItemHeader.jsx";

const NavItem = (props) => {
	const { label, Icon, to, children } = props.item;

	const location = useLocation();

	const [expanded, setExpand] = useState(location.pathname.includes(to));

	const onExpandChange = (e) => {
		e.preventDefault();
		setExpand((expanded) => !expanded);
	};

	return (
		<NavLink
			exact={`true`}
			className={({ isActive }) => (isActive ? "activeNavItem" : "navItem")}
			to={to}>
			{children ? (
				<NavItemHeader
					expanded={expanded}
					onExpandChange={onExpandChange}
					item={props.item}
				/>
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "start",
						alignItems: "center",
					}}>
					<span className="navIcon">{Icon}</span>
					<span className="navLabel">{label}</span>
				</div>
			)}
		</NavLink>
	);
};

export default NavItem;
