import React from "react";
import "./input.css";
import { Controller } from "react-hook-form";

const Input = ({
	label,
	name,
	type,
	defaultValue,
	placeholder,
	error,
	value,
	onChange,
	required,
	width,
	control,
	rules,
}) => {
	return (
		<>
			{control ? (
				<Controller
					control={control}
					name={name}
					rules={rules}
					defaultValue={defaultValue}
					render={({ field }) => (
						<div className="input">
							{label ? <label>{label}</label> : null}
							<input {...field} placeholder={placeholder} name={name} />
							{error ? (
								<span className="error">{error ? error : " "}</span>
							) : null}
						</div>
					)}
				/>
			) : (
				<div className="input">
					{label ? <label>{label}</label> : null}
					<input
						width={width}
						type={type}
						placeholder={placeholder}
						name={name}
						value={value}
						onChange={onChange}
						required={required}
					/>
					<span className="error">{error ? error : " "}</span>
				</div>
			)}
		</>
	);
};

export default Input;
