import { useNavigate } from "react-router-dom";

const List = ({ title, list }) => {
	let navigate = useNavigate();
	return (
		<nav>
			<p
				style={{
					fontWeight: "bold",
					margin: "0",
				}}>
				{title}
			</p>
			<ul aria-label="Footer" style={{ listStyle: "none", marginTop: "10%" }}>
				{list.map((item) => (
					<li
						style={{ lineHeight: "1.75rem", fontWeight: "300" }}
						key={item.title}>
						<div
							style={{
								textDecoration: "none",
								cursor: "pointer",
							}}
							onClick={() => navigate(item.path)}>
							{item.title}
						</div>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default List;
