import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-scroll"
import avatarIcon from "../../../Assets/HomePageAssets/LandingPageAssets/avatar.svg"
import logo from "../../../Assets/HomePageAssets/LandingPageAssets/Logo3.svg"
import MenuIcon from "../../../Assets/HomePageAssets/LandingPageAssets/MenuIcon.svg"
import MenuIconOpen from "../../../Assets/HomePageAssets/LandingPageAssets/MenuOpenIcon.svg"
import "./Navbar.css"

import { navItems } from "../../../constants/constants"

import { useAuthValue } from "../../../contexts/AuthContext/AuthContext"
import { useCart } from "../../../hooks/useCart"
import { logout } from "../../../services/AuthService"
import { getUser } from "../../../services/OtherServices"
import CartBadge from "./CartBadge/badge"
import DropDown from "./DropDown"
import FavBadge from "./FavBadge/FavBadge"

const Navbar = () => {
	let navigate = useNavigate()
	const menuRef = useRef(null)
	const [menu, setMenu] = useState(false)
	const [class_name, setClassName] = useState("")
	const { currentUser, setCurrentUser } = useAuthValue()
	const [user, setUser] = useState()
	const { itemCount, favItemCount } = useCart()
	const [avatar, setAvatar] = useState(avatarIcon)
	const [lastScrollY, setLastScrollY] = useState(0)
	const [visible, setVisible] = useState(true)

	useEffect(() => {
		getUser(currentUser?.uid)
			.then((data) => {
				setUser(data[0])
			})
			.catch((err) => err)
	}, [currentUser?.uid])

	useEffect(() => {
		if (currentUser?.photoURL) {
			setAvatar(currentUser?.photoURL)
		}
	}, [currentUser?.photoURL])

	useEffect(() => {
		window.addEventListener(
			"scroll",
			() => {
				let activeClass = "header-boxshadow"
				if (window.scrollY === 0) {
					activeClass = ""
				}
				setClassName(activeClass)
			},
			{ passive: true }
		)
	}, [])

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (
				menu &&
				menuRef.current &&
				!menuRef.current.contains(e.target)
			) {
				setMenu(false)
			}
		}
		document.addEventListener("mousedown", checkIfClickedOutside, {
			passive: true,
		})
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [menu])

	const controlNavbar = () => {
		if (typeof window !== "undefined") {
			if (window.scrollY > lastScrollY) {
				// if scroll down hide the navbar
				setVisible(false)
			} else {
				// if scroll up show the navbar
				setVisible(true)
			}

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY)
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", controlNavbar, { passive: true })

			// cleanup function
			return () => {
				window.removeEventListener("scroll", controlNavbar)
			}
		}
	}, [lastScrollY])

	let navLinks = Object.values(navItems)

	return (
		<header
			className={`primary-header ${class_name} ${
				!visible && "navbar--hidden"
			}`}
		>
			<div className="nav-wrapper" ref={menuRef}>
				<img
					onClick={() => navigate("/")}
					src={logo}
					alt="flutterion-logo"
				/>

				<button
					onClick={() => {
						setMenu(!menu)
					}}
					className="mobile-nav-toggle"
					aria-controls="primary-navigation"
					aria-expanded="false"
				>
					{!menu ? (
						<img
							className="icon-hamburger"
							src={MenuIcon}
							alt="icon-hamburger"
							aria-hidden="true"
						/>
					) : (
						<img
							className="icon-close"
							src={MenuIconOpen}
							alt="icon-hamburger"
							aria-hidden="true"
						/>
					)}
					<span className="visually-hidden">Menu</span>
				</button>

				<nav
					className={`primary-navigation ${
						menu ? "nav_open" : "nav_close"
					}`}
					id="primary-navigation"
				>
					<ul aria-label="Primary" className="nav-list">
						<li
							className="list-hidden"
							onClick={() => {
								currentUser
									? navigate("/login")
									: navigate("/profile")
							}}
						>
							{currentUser && user
								? user.firstName
									? user.firstName[0] + user.lastName[0]
									: "Profile"
								: "Login"}
						</li>
						<li
							onClick={() => {
								navigate("/cart")
							}}
							className="list-hidden"
						>{`Cart (${itemCount})`}</li>
						{navLinks.map((item) => {
							return (
								<li
									key={item.title}
									onClick={() => navigate(item.path)}
								>
									{item.path === "services" ||
									item.path === "casestudy" ? (
										<Link
											activeClass="active"
											smooth
											spy
											to={item.path}
										>
											{item.title}
										</Link>
									) : (
										item.title
									)}
								</li>
							)
						})}
						{currentUser && (
							<li
								className="list-hidden"
								onClick={() => {
									navigate("/orders")
								}}
							>{`Orders`}</li>
						)}
						{currentUser && (
							<li
								onClick={() => {
									setCurrentUser(null)
									localStorage.clear()
									logout()
									navigate("/login")
								}}
								className="list-hidden"
							>{`Signout`}</li>
						)}
					</ul>
				</nav>

				<div className="cartAvatar">
					<div title="Favoutite Cart">
						<FavBadge count={favItemCount} />
					</div>
					<div title="Cart">
						<CartBadge count={itemCount} />
					</div>

					<div title="Profile">
						{currentUser ? (
							<DropDown
								icon={
									!currentUser?.photoURL &&
									user &&
									user?.firstName &&
									user?.lastName ? (
										<p className="desk-avatar">
											{user?.firstName[0] +
												user?.lastName[0]}
										</p>
									) : (
										<div className="avatar">
											<img src={avatar} alt="avatar" />
										</div>
									)
								}
							/>
						) : (
							<div className="avatar">
								<img
									src={avatarIcon}
									alt="avatar"
									onClick={() => navigate("/login")}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</header>
	)
}

export default Navbar
