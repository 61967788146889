import React, { useState } from "react";
import { FaNodeJs, FaReact, FaAngular } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {
	SiExpress,
	SiDeno,
	SiMongodb,
	SiAmazonaws,
	SiJenkins,
	SiFlutter,
	SiDart,
	SiTypescript,
	SiPython,
	SiMicrosoftazure,
	SiJava,
	SiPhp,
} from "react-icons/si";
import Headline from "../Headline/Headline";
import "./techStack.css";
import { useRef } from "react";

const TechStacks = () => {
	const carousel = useRef();
	const LogoArr = [
		<img
			alt="vue-js"
			src="https://img.icons8.com/color/96/000000/vue-js.png"
		/>,

		<img
			alt="react/react-native"
			src="https://img.icons8.com/color/96/000000/react-native.png"
		/>,
		<img
			alt="angular-js"
			src="https://img.icons8.com/color/96/000000/angularjs.png"
		/>,

		<img
			alt="flutter"
			src="https://img.icons8.com/color/96/000000/flutter.png"
		/>,
		<img
			alt="node-js"
			src="https://img.icons8.com/fluency/96/000000/node-js.png"
		/>,
		<img
			alt="python"
			src="https://img.icons8.com/color/96/000000/python--v1.png"
		/>,
		<img
			alt="java"
			src="https://img.icons8.com/color/96/000000/java-coffee-cup-logo--v1.png"
		/>,
		<img
			alt="amazon-web-services"
			src="https://img.icons8.com/color/96/000000/amazon-web-services.png"
		/>,
		<img alt="dart" src="https://img.icons8.com/color/96/000000/dart.png" />,
		<img
			alt="php"
			src="https://img.icons8.com/officel/96/000000/php-logo.png"
		/>,
		<img
			alt="firebase"
			src="https://img.icons8.com/color/96/000000/firebase.png"
		/>,
		<img
			alt="typescript"
			src="https://img.icons8.com/color/96/000000/typescript.png"
		/>,

		<img
			alt="graph-ql"
			src="https://img.icons8.com/color/96/000000/graphql.png"
		/>,
		<img
			alt="mongodb"
			src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/96/000000/external-mongodb-a-cross-platform-document-oriented-database-program-logo-shadow-tal-revivo.png"
		/>,
	];

	const LogoIconArr = [
		<SiFlutter size={60} />,
		<SiExpress size={60} />,
		<SiDeno size={60} />,
		<SiMongodb size={60} />,
		<SiAmazonaws size={60} />,
		<FaAngular size={60} />,
		<FaNodeJs size={60} />,
		<FaReact size={60} />,
		<SiJenkins size={60} />,
		<SiDart size={60} />,
		<SiTypescript size={60} />,
		<SiPython size={60} />,
		<SiMicrosoftazure size={60} />,
		<SiJava size={60} />,
		<SiPhp size={60} />,
	];

	const [firstMember, setFirstMember] = useState(0);
	const [lastMember, setlastMember] = useState(7);
	const handlePrevious = () => {
		if (firstMember !== 0) {
			setFirstMember((firstMember) => firstMember - 1);
			setlastMember((lastMember) => lastMember - 1);
		}
	};

	const currentMembers = LogoArr.slice(firstMember, lastMember);

	const handleNext = () => {
		if (lastMember !== LogoArr.length) {
			setFirstMember((firstMember) => firstMember + 1);
			setlastMember((lastMember) => lastMember + 1);
		}
	};
	return (
		<div className="tech-container">
			<Headline bgText="Tech" mainText="Tech Stacks" subText="Tech we love" />
			<div className="tech-list">
				<div>
					<button
						className="btn_previous"
						onClick={() => {
							carousel.current.prev();
						}}>
						<AiOutlineArrowLeft />
					</button>
				</div>

				{/* <div className="tech-item-list">
					{currentMembers.length &&
						currentMembers.map((data, index) => (
							<div key={index} className="tech_logo">
								{data}
							</div>
						))}
				</div> */}
				<div style={{ width: "82%", height: "6rem" }}>
					<OwlCarousel
						ref={carousel}
						items={6}
						loop
						margin={22}
						dots={false}
						nav={false}
						autoWidth
						center>
						{LogoIconArr.length &&
							LogoIconArr.map((data, index) => (
								<div key={index} className="tech_logo">
									{data}
								</div>
							))}
					</OwlCarousel>
				</div>
				<div>
					<button
						onClick={() => {
							carousel.current.next();
						}}
						className="btn_next">
						<AiOutlineArrowRight />
					</button>
				</div>
			</div>
		</div>
	);
};

export default TechStacks;
