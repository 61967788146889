import React from "react";
import "./DashboardCards.css";

const DashboardCards = (props) => {
	const { label, value, icon, percent, color } = props;
	return (
		<div className="dashbaord_card_container">
			<div style={{ color: color }} className="dashbaord_card_up">
				<div className="card_icon">{icon}</div>
				<div className="card_percent">{percent}</div>
			</div>
			<div className="dashbaord_card_down">
				<div>{label}</div>
				<div>{value}</div>
			</div>
		</div>
	);
};

export default DashboardCards;
