import React from "react"
import "./ProductOverview.css"

const ProductOverview = ({ data }) => {
	return (
		data?.overview && (
			<div className="productOverview">
				<p className="name">Overview</p>
				<>
					{data?.overview?.map((overview) => {
						return <p className="desc">{overview}</p>
					})}
				</>
			</div>
		)
	)
}

export default ProductOverview
