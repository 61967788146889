import "./Login.css"
import React, { useEffect, useState } from "react"
import gmail from "../../../../Assets/LoginAssets/Gmail.svg"
import facebook from "../../../../Assets/LoginAssets/Facebook.svg"
import or from "../../../../Assets/LoginAssets/or.svg"
import Input from "../InputField/Input"
import { useNavigate } from "react-router-dom"

import {
	signInWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
} from "firebase/auth"

import logo from "../../../../Assets/HomePageAssets/LandingPageAssets/Logo.svg"
import { useSnackbar } from "notistack"
import { firebaseErrorsMsg } from "../../../../constants/constants"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"
import { auth } from "../../../../services/firebase"
import {
	signInWitFacebook,
	signInWithGoogle,
} from "../../../../services/AuthService"

const Login = () => {
	let navigate = useNavigate()
	const { setTimeActive, currentUser } = useAuthValue()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		if (currentUser) navigate("/profile")
	}, [currentUser, navigate])

	const onLogin = (e) => {
		e.preventDefault()
		signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				if (!auth.currentUser.emailVerified) {
					sendEmailVerification(auth.currentUser)
						.then(() => {
							setTimeActive(true)
							navigate("/verify-email")
						})
						.catch((err) =>
							enqueueSnackbar(firebaseErrorsMsg(err), {
								variant: "error",
							})
						)
				} else {
					navigate("/")
					enqueueSnackbar("Logged In", {
						variant: "success",
					})
				}
			})
			.catch((err) => {
				enqueueSnackbar(firebaseErrorsMsg(err), {
					variant: "error",
				})
			})
	}

	const triggerResetEmail = async () => {
		await sendPasswordResetEmail(auth, email)
			.then(() => {
				enqueueSnackbar("Password reset email sent", {
					variant: "success",
				})
			})
			.catch((err) => {
				enqueueSnackbar(firebaseErrorsMsg(err), {
					variant: "error",
				})
			})
	}

	return (
		<div className="Login">
			<p className="home_btn" onClick={() => navigate("/")}>
				Home
			</p>

			<div className="login_fields">
				<form
					className="login_form"
					onSubmit={onLogin}
					name="login_form"
				>
					<div className="login_image">
						<img src={logo} alt="logo" />
					</div>

					<p className="welcome">Welcome back! 👋</p>
					<p className="login_heading">Login to your account</p>

					<div className="fields">
						<Input
							label="Email"
							name="email"
							type="email"
							value={email}
							required
							placeholder="Please enter your email"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Input
							label="Password"
							name="password"
							type="password"
							value={password}
							required
							placeholder="Enter password"
							onChange={(e) => setPassword(e.target.value)}
						/>
						<p className="forgot_pass" onClick={triggerResetEmail}>
							Forgot password?
						</p>
						<button type="submit" className="login_button">
							Login
						</button>
						<p className="not_registered">
							Not registered?{" "}
							<span onClick={() => navigate("/signup")}>
								Create an account
							</span>
						</p>
					</div>

					<img src={or} alt="or" />

					<div className="login_using">
						<p>Login using</p>
						<img
							src={gmail}
							alt="gmail"
							onClick={signInWithGoogle}
						/>

						<img
							src={facebook}
							alt="facebook"
							onClick={signInWitFacebook}
						/>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Login
