import "./Footer.css";
import { useState } from "react";
import logo from "../../../Assets/HomePageAssets/LandingPageAssets/Logo3.svg";
import message from "../../../Assets/HomePageAssets/FooterAssets/message.svg";
import List from "./List/List";
import deskguy from "../../../Assets/HomePageAssets/FooterAssets/deskguy.svg";
import { FiFacebook, FiInstagram, FiGithub, FiTwitter } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { addSubscription, getSubscriptions } from "./FooterServices";
import {
	aboutFlutter,
	learnFlutter,
	multiPlatform,
} from "../../../constants/constants";
import { Timestamp } from "firebase/firestore";

const Footer = () => {
	let navigate = useNavigate();
	const [email, setEmail] = useState("");
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isEmailValid = emailValidation();
		if (isEmailValid) {
			getSubscriptions().then((res) => {
				if (
					!!res.map((item) => item.email).find((element) => element === email)
				) {
					enqueueSnackbar("email already subscribed!", {
						variant: "info",
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
					});
				} else {
					let data = {
						email: email,
						date: Timestamp.fromDate(new Date()),
					};
					addSubscription(data)
						.then(() => {
							enqueueSnackbar("email subscribed successfully!", {
								variant: "success",
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "right",
								},
							});
							setEmail("");
						})
						.catch(() => {
							enqueueSnackbar("subscription failed", {
								variant: "error",
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "right",
								},
							});
						});
				}
			});
		} else {
			enqueueSnackbar("enter correct email", {
				variant: "error",
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right",
				},
			});
		}
	};

	const emailValidation = () => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		return !(!email || regex.test(email) === false);
	};

	return (
		<footer  >
			<div className="container" style={{ backgroundColor: "" }}>
				<div className="footer_flex">
					<div>
						<img
							style={{ width: "50%",marginTop:'4%' }}
							onClick={() => navigate("/")}
							src={logo}
							alt="Logo"
						/>

						<div>
							<p style={{ padding: "1.5rem 0" }}>
								Making the world a better place through constructing elegant
								hierarchies
							</p>
							<p style={{ fontWeight: "700" }}>Subscribe</p>
							<p style={{ padding: "1.5rem 0" }}>
								Subscribe to our newsletter for the latest updates
							</p>

							<form className="subscribeInput" onSubmit={handleSubmit}>
								<input
									title="email"
									type="email"
									placeholder="Email address"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
								<img
									src={message}
									className="emailIcon"
									alt="email-icon"
									type="submit"
									onClick={(e) => handleSubmit(e)}
								/>
							</form>
						</div>
					</div>

					<div className="even-columns">
						<List title="About Flutter" list={aboutFlutter} />
						<List title="Learn Flutter" list={learnFlutter} />
						<List title="Multi-Platform" list={multiPlatform} />

						<nav className="footer-nav">
							<a href="/" className="deskguy">
								<img src={deskguy} alt="Logo" />
							</a>
							<p
				style={{
					fontWeight: "bold",
				}}>
				Social Links
			</p>
							
									<div className="social-link-grid">
									<a aria-label="facebook" href="/">
										<FiFacebook size={20} />
										
									</a>
								
								
									<a aria-label="instagram" href="/">
										<FiInstagram size={20} />
									</a>
								
									<a aria-label="twitter" href="/">
										<FiTwitter size={20} />
									</a>
								
									<a aria-label="github" href="/">
										<FiGithub size={20} />
									</a>
									</div>
									
								
						</nav>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
