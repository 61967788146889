import React, { useState } from "react"
import "./SignUp.css"
import "../forms.css"
import gmail from "../../../../Assets/LoginAssets/Gmail.svg"
import facebook from "../../../../Assets/LoginAssets/Facebook.svg"
import or from "../../../../Assets/LoginAssets/or.svg"
import Input from "../InputField/Input"
import logo from "../../../../Assets/HomePageAssets/LandingPageAssets/Logo.svg"

import { useNavigate, useParams } from "react-router-dom"
import "../../Components/forms.css"

import {
	createUserWithEmailAndPassword,
	sendEmailVerification,
} from "firebase/auth"

import { auth, db } from "../../../../services/firebase"
import { addDoc, collection } from "firebase/firestore"
import { useSnackbar } from "notistack"
import { firebaseErrorsMsg } from "../../../../constants/constants"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"
import {
	signInWitFacebook,
	signInWithGoogle,
} from "../../../../services/AuthService"

const SingUp = () => {
	let navigate = useNavigate()
	const [email, setEmail] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [phone, setPhone] = useState("")
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const { setTimeActive } = useAuthValue()
	const { enqueueSnackbar } = useSnackbar()

	const params = useParams()
	const { role } = params

	const validatePassword = () => {
		let isValid = true
		if (password !== "" && confirmPassword !== "") {
			if (password !== confirmPassword) {
				isValid = false
				enqueueSnackbar("Passwords does not match", {
					variant: "error",
				})
			}
		}
		return isValid
	}

	const register = (e) => {
		e.preventDefault()
		if (validatePassword()) {
			// Create a new user with email and password using firebase
			createUserWithEmailAndPassword(auth, email, password)
				.then((res) => {
					const user = res.user
					addDoc(collection(db, "users"), {
						uid: user.uid,
						firstName,
						lastName,
						phoneNumber: phone,
						email,
						roles: role ? ["USER", role] : ["USER"],
					})
				})
				.then(() => {
					sendEmailVerification(auth.currentUser)
						.then(() => {
							setTimeActive(true)
							navigate("/verify-email")
						})
						.catch((err) => {
							enqueueSnackbar(firebaseErrorsMsg(err), {
								variant: "error",
							})
						})
				})
				.catch((err) => {
					enqueueSnackbar(firebaseErrorsMsg(err), {
						variant: "error",
					})
				})
		}
	}

	return (
		<div className="Login">
			<p className="home_btn" onClick={() => navigate("/")}>
				Home
			</p>
			<div className="signUp_fields">
				<form
					className="signUp_form"
					onSubmit={register}
					name="registration_form"
				>
					<div className="login_image">
						<img src={logo} alt="logo" />
					</div>
					<p className="signup_heading">Create an account</p>
					<p className="with_email">Sign up with email</p>
					<div className="fields">
						<Input
							label=""
							name="email"
							type="email"
							value={email}
							placeholder="Please enter your email"
							error=""
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
						<div className="split_input_signup">
							<Input
								label=""
								name="firstName"
								type="text"
								value={firstName}
								placeholder="First name"
								error=""
								onChange={(e) => setFirstName(e.target.value)}
							/>
							<Input
								label=""
								name="lastName"
								type="text"
								value={lastName}
								placeholder="Last name"
								error=""
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
						<Input
							label=""
							name="phone"
							type="number"
							value={phone}
							placeholder="Enter your phone number"
							onChange={(e) => setPhone(e.target.value)}
							required
						/>
						<Input
							label=""
							name="password"
							type="password"
							value={password}
							placeholder="Enter password"
							onChange={(e) => setPassword(e.target.value)}
							required
						/>
						<Input
							label=""
							name="confirmPassword"
							type="password"
							value={confirmPassword}
							placeholder="Confirm password"
							onChange={(e) => setConfirmPassword(e.target.value)}
							required
						/>
						<button type="submit">Sign up</button>
						<p className="not_registered">
							Already have an account?{" "}
							<span
								onClick={() =>
									navigate(role ? "/admin/login" : "/login")
								}
							>
								Sign in
							</span>
						</p>
						<img src={or} alt="or" />
					</div>

					<div className="signup_using">
						<img
							src={gmail}
							alt="gmail"
							onClick={signInWithGoogle}
						/>
						<img
							src={facebook}
							alt="facebook"
							onClick={signInWitFacebook}
						/>
					</div>
				</form>
			</div>
		</div>
	)
}

export default SingUp
