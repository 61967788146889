import "./CaseStudy.css"
import RectangleSvg from "../../../../Assets/HomePageAssets/CaseStudyAssets/casestudy.webp"
import DiamondhandSvg from "../../../../Assets/HomePageAssets/CaseStudyAssets/diamondhand.webp"
import DiamondSvg from "../../../../Assets/HomePageAssets/CaseStudyAssets/Layer 13.svg"
import Headline from "../Headline/Headline"

function CaseStudy() {
	return (
		<section className="case_study_container" id="casestudy">
			<Headline
				bgText="study"
				mainText="Case Study"
				subText="Around Our Agency"
			/>

			<div className="caseStudy">
				<img src={DiamondSvg} className="diamond" alt="diamond" />

				<div className="case-study-img">
					<img src={RectangleSvg} alt="case-study" />
				</div>

				<div className="case-study-content">
					<div>Icons Study</div>
					<p>
						Deploy to multiple devices from a single codebase:
						mobile, web, desktop, and embedded devices. Deploy to
						multiple devices from a single codebase: mobile, web,
						desktop, and embedded devices.Deploy to multiple devices
						from a single codebase: mobile, web, desktop, and
						embedded devices. Deploy to multiple devices from a
						single codebase: mobile, web, desktop, and embedded
						devices. Deploy to multiple.
					</p>
					<button className="case-study-btn">Read More</button>
				</div>

				<div>
					<img
						src={DiamondhandSvg}
						className="diamondHand"
						alt="hand"
					/>
				</div>
			</div>
		</section>
	)
}

export default CaseStudy
