import CaseStudy from "./CaseStudy/CaseStudy";
import Landing from "./Landing_page/Landing";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Team from "./Team/Team";
import TechStacks from "./TechStacks/techStacks";

const HomePage = () => {
	return (
		<div className="main-container">
			<Landing />
			<Services />
			<TechStacks />
			<CaseStudy />
			<Team />
			<Contact />
		</div>
	);
};

export default HomePage;
