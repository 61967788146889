import { createContext, useEffect, useState } from "react"
import { getProducts } from "../../services/OtherServices"
export const ProductsContext = createContext()

const ProductsContextProvider = ({ children }) => {
	const [products, setProducts] = useState([])

	useEffect(() => {
		let tempArray = getProducts()
		tempArray.then((res) => setProducts(res))
	}, [])

	return (
		<ProductsContext.Provider value={{ products }}>
			{children}
		</ProductsContext.Provider>
	)
}

export default ProductsContextProvider
