import MarkdownPreview from "@uiw/react-markdown-preview"
import "./MarkDown.css"

const MarkDown = ({ product }) => {
	return (
		<div data-color-mode="light" className="markdown-container">
			<MarkdownPreview
				style={{ fontSize: "0.8rem" }}
				source={product?.mdFile}
			/>
		</div>
	)
}

export default MarkDown
