import "./ProductHighlight.css"
import { IoMdCheckmark } from "react-icons/io"

const ProductHighlight = ({ data }) => {
	return (
		data?.highlights?.length > 0 && (
			<div className="productHighlight">
				<p className="name">Highlights</p>
				<div className="highlights">
					{data?.highlights?.map((highlight) => (
						<li key={highlight}>
							<IoMdCheckmark color="#5F88F3" size={19} />
							{highlight}
						</li>
					))}
				</div>
			</div>
		)
	)
}

export default ProductHighlight
