import {
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore"
import { db } from "../../services/firebase"

export const getOrderSummary = async (userId) => {
	const paymentsRef = collection(db, "payments")
	const q = query(paymentsRef, where("userId", "==", userId))
	const querySnapshot = await getDocs(q)

	let tempArray = []
	querySnapshot.forEach((doc) => {
		let obj = doc.data()
		obj["orderId"] = doc.id
		tempArray.push(obj)
	})

	return tempArray
}

const updateProductRating = async (product, id) => {
	try {
		await updateDoc(doc(db, "products", id), product)
	} catch (err) {
		alert(err)
	}
}

export { updateProductRating }
