import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { CSSTransition } from "react-transition-group"
import "./CustomModal.css"
import { useNavigate } from "react-router-dom"

const CustomModal = (props) => {
	let navigate = useNavigate()
	const closeOnEscapeKeyDown = (e) => {
		if ((e.charCode || e.keyCode) === 27) {
			props.onClose()
		}
	}

	useEffect(() => {
		document.body.addEventListener("keydown", closeOnEscapeKeyDown, {
			passive: true,
		})
		return function cleanup() {
			document.body.removeEventListener("keydown", closeOnEscapeKeyDown)
		}
	}, [])

	return ReactDOM.createPortal(
		<CSSTransition
			in={props.show}
			unmountOnExit
			timeout={{ enter: 0, exit: 300 }}
		>
			<div role="dialog" className="custom_modal" onClick={props.onClose}>
				<div className="centered">
					<div
						className="custom_modal-content"
						onClick={(e) => e.stopPropagation()}
					>
						<div className="modal-header">
							<p className="modal-title">{props.title}</p>
						</div>
						<div className="custom_modal-body">
							{props.children}
						</div>
						{props.footer ? (
							<div className="custom_modal-footer">
								{!props.secondaryActionName ? (
									<div className="active_one">
										<button
											onClick={props.primaryAction}
											className="custom_modal-button-1"
										>
											{props.primaryActionName}
										</button>
									</div>
								) : (
									<div>
										<button
											onClick={props.primaryAction}
											className="custom_modal-button-1"
										>
											{props.primaryActionName}
										</button>
										<button
											onClick={props.secondaryAction}
											className="custom_modal-button-2"
										>
											{props.secondaryActionName}
										</button>
									</div>
								)}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</CSSTransition>,
		document.getElementById("root")
	)
}

export default CustomModal
