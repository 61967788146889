import "./CartPage.css";
import React, { useEffect, useState } from "react";
import Headline from "../HomePage/Components/Headline/Headline";
import Paysection from "./Components/PaySection/Paysection";
import CartList from "./Components/CartList/CartList";
import MoreProducts from "./Components/MoreProducts/MoreProducts";
import { useCart } from "../../hooks/useCart";
import EmptyCart from "./Components/EmptyCart";
import Loader from "../../UI/Loader/Loader";

function CartPage() {
	const { cartItems } = useCart();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (cartItems.length || !cartItems.length) {
			setLoading(false);
		}
		window.scrollTo(0, 0);
	}, [cartItems]);

	return (
		<>
			{cartItems.length ? (
				<Headline bgText="Cart" mainText="Shopping Cart" subText="" />
			) : null}

			{!loading ? (
				cartItems.length > 0 ? (
					<div className="shopping-cart">
						<CartList />
						<Paysection />
					</div>
				) : (
					<EmptyCart />
				)
			) : (
				<Loader />
			)}

			<Headline
				bgText="product"
				mainText="More product"
				subText="Around Our Agency"
			/>
			<MoreProducts />
		</>
	);
}

export default CartPage;
