import "./SearchBar.css";
import { FiSearch } from "react-icons/fi";
import Headline from "../../../HomePage/Components/Headline/Headline";

const SearchBar = ({ setSearch }) => {
	return (
		<div>
			<div className="search-bar-container">
				<div className="search">
					<input
						placeholder="Search"
						type="search"
						onChange={(e) => {
							setSearch(e.target.value.toLowerCase());
						}}
					/>
					<div className="search-icon">
						<FiSearch color="black" size={30} />
					</div>
				</div>
			</div>
			<div>
				<Headline
					bgText="Flutter"
					mainText="Trending Collections of Flutter"
					subText="AroundExplore all collections in Art category. Our Agency"
				/>
			</div>
		</div>
	);
};

export default SearchBar;
