import React from "react";
import "./error404.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import UnauthorizedPng from "../../Assets/ErrorPageAssets/Unauthorized.png";

const Unauthorized = () => {
	let navigate = useNavigate();
	return (
		<div className="error404-container">
			<h1 className="error404-head">401</h1>
			<p className="error404-title">OOOPS ! UNAUTHORIZED</p>
			<div className="error404-description">
				<p>You are unauthorized to the page that your are looking for.</p>
			</div>
			<div className="error404-button">
				<button
					onClick={() => {
						navigate("/");
					}}>
					<div>
						<AiOutlineArrowLeft size={20} color="white" />
					</div>
					Back to home
				</button>
			</div>
			<div className="side-rectangle-right"></div>
			<div className="side-rectangle-left"></div>
		</div>
	);
};

export default Unauthorized;
