import "./AddEditProject.css"
import { useForm, Controller } from "react-hook-form"
import { useSnackbar } from "notistack"
import { addDoc, collection, doc, updateDoc } from "firebase/firestore"
import { db } from "../../../../services/firebase"
import Select from "react-select"
import DollarIcon from "../../../../Assets/dollarIcon.svg"
import CreatableSelect from "react-select/creatable"
import { formOptions, highlightOptions, options } from "./options"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { fields } from "./constants"
import ImageUploads from "./ImageUploads"
import { isEmpty } from "@firebase/util"
import FileField from "./FileField"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"

const AddEditProject = () => {
	const { enqueueSnackbar } = useSnackbar()
	let navigate = useNavigate()

	const { state } = useLocation()

	const params = useParams()
	const { id } = params
	const isAddMode = !id

	const [urls, setUrls] = useState([])
	const [mdFileContent, setMdFileContent] = useState("")
	const [projectZip, setProjectZip] = useState("")

	const [loading, setLoading] = useState(false)
	const { currentUser } = useAuthValue()

	const [preview2, setPreview2] = useState([])

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setValue,
	} = useForm({ mode: "onChange" })

	useEffect(() => {
		if (!isAddMode) {
			if (state) {
				const { project } = state
				let highlights,
					compatibility = []

				if (!isEmpty(project["compatibility"])) {
					compatibility = options.filter(
						(f, i) => f.value === project["compatibility"][i]
					)
				}
				if (!isEmpty(project["highlights"])) {
					highlights = highlightOptions.filter(
						(f, i) => f.value === project["highlights"][i]
					)
				}

				fields.forEach((field) => {
					setValue(field, project[field])
				})
				setValue("compatibility", compatibility)
				setValue("highlights", highlights)
				setMdFileContent(project["mdFile"])
				setProjectZip(project["projectZip"])
			}
		}
	}, [isAddMode, params.id, setValue, state])

	const onFormSubmit = async (product) => {
		setLoading(true)
		product["highlights"] = product["highlights"]?.length
			? product["highlights"]?.map((item) => item.value)
			: []

		product["compatibility"] = product["compatibility"]?.length
			? product["compatibility"]?.map((item) => item.value)
			: []

		product["mdFile"] = mdFileContent ? mdFileContent : ""
		product["projectZip"] = projectZip ? projectZip : ""

		if (urls?.length === 0) {
			const { image, gridImages, thumbnail } = state?.project
			product["image"] = image
			product["thumbnail"] = thumbnail
			product["gridImages"] = gridImages
		} else {
			product["image"] = urls[0]
			product["thumbnail"] = urls[1]
			product["gridImages"] = urls?.slice(2)
		}

		if (!isAddMode) {
			try {
				await updateDoc(doc(db, "products", id), product).then(() => {
					setLoading(false)
					enqueueSnackbar("product updated successfully", {
						variant: "success",
					})
					navigate("/admin/sub/projects")
				})
			} catch (err) {
				setLoading(false)
				enqueueSnackbar(err, {
					variant: "error",
				})
			}
		} else {
			product["status"] = "PENDING"
			product["userId"] = currentUser?.uid
			try {
				await addDoc(collection(db, "products"), product).then(() => {
					setLoading(false)
					enqueueSnackbar("product added successfully", {
						variant: "success",
					})
					navigate("/admin/sub/projects")
				})
			} catch (err) {
				setLoading(false)
				enqueueSnackbar(err, {
					variant: "error",
				})
			}
		}
	}

	const styles = {
		multiValue: (styles) => {
			return {
				...styles,
				backgroundColor: "papayawhip",
			}
		},
	}

	return (
		<div className="container">
			<form onSubmit={handleSubmit(onFormSubmit)}>
				<div className="row">
					<div className="col-25">
						<label htmlFor="title">Title</label>
						<input
							type="text"
							id="title"
							name="title"
							placeholder="Enter Product title"
							{...register("title", formOptions.title)}
						/>
						<small className="text-danger">
							{errors?.title && errors.title.message}
						</small>
					</div>
					<div className="col-25">
						<label htmlFor="subtitle">Subtitle</label>
						<input
							type="text"
							id="subtitle"
							name="subtitle"
							placeholder="Enter Subtitle"
							{...register("subtitle", formOptions.subtitle)}
						/>
						<small className="text-danger">
							{errors?.subtitle && errors.subtitle.message}
						</small>
					</div>
				</div>
				<div className="row">
					<div className="col-25">
						<label htmlFor="fname">Compatibility</label>
						<Controller
							name="compatibility"
							control={control}
							render={({ field }) => {
								return (
									<Select
										name="compatibility"
										styles={styles}
										closeMenuOnSelect={false}
										options={options}
										isMulti
										{...field}
									/>
								)
							}}
						/>
						<small className="text-danger">
							{errors?.compatibility &&
								errors.compatibility.message}
						</small>
					</div>
					<div className="col-25">
						<label htmlFor="fname">Highlights</label>
						<Controller
							name="highlights"
							control={control}
							render={({ field }) => {
								return (
									<CreatableSelect
										name="highlights"
										styles={styles}
										options={highlightOptions}
										isMulti
										{...field}
									/>
								)
							}}
						/>
						<small className="text-danger">
							{errors?.highlights && errors.highlights.message}
						</small>
					</div>
				</div>
				<div className="row">
					<div className="col-25">
						<label htmlFor="fname">Featured</label>
						<input
							type="number"
							placeholder="Enter Number"
							id="featured"
							name="featured"
							{...register("featured", formOptions.featured)}
						/>

						<small className="text-danger">
							{errors?.featured && errors.featured.message}
						</small>
					</div>
					<div className="col-25">
						<label htmlFor="license">License</label>
						<input
							type="text"
							id="license"
							name="license"
							placeholder="Enter License"
							{...register("license", formOptions.license)}
						/>
						<small className="text-danger">
							{errors?.featured && errors.featured.message}
						</small>
					</div>
				</div>
				<div className="row">
					<div className="col-25">
						<label htmlFor="price">Price</label>

						<div className="wrapper">
							<div>
								<img
									className="project-icon"
									src={DollarIcon}
									alt={"DollarIcon"}
									width={50}
									height={50}
									style={{
										zIndex: 1,
									}}
								/>
							</div>
							<input
								type="number"
								style={{
									transform: "translateX(6.5%)",
									width: "94%",
								}}
								placeholder="Enter Amount"
								id="price"
								name="price"
								{...register("price", formOptions.price)}
							/>
						</div>
						<small className="text-danger">
							{errors?.price && errors.price.message}
						</small>
					</div>
					{/* <div className="col-25">
						<label htmlFor="discount">Discount</label>
						<div className="wrapper">
							<div>
								<img
									className="icon"
									src={DollarIcon}
									alt={"DollarIcon"}
									width={50}
									height={50}
									style={{
										zIndex: 1,
									}}
								/>
							</div>
							<input
								type="number"
								style={{
									transform: "translateX(6.5%)",
									width: "94%",
								}}
								id="discount"
								name="discount"
								placeholder="Discount Price"
								{...register("discount", formOptions.discount)}
							/>
						</div>
					</div> */}
				</div>
				<div className="row">
					<label htmlFor="description">Description</label>
					<textarea
						id="description"
						name="description"
						placeholder="Product Description.."
						{...register("description", formOptions.description)}
						style={{ height: "120px" }}
					/>
					<small className="text-danger">
						{errors?.description && errors.description.message}
					</small>
				</div>

				<div className="row">
					<div className="col-25">
						<FileField
							accept={".md"}
							label="Upload Markdown"
							multiple={false}
							setMdFileContent={setMdFileContent}
							uploadButton={false}
						/>
					</div>
					<div className="col-25">
						<FileField
							label="Upload Project File"
							accept={".zip"}
							multiple={false}
							setProjectZip={setProjectZip}
							uploadButton={true}
						/>
					</div>
				</div>

				<div className="row">
					<div>
						<ImageUploads
							urls={urls}
							setUrls={setUrls}
							project={state?.project}
							preview2={preview2}
							setPreview2={setPreview2}
						/>
					</div>
				</div>

				<div className="row">
					{isAddMode && urls?.length >= 3 && (
						<button
							type="submit"
							className={`submit-button ${
								loading ? `button--loading` : ``
							}`}
						>
							<span className="button__text">Add</span>
						</button>
					)}

					{!isAddMode && (
						<button
							type="submit"
							className={`submit-button ${
								loading ? `button--loading` : ``
							}`}
						>
							<span className="button__text">Save</span>
						</button>
					)}
				</div>
			</form>
		</div>
	)
}

export default AddEditProject
