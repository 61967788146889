import { useProducts } from "../../../../hooks/useProducts";
import Loader from "../../../../UI/Loader/Loader";
import ProductCard from "../../../CollectionsPage/Components/ProductCard/ProductCard";

const MoreProducts = () => {
	const { products } = useProducts();
	let moreProducts = products
		.filter((item) => {
			if (item.status === "APPROVED") {
				return item;
			}
		})
		.slice(0, 3);

	return (
		<div
			style={{ paddingBottom: "8rem" }}
			className={`product-collection-grid`}>
			{moreProducts.length ? (
				moreProducts.map((data, index) => (
					<ProductCard key={index} product={data} />
				))
			) : (
				<Loader />
			)}
		</div>
	);
};

export default MoreProducts;
