import {
	GoogleAuthProvider,
	signInWithPopup,
	FacebookAuthProvider,
	signOut,
} from "firebase/auth"
import { collection, where, addDoc, getDocs, query } from "firebase/firestore"
import { auth, db } from "./firebase"

const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()

googleProvider.setCustomParameters({ prompt: "select_account" })
facebookProvider.setCustomParameters({ prompt: "select_account" })

export const signInWithGoogle = async () => {
	try {
		const res = await signInWithPopup(auth, googleProvider)
		const user = res.user
		const q = query(collection(db, "users"), where("uid", "==", user.uid))
		const docs = await getDocs(q)
		if (docs.docs.length === 0) {
			await addDoc(collection(db, "users"), {
				uid: user.uid,
				name: user.displayName,
				authProvider: "google",
				email: user.email,
			})
		}
	} catch (err) {
		console.error(err)
		alert(err.message)
	}
}

export const signInWitFacebook = async () => {
	try {
		const res = await signInWithPopup(auth, facebookProvider)
		const user = res.user
		const q = query(collection(db, "users"), where("uid", "==", user.uid))
		const docs = await getDocs(q)
		if (docs.docs.length === 0) {
			await addDoc(collection(db, "users"), {
				uid: user.uid,
				name: user.displayName,
				authProvider: "facebook",
				email: user.email,
			})
		}
	} catch (err) {
		console.error(err)
		alert(err.message)
	}
}

export const logout = async () => {
	try {
		await signOut(auth)
		return true
	} catch (error) {
		return false
	}
}
