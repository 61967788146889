import React from "react";
import "./error404.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import RectLeft from '../../Assets/ErrorPageAssets/RectangleLeft.svg';
import RectRight from '../../Assets/ErrorPageAssets/RectangleRight.svg';

const Error404 = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let navigate = useNavigate();
	return (
		<div className="error404-container">
			<h1 className="error404-head">404</h1>
			<p className="error404-title">OOOPS ! PAGE NOT FOUND</p>
			<div className="error404-description">
				<p>
					This Page doesn’t exist or was removed! We suggest you back to home
				</p>
			</div>
			<div className="error404-button">
				<button
					onClick={() => {
						navigate("/");
					}}>
					<div>
						<AiOutlineArrowLeft size={20} color="white" />
					</div>
					Back to home
				</button>
			</div>

			{/* <div className="error404-content">
				
				
				
				
			</div>
			<div className="error404-image">
				<img alt="404Error" src={Error404png} />
			</div> */}
			<div className="rect-images">
			
			<img src={RectLeft}/>

			<img src={RectRight}/>
			
			</div>
			
		</div>
	);
};

export default Error404;
