import "./Profile.css"
import { useAuthValue } from "../../contexts/AuthContext/AuthContext"
import DefaultMemberImage from "../../Assets/HomePageAssets/ServiceAssets/ServicePNG.webp"

const Profile = () => {
	const { currentUser } = useAuthValue()
	return (
		<div
			className="profile-center"
			style={{
				paddingBottom: "16rem",
				paddingTop: "5rem",
			}}
		>
			<div className="profile">
				{/* <h1>Profile</h1> */}
				<div className="member_image">
					<img
						src={
							currentUser?.photoURL
								? currentUser?.photoURL
								: DefaultMemberImage
						}
						alt="member_image"
					/>
				</div>

				<div>
					<p>
						<strong>Name: </strong>
						{currentUser?.displayName}
					</p>
					<p>
						<strong>Phone Number: </strong>
						{currentUser?.phoneNumber}
					</p>
					<p>
						<strong>Email: </strong>
						{currentUser?.email}
					</p>
					<p>
						<strong>Role: </strong>
						{currentUser.roles[currentUser.roles?.length - 1]}
					</p>
					<p>
						<strong>Email verified: </strong>
						{`${currentUser?.emailVerified}`}
					</p>
				</div>
			</div>
		</div>
	)
}

export default Profile
