import EmptyCartIcon from "../../../Assets/CartAssets/empty-cart.png"
import "./EmptyCart.css"

const EmptyCart = () => {
	return (
		<div className="empty-cart">
			<img width={50} height={50} src={EmptyCartIcon} alt="empty-cart" />
			Empty Cart
			<p style={{ fontSize: "large" }}> Add Some Products</p>
		</div>
	)
}

export default EmptyCart
