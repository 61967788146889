import "./Orders.css"
import { useEffect, useState } from "react"
import { Rating } from "../../UI/Rating/Rating.tsx"
import { formatNumber } from "../../helpers/utils"
import { useAuthValue } from "../../contexts/AuthContext/AuthContext"
import Headline from "../HomePage/Components/Headline/Headline"
import Loader from "../../UI/Loader/Loader"
import { useSnackbar } from "notistack"
import { getOrderSummary, updateProductRating } from "./OrderServices"
import { useProducts } from "../../hooks/useProducts"
import { useCallback } from "react"
import Paginations from "../../UI/Pagination/Pagination"

const Orders = () => {
	const { currentUser } = useAuthValue()
	const { products } = useProducts()
	const { enqueueSnackbar } = useSnackbar()
	const [orders, setOrders] = useState([])

	const [currentPage, setCurrentPage] = useState(1)
	let NUM_OF_RECORDS = orders.length
	let LIMIT = 3

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = orders.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	const updateProduct = (product, ratingValue) => {
		if (product?.productRatings) {
			let alreadyRated = !!product.productRatings?.find(
				(obj) => obj.userId === currentUser?.uid
			)

			if (alreadyRated) {
				product["productRatings"] = product.productRatings?.map(
					(obj) => {
						if (obj.userId === currentUser?.uid) {
							return { ...obj, rating: ratingValue }
						}
						return obj
					}
				)
			} else {
				product["productRatings"].push({
					userId: currentUser?.uid,
					rating: ratingValue,
				})
			}
		} else {
			product["productRatings"] = product.productRatings
				? product.productRatings
				: []

			product["productRatings"].push({
				userId: currentUser?.uid,
				rating: ratingValue,
			})
		}

		updateProductRating(product, product.productId)
			.then(() => {
				enqueueSnackbar("Thanks for rating the item", {
					variant: "success",
				})
				getOrders()
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				})
			})
	}

	const handleRating = (ratingValue, product) => {
		updateProduct(product, ratingValue)
	}

	const getOrders = () => {
		getOrderSummary(currentUser?.uid).then((res) => {
			let mergedOrders = [].concat.apply(
				[],
				res.map((item) => item.products)
			)

			let uniqueObjArray = [
				...new Map(
					mergedOrders.map((item) => [item["productId"], item])
				).values(),
			]
			if (products.length > 0) {
				let ProductIds = products.map((item) => item.productId)
				let OrderIds = uniqueObjArray.map((item) => item.productId)

				let filteredProducts = OrderIds.filter((value) =>
					ProductIds.includes(value)
				)

				let orderedProducts = []
				for (let index = 0; index < products.length; index++) {
					for (let idx = 0; idx < filteredProducts.length; idx++) {
						if (
							products[index].productId === filteredProducts[idx]
						) {
							orderedProducts.push(products[index])
						}
					}
				}

				setOrders(orderedProducts)
			}
		})
	}

	useEffect(() => {
		getOrders()
	}, [])

	return (
		<>
			<Headline bgText="Orders" mainText="Orders" />
			<div className="order-list">
				{currentData?.length > 0 ? (
					currentData?.map((product) => (
						<div className="order-item" key={product?.productId}>
							<img
								className="order-item-image"
								alt="ProductImage"
								src={
									product?.thumbnail ||
									"https://picsum.photos/500/300"
								}
							/>
							<div className="item-details">
								<p className="order-item-text">
									{product?.title}
								</p>
								<p className="order-item-secondary-text">
									{product?.subtitle}
								</p>
								<p className="order-item-secondary-text">
									{product?.description}
								</p>
								<p className="license">
									License:
									<span
										style={{
											color: "#5F88F3",
											marginLeft: 5,
										}}
									>
										{product?.license}
									</span>
								</p>
								<p className="order-rating">
									<Rating
										readonly={
											product?.productRatings.find(
												(item) =>
													item.userId ===
													currentUser?.uid
											)?.rating
												? true
												: false
										}
										onClick={(ratingValue) =>
											handleRating(ratingValue, product)
										}
										ratingValue={
											product?.productRatings?.find(
												(item) =>
													item.userId ===
													currentUser?.uid
											)?.rating !== undefined
												? product?.productRatings?.find(
														(item) =>
															item.userId ===
															currentUser?.uid
												  )?.rating
												: 0
										}
										size={25}
										transition
									/>
								</p>
							</div>

							<div className="item-price">
								{formatNumber(product?.price)}
							</div>
						</div>
					))
				) : orders?.length === 0 ? (
					<div style={{ textAlign: "center", fontSize: "1rem" }}>
						No Orders Found
					</div>
				) : (
					<Loader />
				)}
			</div>
			<div className="pagination-wrapper">
				<Paginations
					totalRecords={NUM_OF_RECORDS}
					pageLimit={LIMIT}
					pageNeighbours={2}
					onPageChanged={onPageChanged}
					currentPage={currentPage}
				/>
			</div>
		</>
	)
}

export default Orders
