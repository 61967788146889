import { useEffect, useReducer } from "react"

import { getCartItems, getFavItems } from "../../services/CartService"
import { useAuthValue } from "../AuthContext/AuthContext"
import CartContext from "./CartContext"
import { CartReducer, sumFavItems, sumItems } from "./CartReducer"
import { INITIALIZE_CART } from "./CartTypes"

const CartState = ({ children }) => {
	const { currentUser } = useAuthValue()

	const initialState = {
		cartItems: [],
		favItems: [],

		...sumItems([]),
		...sumFavItems([]),

		checkout: false,
		logout: false,
	}

	const [state, dispatch] = useReducer(CartReducer, initialState)

	useEffect(() => {
		if (currentUser?.uid) {
			getCartItems(currentUser?.uid).then((cartResponse) => {
				getFavItems(currentUser?.uid).then((favResponse) => {
					dispatch({
						type: INITIALIZE_CART,
						payload: {
							...initialState,

							...sumItems(cartResponse),
							cartItems: cartResponse,

							...sumFavItems(favResponse),
							favItems: favResponse,
						},
					})
				})
			})
		}
	}, [currentUser?.uid])

	const addToCart = (payload) => {
		dispatch({ type: "ADD_ITEM", payload })
	}

	const removeFromCart = (payload) => {
		dispatch({ type: "REMOVE_ITEM", payload })
	}

	const addFavItem = (payload) => {
		dispatch({ type: "ADD_FAVOURITE_ITEM", payload })
	}

	const removeFavItem = (payload) => {
		dispatch({ type: "REMOVE_FAVOURITE_ITEM", payload })
	}
	const clearCart = () => {
		dispatch({ type: "CLEAR" })
	}

	const handleCheckout = () => {
		dispatch({ type: "CHECKOUT" })
	}

	const handleLogout = () => {
		dispatch({ type: "LOGOUT" })
	}

	const cartContextValues = {
		cartItems: state.cartItems,
		favItems: state.favItems,
		removeFromCart,
		addToCart,
		clearCart,
		handleCheckout,
		addFavItem,
		removeFavItem,
		handleLogout,
		...state,
	}

	return (
		<CartContext.Provider value={cartContextValues}>
			{children}
		</CartContext.Provider>
	)
}

export default CartState
