import { onAuthStateChanged } from "firebase/auth"
import { createContext, useContext, useEffect, useState } from "react"
import { auth } from "../../services/firebase"
import { getUser } from "../../services/OtherServices"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null)
	const [timeActive, setTimeActive] = useState(false)

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			let tempUser = user
			getUser(user?.uid)
				.then((data) => {
					tempUser["displayName"] =
						data[0].firstName + " " + data[0].lastName
					tempUser["roles"] = data[0].roles
					tempUser["phoneNumber"] = data[0].phoneNumber
					setCurrentUser(tempUser)
				})
				.catch((err) => err)
		})
	}, [currentUser])

	const contextValue = {
		currentUser,
		timeActive,
		setTimeActive,
		setCurrentUser,
	}

	return (
		<AuthContext.Provider value={contextValue}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuthValue = () => {
	return useContext(AuthContext)
}
