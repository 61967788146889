import { doc, updateDoc } from "firebase/firestore"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { FiPlusCircle } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import { userTableHeader } from "../../../constants/constants"
import { useAuthValue } from "../../../contexts/AuthContext/AuthContext"
import { db } from "../../../services/firebase"
import { getUsers } from "../../AdminServices/AdminServices"
import Loader from "../../../UI/Loader/Loader"
import Paginations from "../../../UI/Pagination/Pagination"
import { useCallback } from "react"

const UserList = () => {
	let navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [tableData, setTableData] = useState([])
	const { currentUser } = useAuthValue()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const [currentPage, setCurrentPage] = useState(1)
	let NUM_OF_RECORDS = tableData.length
	let LIMIT = 7

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = tableData.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	useEffect(() => {
		if (currentUser?.roles?.includes("ADMIN")) {
			getUsers()
				.then((res) => {
					setTableData(res)
				})
				.catch((err) => {
					enqueueSnackbar(err, {
						variant: "error",
					})
				})
		}
	}, [currentUser?.roles, enqueueSnackbar])

	const updateUserRole = async (user, role) => {
		let assignedRoles = user.roles

		if (assignedRoles.find((item) => item === role)) {
			assignedRoles.splice(assignedRoles.indexOf(role), 1)
			user["roles"] = assignedRoles
		} else {
			assignedRoles.push(role)
			user["roles"] = assignedRoles
		}

		try {
			await updateDoc(doc(db, "users", user.id), user).then((res) => {
				getUsers()
					.then((res) => {
						setTableData(res)
					})
					.catch((err) => {
						enqueueSnackbar(err, {
							variant: "error",
						})
					})
				enqueueSnackbar(`User role updated!`, {
					variant: "info",
				})
			})
		} catch (err) {
			enqueueSnackbar(err, {
				variant: "error",
			})
		}
	}

	return (
		<div className="teams_container">
			<div className="teams_actions">
				<h2>Users</h2>
				<button onClick={() => navigate("/admin/sub/createUser")}>
					<FiPlusCircle className="action_btn_icon" />
					Add User
				</button>
			</div>
			<div className="teams_table">
				<table>
					<thead style={{ backgroundColor: "#454FDEA1" }}>
						<tr>
							{userTableHeader.map((header) => (
								<th key={header}>{header}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentData.length ? (
							currentData.map((user) => (
								<tr key={user.productId}>
									<td>
										{user?.name
											? user.name
											: user.firstName +
											  " " +
											  user.lastName}
									</td>
									<td>{user?.email}</td>
									<td>{user?.phoneNumber}</td>

									<td>
										<button
											style={{
												backgroundColor:
													user?.roles.find(
														(item) => item === "DEV"
													)
														? "green"
														: "red",
											}}
											onClick={() =>
												updateUserRole(user, "DEV")
											}
										>
											AUTHOR
										</button>

										<button
											style={{
												backgroundColor:
													user?.roles.find(
														(item) =>
															item === "ADMIN"
													)
														? "green"
														: "red",
											}}
											onClick={() =>
												updateUserRole(user, "ADMIN")
											}
										>
											ADMIN
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={3}>
									{tableData.length !== 0 ? (
										<Loader />
									) : (
										"No Users Found"
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{NUM_OF_RECORDS > 5 ? (
				<div className="pagination-wrapper">
					<Paginations
						totalRecords={NUM_OF_RECORDS}
						pageLimit={LIMIT}
						pageNeighbours={2}
						onPageChanged={onPageChanged}
						currentPage={currentPage}
					/>
				</div>
			) : null}
		</div>
	)
}

export default UserList
