import "./AdminLogin.css"
import Input from "../../../Pages/SignupSignin/Components/InputField/Input"
import logo from "../../../Assets/HomePageAssets/LandingPageAssets/Logo.svg"
import { auth } from "../../../services/firebase"

import { useSnackbar } from "notistack"
import { useNavigate, useParams } from "react-router-dom"
import { firebaseErrorsMsg } from "../../../constants/constants"
import { useEffect, useState } from "react"
import {
	sendEmailVerification,
	signInWithEmailAndPassword,
} from "firebase/auth"
import { useAuthValue } from "../../../contexts/AuthContext/AuthContext"

export default function AdminLogin() {
	const { setTimeActive, currentUser } = useAuthValue()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [loading, setLoading] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	let navigate = useNavigate()

	useEffect(() => {
		if (currentUser) navigate("/admin/sub/dashboard")
	}, [currentUser, navigate])

	const onFormSubmit = (e) => {
		e.preventDefault()
		setLoading(true)
		signInWithEmailAndPassword(auth, email, password)
			.then((response) => {
				if (!auth.currentUser.emailVerified) {
					sendEmailVerification(auth.currentUser)
						.then(() => {
							setTimeActive(true)
							setLoading(false)
							navigate("/verify-email")
						})
						.catch((err) => {
							enqueueSnackbar(firebaseErrorsMsg(err), {
								variant: "error",
							})
							setLoading(false)
						})
				} else {
					setLoading(false)
					localStorage.setItem(
						"registered",
						response?._tokenResponse?.registered
					)
					enqueueSnackbar("Logged In", {
						variant: "success",
					})
					navigate("/admin/sub/dashboard")
				}
			})
			.catch((err) => {
				setLoading(false)
				enqueueSnackbar(firebaseErrorsMsg(err), {
					variant: "error",
				})
			})
	}

	return (
		<div>
			<div className="admin_login_container">
				<img className="admin_logo" src={logo} alt="logo" />

				<form className="admin_fields" onSubmit={onFormSubmit}>
					<div className="admin_heading">
						<p className="admin_login_heading">Admin Login</p>
					</div>

					<Input
						label="Email"
						name="email"
						type="email"
						value={email}
						required
						placeholder="Please enter your email"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Input
						label="Password"
						name="password"
						type="password"
						value={password}
						required
						placeholder="Enter password"
						onChange={(e) => setPassword(e.target.value)}
					/>

					<button
						type="submit"
						className={`admin_login_button ${
							loading ? `button--loading` : ``
						}`}
					>
						<span className="button__text">Login</span>
					</button>
				</form>
			</div>
		</div>
	)
}
