import React from "react";
import { useNavigate } from "react-router-dom";
import "./AuthorStrip.css";

const AuthorStrip = () => {
	let navigate = useNavigate();
	return (
		<div className="authorstrip-container">
			<div className="authorstrip-layout">
				<div className="authorstrip-content">
					<h1>Become a Seller</h1>
					<p>
						As said by CTO kaa of testaviva who’s been tweaking a legal platform
						with our experts for over 6 years. can we help?
					</p>
				</div>
				<div className="authorstrip-button">
					<button onClick={() => navigate("/admin")}>Get Start</button>
				</div>
			</div>
		</div>
	);
};

export default AuthorStrip;
