import {
	FiHome,
	FiUsers,
	FiFileText,
	FiSettings,
	FiShoppingBag,
} from "react-icons/fi"

export const sideMenu = [
	{
		label: "Dashboard",
		Icon: <FiHome size="1.5rem" />,
		to: "/admin/sub/dashboard",
	},
	{
		label: "Orders & Sales",
		Icon: <FiShoppingBag size="1.5rem" />,
		to: "/admin/sub/sales",
	},
	{
		label: "Teams",
		Icon: <FiUsers size="1.5rem" />,
		to: "/admin/sub/teams",
	},
	{
		label: "Projects",
		Icon: <FiFileText size="1.5rem" />,
		to: "/admin/sub/projects",
	},
	{
		label: "Messages",
		Icon: <FiFileText size="1.5rem" />,
		to: "/admin/sub/messages",
	},
	{
		label: "Subscriptions",
		Icon: <FiFileText size="1.5rem" />,
		to: "/admin/sub/Subscriptions",
	},
	{
		label: "Users",
		Icon: <FiSettings size="1.5rem" />,
		to: "/admin/sub/users",
	},
	{
		label: "Settings",
		Icon: <FiSettings size="1.5rem" />,
		to: "/settings",
	},
	{
		label: "Blogs",
		Icon: <FiSettings size="1.5rem" />,
		to: "/admin/sub/blogsList",
	},
]

export const sideMenuDev = [
	{
		label: "Dashboard",
		Icon: <FiHome size="1.5rem" />,
		to: "/admin/sub/dashboard",
	},
	{
		label: "Sales",
		Icon: <FiShoppingBag size="1.5rem" />,
		to: "/admin/sub/sales",
	},

	{
		label: "Projects",
		Icon: <FiFileText size="1.5rem" />,
		to: "/admin/sub/projects",
	},
	{
		label: "Settings",
		Icon: <FiSettings size="1.5rem" />,
		to: "/settings",
	},
]
