import React from "react"
import { useNavigate } from "react-router-dom"
import "./badge.css"
import cart from "../../../../Assets/HomePageAssets/LandingPageAssets/cart-image.svg"

export default function CartBadge({ count }) {
	let navigate = useNavigate()

	return (
		<div
			className="notification"
			onClick={() => navigate("/cart")}
			title="Cart"
		>
			<span className="cart">
				<img src={cart} alt="cart" />
			</span>
			{count ? <span className="badge">{count}</span> : null}
		</div>
	)
}
