import React, { useState } from "react"
import ImageField from "./ImageField"

function ImageUploads({ urls, setUrls, project, preview2, setPreview2 }) {
	const [preview, setPreview] = useState([])
	const [preview1, setPreview1] = useState([])

	return (
		<>
			{project ? (
				<div>
					<ImageField
						label="Product Image"
						multiple={false}
						setUrls={setUrls}
						width={500}
						height={300}
						setPreview={setPreview}
					/>
					<div>
						<img
							key={project?.image}
							style={{ width: "500px" }}
							src={project?.image}
							alt="firebase-img"
						/>
					</div>

					<ImageField
						label="Product Thumbnail"
						multiple={false}
						setUrls={setUrls}
						width={186}
						height={222}
						setPreview={setPreview1}
					/>

					<div>
						<img
							key={project?.thumbnail}
							style={{ width: "500px" }}
							src={project?.thumbnail}
							alt="firebase-img"
						/>
					</div>

					<ImageField
						label="Product Grid Images"
						multiple={true}
						setUrls={setUrls}
						width={1290}
						height={1390}
						setPreview={setPreview2}
					/>

					<div>
						{project?.gridImages?.map((url, i) => (
							<a
								href={url}
								target="_blank"
								rel="noreferrer"
								style={{ padding: "0.5rem" }}
							>
								<img
									key={i}
									style={{ width: "200px" }}
									src={url}
									alt="firebase-img"
								/>
							</a>
						))}
					</div>
				</div>
			) : (
				<div>
					<ImageField
						label="Product Image"
						multiple={false}
						setUrls={setUrls}
						width={500}
						height={300}
						setPreview={setPreview}
					/>
					{preview && (
						<div>
							<img
								key={preview}
								style={{ width: "500px" }}
								src={preview}
								alt="firebase-img"
							/>
						</div>
					)}
					{urls[0] && (
						<ImageField
							label="Product Thumbnail"
							multiple={false}
							setUrls={setUrls}
							width={186}
							height={222}
							setPreview={setPreview1}
						/>
					)}
					{preview1?.length > 0 && (
						<div>
							<img
								key={preview1}
								style={{ width: "500px" }}
								src={preview1}
								alt="firebase-img"
							/>
						</div>
					)}
					{urls[1] && (
						<ImageField
							label="Product Grid Images"
							multiple={true}
							setUrls={setUrls}
							width={1290}
							height={1390}
							setPreview={setPreview2}
						/>
					)}
					{preview2 &&
						preview2?.map((url, i) => (
							<div key={i}>
								<a href={url} target="_blank" rel="noreferrer">
									<img
										key={i}
										style={{ width: "500px" }}
										src={url}
										alt="firebase-img"
									/>
								</a>
							</div>
						))}
				</div>
			)}
		</>
	)
}

export default ImageUploads
