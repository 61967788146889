import "./Paysection.css"
import SecureCheckout from "../../../../Assets/CartAssets/secure-checkout-image.svg"
import { useCart } from "../../../../hooks/useCart"
import { formatNumber } from "../../../../helpers/utils"
import { useNavigate } from "react-router-dom"
import { deleteStoreCart } from "../../../../services/CartService"
import { db } from "../../../../services/firebase"

import { useSnackbar } from "notistack"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"

export default function Paysection() {
	const { total, clearCart } = useCart()
	const { currentUser } = useAuthValue()
	const { enqueueSnackbar } = useSnackbar()

	let navigate = useNavigate()

	const handlePayClick = (e) => {
		e.preventDefault()
		total > 0 && navigate("/payment")
	}

	const onClearClick = () => {
		clearCart()
		deleteStoreCart(db, "cartItems", currentUser?.uid)
		enqueueSnackbar("Items removed from cart", {
			variant: "info",
		})
	}

	return (
		<div className="pay-section">
			<h6 className="cart-title">Your Cart Total</h6>
			<h5 className="total-price">{formatNumber(total)}</h5>
			<button className="pay-button" onClick={(e) => handlePayClick(e)}>
				<span>{<p>Pay</p>}</span>
			</button>

			<button
				className="clear-button"
				disabled={total <= 0}
				onClick={() => onClearClick()}
			>
				Clear
			</button>
			<p className="pay-subtext">
				Price displayed excludes any applicable
			</p>
			<p className="pay-subtext-color">taxes and a handling fee.</p>

			<div className="pay-subtext">
				<div className="pay-subtext-color">
					<span>
						<img
							className="pay-subtextimage"
							alt="secure-chekout"
							src={SecureCheckout}
						/>
					</span>
					Secure checkout
				</div>
			</div>
		</div>
	)
}
