import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { storage } from "../../../../services/firebase"

function ImageField({ label, multiple, setUrls, width, height, setPreview }) {
	const [images, setImages] = useState([])
	const [progress, setProgress] = useState(0)
	const { enqueueSnackbar } = useSnackbar()
	const [selectedFiles, setSelectedFiles] = useState([])

	useEffect(() => {
		if (selectedFiles.length === 0) {
			setPreview(undefined)
			return
		}

		if (selectedFiles.length === 1) {
			setPreview([URL.createObjectURL(selectedFiles[0])])
		}

		if (selectedFiles.length > 1) {
			let tempArray = []
			for (let i = 0; i < selectedFiles.length; i++) {
				tempArray.push(URL.createObjectURL(selectedFiles[i]))
			}
			setPreview(tempArray)
		}

		return () => {
			if (selectedFiles.length === 1) {
				URL.revokeObjectURL(selectedFiles[0])
			}

			if (selectedFiles.length > 1) {
				for (let i = 0; i < selectedFiles.length; i++) {
					URL.revokeObjectURL(selectedFiles[i])
				}
			}
		}
	}, [selectedFiles, setPreview])

	const UploadControl = ({
		children,
		value,
		onChange,
		disabled,
		accept,
		multiple,
	}) => {
		return (
			<label htmlFor="contained-button-file">
				<input
					value={value}
					accept={accept}
					disabled={disabled}
					style={{ display: "none" }}
					id="contained-button-file"
					type="file"
					onChange={disabled ? () => {} : onChange}
					multiple={multiple}
				/>
				{children}
			</label>
		)
	}

	const handleChange = ({ target: { files } }) => {
		if (!files || files.length === 0) {
			setSelectedFiles(undefined)
			return
		}

		let tempArray = []
		for (let i = 0; i < files.length; i++) {
			const newImage = files[i]
			const maxAllowedSize = 1024 * 1024

			if (newImage.size > maxAllowedSize) {
				window.alert("Please upload a image smaller than 1 MB")
				return
			}

			newImage["id"] = Math.random()
			tempArray.push(newImage)
			setImages(tempArray)
		}
		setSelectedFiles(tempArray)
	}

	const handleUpload = () => {
		images.map((image) => {
			const storageRef = ref(storage, `files/${image.name}`)
			const uploadTask = uploadBytesResumable(storageRef, image)

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const prog = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					)
					setProgress(prog)
				},
				(err) => {
					enqueueSnackbar(err, {
						variant: "error",
					})
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						setUrls((prevState) => [...prevState, url])
					})
				}
			)
		})
	}

	useEffect(() => {
		if (progress === 100) {
			enqueueSnackbar("Uploaded Successfully", {
				variant: "success",
			})
		}
	}, [enqueueSnackbar, progress])

	return (
		<>
			<div className="row">
				<label htmlFor="Product Thumbnail">{label}</label>
			</div>
			<div className="row">
				<small className="row">
					* images of size of width: <b>{width} px</b> height:{" "}
					<b>{height} px</b> required
				</small>
				<label className="custom-file-input">
					<UploadControl
						onChange={handleChange}
						accept=".png, .jpg, .jpeg"
						multiple={multiple}
					/>
				</label>
				<button
					style={{ backgroundColor: "black" }}
					onClick={handleUpload}
					type="button"
				>
					Upload
				</button>
				<progress value={progress} max="100" />
				<br />
				<br />
				<br />
			</div>
		</>
	)
}

export default ImageField
