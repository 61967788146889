import "./Sales.css"
import { useEffect, useState } from "react"
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from "recharts"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"
import { ordersTableHeader } from "../../../../constants/constants"
import Loader from "../../../../UI/Loader/Loader"
import { formatNumber } from "../../../../helpers/utils"
import { getPayments } from "../../../AdminServices/AdminServices"
import { getUser } from "../../../../services/OtherServices"
import { useCallback } from "react"
import Paginations from "../../../../UI/Pagination/Pagination"

const SalesForecastData = [
	{
		label: "Revenue",
		value: "+45.5%",
		color: "",
	},
	{
		label: "Net Profit",
		value: "+18.6%",
		color: "",
	},
	{
		label: "Orders",
		value: "+43.6%",
		color: "",
	},
	{
		label: "Visitors",
		value: "-12.1%",
		color: "",
	},
]

const graphData = [
	{
		name: "Jan",
		pv: 2400,
	},
	{
		name: "Feb",
		pv: 1398,
		amt: 2210,
	},
	{
		name: "Mar",
		uv: 2000,
		pv: 9800,
		amt: 2290,
	},
	{
		name: "Apr",
		uv: 2780,
		pv: 3908,
		amt: 2000,
	},
	{
		name: "May",
		uv: 1890,
		pv: 4800,
		amt: 2181,
	},
	{
		name: "Jun",
		uv: 2390,
		pv: 3800,
		amt: 2500,
	},
	{
		name: "Jul",
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
]

const CustomTooltip = ({ active, payload, label }) => {
	if (active) {
		return (
			<div className="custom-tooltip">
				<p>{`${payload[0].value}`}</p>
			</div>
		)
	}

	return null
}

const Sales = () => {
	const { currentUser } = useAuthValue()
	const [orders, setOrders] = useState([])
	const [user, setUser] = useState()
	const [currentPage, setCurrentPage] = useState(1)

	let NUM_OF_RECORDS = orders.length
	let LIMIT = 4

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = orders.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	useEffect(() => {
		if (currentUser?.roles.includes("ADMIN")) {
			getPayments().then((res) => {
				let mergedOrders = [].concat.apply(
					[],
					res.map((item) => item.products)
				)

				if (currentUser?.roles?.includes("ADMIN")) {
					setOrders(mergedOrders)
				} else {
					let currentUserOrders = mergedOrders.filter((item) => {
						if (item.userId === currentUser?.uid) {
							return item
						}
					})
					setOrders(currentUserOrders)
				}
			})
		}
	}, [])

	console.log(currentData, "currentData")

	useEffect(() => {
		getUser(currentUser?.uid)
			.then((data) => {
				setUser(data[0])
			})
			.catch((err) => err)
	}, [currentUser?.uid])

	return (
		<div>
			<div className="sales_container">
				<div className="area_graph_card">
					<div className="area_graph_button_container">
						<button>Income</button>
						{/* <button>Expenses</button> */}
					</div>

					<AreaChart
						width={550}
						height={300}
						data={graphData}
						margin={{ top: 25, right: 25, left: 25, bottom: 25 }}
					>
						<defs>
							<linearGradient
								id="colorPv"
								x1="0"
								y1="0"
								x2="0"
								y2="1"
							>
								<stop
									offset="5%"
									stopColor="rgba(166, 50, 223, 0.2)"
									stopOpacity={0.8}
								/>
								<stop
									offset="95%"
									stopColor="#8884d8"
									stopOpacity={0}
								/>
							</linearGradient>
						</defs>
						<XAxis dataKey="name" />
						<YAxis />
						<CartesianGrid horizontal vertical={false} />
						<Tooltip
							content={CustomTooltip}
							contentStyle={{
								backgroundColor: "#7A1FDD",
								borderRadius: "25%",
							}}
						/>
						<Area
							type="monotone"
							dataKey="pv"
							stroke="#8884d8"
							fillOpacity={1}
							fill="url(#colorPv)"
						/>
					</AreaChart>
				</div>
				<div className="sales_forecast_container">
					{SalesForecastData.map((data, key) => (
						<div key={key} className="sales_forcast_card">
							<div className="card_top">
								<p style={{ fontSize: "0.75rem" }}>
									{data.label}
								</p>
								<p>{data.value}</p>
							</div>
							<div className="card_bottom">
								<AreaChart
									width={200}
									height={60}
									data={graphData}
								>
									<defs>
										<linearGradient
											id="colorPv"
											x1="0"
											y1="0"
											x2="0"
											y2="1"
										>
											<stop
												offset="5%"
												stopColor="rgba(166, 50, 223, 0.2)"
												stopOpacity={0.5}
											/>
											<stop
												offset="95%"
												stopColor="#8884d8"
												stopOpacity={0}
											/>
										</linearGradient>
									</defs>
									<Area
										type="monotone"
										dataKey="pv"
										stroke="#8884d8"
										fillOpacity={1}
										fill="url(#colorPv)"
									/>
								</AreaChart>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="recent_order_container">
				Recent Orders
				<div className="recent_orders">
					<table>
						<tr>
							{ordersTableHeader.map((header) => (
								<th key={header}>{header}</th>
							))}
						</tr>
						{currentData.length ? (
							currentData.map((item, index) => (
								<tr key={index}>
									<td>
										<img
											src={item.image}
											alt="product pic"
											style={{
												height: "100px",
												width: "100%",
												objectFit: "contain",
											}}
										/>
									</td>
									<td>{item.title}</td>
									<td>{item.productId}</td>
									<td>
										{user &&
										user?.firstName &&
										user?.lastName
											? user?.firstName +
											  " " +
											  user?.lastName
											: item.userId}
									</td>
									<td>{formatNumber(item.price)} </td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={3}>
									{orders.length !== 0 ? (
										<Loader />
									) : (
										<div>No Recent Orders Found</div>
									)}
								</td>
							</tr>
						)}
					</table>
				</div>
				{NUM_OF_RECORDS > 5 ? (
					<div className="pagination-wrapper">
						<Paginations
							totalRecords={NUM_OF_RECORDS}
							pageLimit={LIMIT}
							pageNeighbours={2}
							onPageChanged={onPageChanged}
							currentPage={currentPage}
						/>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default Sales
