export const formatNumber = (number) => {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "INR",
	}).format(number)
}

export const Average = (arr) => {
	let a = 0
	arr.forEach((item) => (a += item))
	let average = a / arr.length

	return Math.round(average)
}
