import "./DropDownStyles.css"

import React, { useState, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import { useNavigate } from "react-router-dom"
import { FaShoppingBag, FaSignOutAlt, FaUser } from "react-icons/fa"
import { useCart } from "../../../hooks/useCart"
import { logout } from "../../../services/AuthService"
import { useAuthValue } from "../../../contexts/AuthContext/AuthContext"

function DropDown({ icon }) {
	return (
		<NavItem icon={icon}>
			<DropdownMenu />
		</NavItem>
	)
}

function NavItem(props) {
	const [open, setOpen] = useState(false)

	return (
		<div className="nav-item" onClick={() => setOpen(!open)}>
			{props.icon}
			{open && props.children}
		</div>
	)
}

function DropdownMenu() {
	const [activeMenu, setActiveMenu] = useState("main")
	const { handleLogout } = useCart()
	const { setCurrentUser } = useAuthValue()

	const dropdownRef = useRef(null)
	let navigate = useNavigate()

	const DropdownItem = (props) => {
		return (
			<div
				className="menu-item"
				onClick={() => {
					if (props.goToMenu === "logout") {
						setCurrentUser(null)
						handleLogout()
						logout()
						sessionStorage.clear()
						localStorage.clear()
						navigate("/login")
					} else {
						navigate(props.goToMenu)
						props.goToMenu && setActiveMenu(props.goToMenu)
					}
				}}
			>
				<span className="icon-button">{props.leftIcon}</span>
				{props.children}
				<span className="icon-right">{props.rightIcon}</span>
			</div>
		)
	}

	return (
		<div className="dropdown" ref={dropdownRef}>
			<CSSTransition
				in={activeMenu === "main"}
				timeout={500}
				unmountOnExit
			>
				<div className="menu">
					<DropdownItem
						leftIcon={<FaUser className="menu-item" />}
						goToMenu="profile"
					>
						My Profile
					</DropdownItem>
					<DropdownItem
						leftIcon={<FaShoppingBag />}
						goToMenu="orders"
					>
						Orders
					</DropdownItem>
					<DropdownItem leftIcon={<FaSignOutAlt />} goToMenu="logout">
						Sign Out
					</DropdownItem>
				</div>
			</CSSTransition>
		</div>
	)
}

export default DropDown
