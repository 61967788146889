import { useContext } from "react"
import CartContext from "../contexts/CartContext/CartContext"

export const useCart = () => {
	const ctx = useContext(CartContext)

	return {
		...ctx,
	}
}
