import React from "react";
import "./Headline.css";

const Headline = ({ bgText, mainText, subText }) => {
	return (
		<div className="headline_title">
			<p className="bgText">{bgText}</p>
			<b className="mainText">{mainText}</b>
			<p className="subText">{subText}</p>
		</div>
	);
};

export default Headline;
