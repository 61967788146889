import React, { useEffect, useState } from "react"
import ProductHeading from "./ProductHeading/ProductHeading"
import ImageGallery from "./ImageGallary/ImageGallery"
import ProductOverview from "./ProductOverview/ProductOverview"
import ProductHighlight from "./ProductHighlights/ProductHighlight"
import Compatibility from "./Compatibility/Compatibility"
import Headline from "../../HomePage/Components/Headline/Headline"
import { useParams } from "react-router-dom"
import MoreProducts from "../../CartPage/Components/MoreProducts/MoreProducts"
import { useProducts } from "../../../hooks/useProducts"
import MarkDown from "./MarkDownPreview/MarkDown"

const BuyCollection = () => {
	const { products } = useProducts()
	const [product, setProduct] = useState({})
	const params = useParams()

	useEffect(() => {
		let tempProduct = products.filter((item) => {
			if (item.productId === params.id) {
				return item
			}
		})
		setProduct(tempProduct[0])
		window.scrollTo(0, 0)
	}, [params.id, product, products])

	return (
		<div>
			<ProductHeading product={product} />
			<ImageGallery data={product} />
			<MarkDown product={product} />
			<ProductOverview data={product} />
			<ProductHighlight data={product} />
			<Compatibility data={product} />
			<Headline
				bgText="product"
				mainText="More product"
				subText="Around Our Agency"
			/>
			<MoreProducts />
		</div>
	)
}

export default BuyCollection
