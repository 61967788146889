import { doc, updateDoc } from "firebase/firestore"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useCallback } from "react"
import { FiEdit2, FiPlusCircle } from "react-icons/fi"
import { RiDeleteBinLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { tableHeaders } from "../../../../constants/constants"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"
import { removeProduct } from "../../../../services/CartService"
import { db } from "../../../../services/firebase"
import {
	getProducts,
	getProductsByUserId,
} from "../../../../services/OtherServices"
import Loader from "../../../../UI/Loader/Loader"
import Paginations from "../../../../UI/Pagination/Pagination"

import "./ProjectsTable.css"

const ProjectsTable = () => {
	let navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [tableData, setTableData] = useState([])
	const { currentUser } = useAuthValue()

	const [currentPage, setCurrentPage] = useState(1)
	let NUM_OF_RECORDS = tableData.length
	let LIMIT = 7

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = tableData.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	useEffect(() => {
		if (currentUser?.roles?.includes("ADMIN")) {
			getProducts()
				.then((res) => {
					setTableData(res)
				})
				.catch((err) => {
					enqueueSnackbar(err, {
						variant: "error",
					})
				})
		} else {
			getProductsByUserId(currentUser?.uid)
				.then((res) => {
					setTableData(res)
				})
				.catch((err) => {
					enqueueSnackbar(err, {
						variant: "error",
					})
				})
		}
	}, [currentUser?.roles, currentUser?.uid, enqueueSnackbar])

	const onDeleteClick = (id) => {
		removeProduct(id)
		enqueueSnackbar("Item removed from cart", {
			variant: "info",
		})
		let tempArray = getProducts()
		tempArray.then((res) => setTableData(res))
	}

	const updateStatus = async (product, status) => {
		product["status"] = status

		try {
			await updateDoc(
				doc(db, "products", product.productId),
				product
			).then(() => {
				getProducts()
					.then((res) => {
						setTableData(res)
					})
					.catch((err) => {
						enqueueSnackbar(err, {
							variant: "error",
						})
					})
				enqueueSnackbar("status updated", {
					variant: "success",
				})
			})
		} catch (err) {
			enqueueSnackbar(err, {
				variant: "error",
			})
		}
	}

	return (
		<div className="project_container">
			<div className="project_actions">
				<h2>Projects</h2>
				<button onClick={() => navigate("/admin/sub/addproject")}>
					<FiPlusCircle className="action_btn_icon" />
					Add Project
				</button>
			</div>
			<div className="project_table">
				<table>
					<thead style={{ backgroundColor: "#454FDEA1" }}>
						<tr>
							{tableHeaders.map((header) => (
								<th key={header}>{header}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentData.length ? (
							currentData.map((project, key) => (
								<tr key={project.productId}>
									<td>{project.title}</td>
									<td>{project.subtitle}</td>
									<td>{project.status}</td>
									<td>
										{currentUser?.roles?.includes(
											"ADMIN"
										) && (
											<>
												<button
													style={{
														backgroundColor: "blue",
													}}
													onClick={() =>
														updateStatus(
															project,
															"APPROVED"
														)
													}
												>
													APPROVE
												</button>
												<button
													style={{
														backgroundColor: "red",
													}}
													onClick={() =>
														updateStatus(
															project,
															"REJECTED"
														)
													}
												>
													REJECT
												</button>
											</>
										)}
										<button
											onClick={() =>
												navigate(
													`/admin/sub/editproject/${project.productId}`,
													{
														state: {
															project,
														},
													}
												)
											}
										>
											<FiEdit2
												size={16}
												color="#6A8DF3"
											/>
										</button>
										<button
											onClick={() =>
												onDeleteClick(project.productId)
											}
										>
											<RiDeleteBinLine
												size={16}
												color="#6A8DF3"
											/>
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={3}>
									{tableData.length !== 0 ? (
										<Loader />
									) : (
										"No Projects Found"
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<div className="pagination-wrapper">
				<Paginations
					totalRecords={NUM_OF_RECORDS}
					pageLimit={LIMIT}
					pageNeighbours={2}
					onPageChanged={onPageChanged}
					currentPage={currentPage}
				/>
			</div>
		</div>
	)
}

export default ProjectsTable
