import ServiceCards from "./ServiceCard/ServiceCards"
import "./Services.css"
import "./ServiceCard/ServiceCard.css"
import stripe2 from "../../../../Assets/HomePageAssets/LandingPageAssets/strip2.svg"
import stripe1 from "../../../../Assets/HomePageAssets/LandingPageAssets/strip1.svg"
import servicePNG from "../../../../Assets/HomePageAssets/ServiceAssets/ServicePNG.webp"
import { data } from "./ServiceCard/servicesData"
import Headline from "../Headline/Headline"

function Services() {
	return (
		<div className="service_container" id="services">
			<Headline
				bgText="our"
				mainText="Our Services"
				subText="Around Our Agency"
			/>
			<div className="services">
				<img
					className="bgstrip1"
					src={stripe1}
					alt="background_graphic"
				/>
				<img
					className="service-man"
					src={servicePNG}
					alt="serviceman"
				/>
				{data.map((elem, index) => (
					<div key={index} className="service-layout">
						<ServiceCards elem={elem} />
					</div>
				))}
				<img
					className="bgstrip2"
					src={stripe2}
					alt="background_graphic"
				/>
			</div>
		</div>
	)
}

export default Services
