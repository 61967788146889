import { useEffect, useState } from "react"
import Loader from "../../../UI/Loader/Loader"
import { subscriptionsHeader } from "../../../constants/constants"
import { getSubscriptions } from "../../AdminServices/AdminServices"
import { useCallback } from "react"
import Paginations from "../../../UI/Pagination/Pagination"

const Subscriptions = () => {
	const [tableData, setTableData] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	let NUM_OF_RECORDS = tableData.length
	let LIMIT = 7

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = tableData.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	useEffect(() => {
		getSubscriptions()
			.then((res) => {
				setTableData(res)
			})
			.catch((err) => {
				alert(err)
			})
	}, [])

	return (
		<div className="teams_container">
			<div className="teams_actions">
				<h2>Subscriptions</h2>
			</div>
			<div className="teams_table">
				<table>
					<tr>
						{subscriptionsHeader.map((header) => (
							<th key={header}>{header}</th>
						))}
					</tr>
					{currentData.length ? (
						currentData.map((item) => (
							<tr key={item.email}>
								<td>{Date(item.date)}</td>
								<td>{item.email}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={3}>
								<Loader />
							</td>
						</tr>
					)}
				</table>
			</div>
			{NUM_OF_RECORDS > 5 ? (
				<div className="pagination-wrapper">
					<Paginations
						totalRecords={NUM_OF_RECORDS}
						pageLimit={LIMIT}
						pageNeighbours={2}
						onPageChanged={onPageChanged}
						currentPage={currentPage}
					/>
				</div>
			) : null}
		</div>
	)
}

export default Subscriptions
