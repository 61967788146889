import { doc, updateDoc } from "firebase/firestore"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { useEffect, useState } from "react"
import { FiEdit2, FiPlusCircle } from "react-icons/fi"
import { RiDeleteBinLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { blogsTableHeaders } from "../../../constants/constants"
import { useAuthValue } from "../../../contexts/AuthContext/AuthContext"
import { db } from "../../../services/firebase"
import Loader from "../../../UI/Loader/Loader"
import Paginations from "../../../UI/Pagination/Pagination"
import { ReadMore } from "../../../UI/ReadMore/ReadMore"
import {
	getBlogs,
	getBlogsByUserId,
	removeBlog,
} from "../../AdminServices/AdminServices"

const BlogList = () => {
	let navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [tableData, setTableData] = useState([])
	const { currentUser } = useAuthValue()

	const [currentPage, setCurrentPage] = useState(1)
	let NUM_OF_RECORDS = tableData.length
	let LIMIT = 5

	const onPageChanged = useCallback(
		(event, page) => {
			event.preventDefault()
			setCurrentPage(page)
		},
		[setCurrentPage]
	)
	const currentData = tableData.slice(
		(currentPage - 1) * LIMIT,
		(currentPage - 1) * LIMIT + LIMIT
	)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		if (currentUser?.roles?.includes("ADMIN")) {
			getBlogs()
				.then((res) => {
					setTableData(res)
				})
				.catch((err) => {
					enqueueSnackbar(err, {
						variant: "error",
					})
				})
		} else {
			getBlogsByUserId(currentUser?.uid)
				.then((res) => {
					setTableData(res)
				})
				.catch((err) => {
					enqueueSnackbar(err, {
						variant: "error",
					})
				})
		}
	}, [currentUser?.roles, currentUser?.uid, enqueueSnackbar])

	const onDeleteClick = (id) => {
		removeBlog(id)
		enqueueSnackbar("blog deleted!", {
			variant: "info",
		})
		let tempArray = getBlogs()
		tempArray.then((res) => setTableData(res))
	}

	const updateBlogStatus = async (blog, status) => {
		if (status === "PUBLISH") blog["publish"] = true
		else blog["publish"] = false

		try {
			await updateDoc(doc(db, "blogs", blog.id), blog).then(() => {
				getBlogs()
					.then((res) => {
						setTableData(res)
					})
					.catch((err) => {
						enqueueSnackbar(err, {
							variant: "error",
						})
					})
				enqueueSnackbar(`Blog ${status}ED!`, {
					variant: `${blog["publish"] ? "success" : "info"}`,
				})
			})
		} catch (err) {
			enqueueSnackbar(err, {
				variant: "error",
			})
		}
	}

	return (
		<div className="teams_container">
			<div className="teams_actions">
				<h2>Blogs</h2>
				<button onClick={() => navigate("/admin/sub/createBlog")}>
					<FiPlusCircle className="action_btn_icon" />
					Add Blog
				</button>
			</div>
			<div className="teams_table">
				<table>
					<thead style={{ backgroundColor: "#454FDEA1" }}>
						<tr>
							{blogsTableHeaders.map((header) => (
								<th key={header}>{header}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentData.length ? (
							currentData.map((blog, key) => (
								<tr key={blog.productId}>
									<td>{blog.title}</td>
									<td>
										<ReadMore size={5}>
											{blog.subtitle}
										</ReadMore>
									</td>
									<td>
										<a
											href={blog.coverImageUrl}
											style={{ textDecoration: "none" }}
										>
											Cover Image
										</a>
									</td>
									<td>
										{currentUser?.roles?.includes(
											"ADMIN"
										) && (
											<>
												{blog.publish ? (
													<button
														style={{
															backgroundColor:
																"red",
														}}
														onClick={() =>
															updateBlogStatus(
																blog,
																"UNPUBLISH"
															)
														}
													>
														UNPUBLISH
													</button>
												) : (
													<button
														style={{
															backgroundColor:
																"blue",
														}}
														onClick={() =>
															updateBlogStatus(
																blog,
																"PUBLISH"
															)
														}
													>
														PUBLISH
													</button>
												)}
											</>
										)}
									</td>
									<td>
										{new Date(
											blog.created?.toDate()
										).toLocaleDateString("en-us", {
											weekday: "short",
											year: "numeric",
											month: "short",
											day: "numeric",
										})}
									</td>
									<td>
										{blog.updated
											? new Date(
													blog.updated?.toDate()
											  ).toLocaleDateString("en-us", {
													weekday: "short",
													year: "numeric",
													month: "short",
													day: "numeric",
											  })
											: "-"}
									</td>
									<td>
										<button
											onClick={() =>
												navigate(
													`/admin/sub/editblog/${blog.id}`,
													{
														state: {
															blog,
														},
													}
												)
											}
										>
											<FiEdit2
												size={16}
												color="#6A8DF3"
											/>
										</button>
										<button
											onClick={() =>
												onDeleteClick(blog.id)
											}
										>
											<RiDeleteBinLine
												size={16}
												color="#6A8DF3"
											/>
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={3}>
									{tableData.length !== 0 ? (
										<Loader />
									) : (
										"No Blogs Found"
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<div className="pagination-wrapper">
				<Paginations
					totalRecords={NUM_OF_RECORDS}
					pageLimit={LIMIT}
					pageNeighbours={2}
					onPageChanged={onPageChanged}
					currentPage={currentPage}
				/>
			</div>
		</div>
	)
}

export default BlogList
