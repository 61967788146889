import {
	ADD_FAVOURITE_ITEM,
	ADD_ITEM,
	CHECKOUT,
	CLEAR,
	INITIALIZE_CART,
	REMOVE_FAVOURITE_ITEM,
	REMOVE_ITEM,
	LOGOUT,
} from "./CartTypes"

export const sumItems = (items) => {
	let itemCount = items.reduce((total) => total + 1, 0)

	let total = items
		.reduce((total, product) => total + product.price * 1, 0)
		.toFixed(2)

	return { itemCount, total }
}

export const sumFavItems = (favItems) => {
	let favItemCount = favItems.reduce((total) => total + 1, 0)

	return { favItemCount }
}

export const CartReducer = (state, action) => {
	const { cartItems, favItems } = state

	switch (action.type) {
		case ADD_ITEM:
			if (
				!cartItems.find(
					(item) => item.productId === action.payload.productId
				)
			) {
				cartItems.push({
					...action.payload,
				})
			}

			return {
				...state,
				...sumItems(cartItems),
				cartItems: [...cartItems],
			}
		case REMOVE_ITEM:
			return {
				...state,
				...sumItems(
					cartItems.filter(
						(item) => item.productId !== action.payload.productId
					)
				),
				cartItems: [
					...cartItems.filter(
						(item) => item.productId !== action.payload.productId
					),
				],
			}
		case ADD_FAVOURITE_ITEM:
			if (
				!favItems?.find(
					(item) => item.productId === action.payload.productId
				)
			) {
				favItems.push({
					...action.payload,
				})
			}

			return {
				...state,
				...sumFavItems(favItems),
				favItems: [...favItems],
			}
		case REMOVE_FAVOURITE_ITEM:
			return {
				...state,
				...sumFavItems(
					favItems.filter(
						(item) => item.productId !== action.payload.productId
					)
				),
				favItems: [
					...favItems.filter(
						(item) => item.productId !== action.payload.productId
					),
				],
			}
		case CHECKOUT:
			return {
				...state,
				cartItems: [],
				checkout: true,
				...sumItems([]),
			}
		case CLEAR:
			return {
				...state,
				cartItems: [],
				...sumItems([]),
			}
		case INITIALIZE_CART:
			return {
				...action.payload,
			}
		case LOGOUT:
			return {
				favItems: [],
				cartItems: [],
				logout: true,
				...sumItems([]),
				...sumFavItems([]),
			}
		default:
			return state
	}
}
