import React from "react";
import "./Loader.css";

const Loader = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
			}}>
			<div className="loader"></div>
			<div style={{ marginTop: "2%" }}>Loading...</div>
		</div>
	);
};

export default Loader;
