import "./VerifyEmail.css"
import { useState, useEffect } from "react"

import { sendEmailVerification } from "firebase/auth"
import { useNavigate } from "react-router-dom"

import { auth } from "../../../../services/firebase"
import { useAuthValue } from "../../../../contexts/AuthContext/AuthContext"

function VerifyEmail() {
	const { currentUser, timeActive, setTimeActive } = useAuthValue()
	const [time, setTime] = useState(60)

	const navigate = useNavigate()

	useEffect(() => {
		const interval = setInterval(() => {
			currentUser
				?.reload()
				.then(() => {
					if (currentUser?.emailVerified) {
						clearInterval(interval)
						navigate("/")
					}
				})
				.catch((err) => {
					alert(err.message)
				})
		}, 1000)
	}, [navigate, currentUser])

	useEffect(() => {
		let interval = null
		if (timeActive && time !== 0) {
			interval = setInterval(() => {
				setTime((time) => time - 1)
			}, 1000)
		} else if (time === 0) {
			setTimeActive(false)
			setTime(60)
			clearInterval(interval)
		}
		return () => clearInterval(interval)
	}, [timeActive, time, setTimeActive])

	const resendEmailVerification = () => {
		sendEmailVerification(auth.currentUser)
			.then(() => {
				setTimeActive(true)
			})
			.catch((err) => {
				alert(err.message)
			})
	}

	return (
		<div className="verify-center" style={{ paddingTop: "7rem" }}>
			<div className="verifyEmail">
				<h1>Verify your Email Address</h1>
				<p>
					<strong>A Verification email has been sent to:</strong>
					<br />
					<span>{currentUser?.email}</span>
				</p>
				<span>
					Follow the instruction in the email to verify your account
				</span>
				<button onClick={resendEmailVerification} disabled={timeActive}>
					Resend Email {timeActive && time}
				</button>
				<button
					style={{
						marginLeft: 10,
					}}
					onClick={() => navigate("/")}
				>
					Home
				</button>
			</div>
		</div>
	)
}

export default VerifyEmail
