import React, { useState, useRef } from "react";
import "./ImageGallery.css";
import {
	RiLayoutGridFill,
	RiLayoutLeftFill,
	RiArrowRightLine,
	RiArrowLeftLine,
} from "react-icons/ri";

const ImageGallery = ({ data }) => {
	const imageRef = useRef(null);
	const [firstImage, setFirstImage] = useState(0);
	const [lastImage, setLastImage] = useState(6);
	const [grid, setGrid] = useState(false);

	let images = data?.gridImages?.map((item) => {
		return { image: item };
	});

	let currentImages = grid ? images?.slice(firstImage, lastImage) : images;

	const goBack = () => {
		if (firstImage !== 0) {
			setFirstImage(firstImage - 1);
			setLastImage(lastImage - 1);
		}
	};

	const goNext = () => {
		if (lastImage !== images.length) {
			setFirstImage(firstImage + 1);
			setLastImage(lastImage + 1);
		}
	};

	const scrollimg = (scrollOffset) => {
		imageRef.current.scrollLeft += scrollOffset;
	};

	if (currentImages?.length) {
		return (
			<div className="imageGallery">
				<button className="listView" onClick={() => setGrid(!grid)}>
					{grid ? (
						<RiLayoutLeftFill size={15} />
					) : (
						<RiLayoutGridFill size={15} />
					)}
				</button>
				<div className="Gallery">
					<div
						className="listView back"
						onClick={grid ? goBack : () => scrollimg(-500)}>
						<RiArrowLeftLine size={15} />
					</div>

					<div
						style={{ width: currentImages?.length > 4 ? "80rem" : "40rem" }}
						className={`${grid ? "GalleryGrid" : "GallerySlide"} `}
						ref={imageRef}>
						{currentImages?.map((image) => (
							<img
								src={image.image || "https://picsum.photos/1290/1320"}
								alt="product-images"
								key={image.image}
							/>
						))}
					</div>

					<div
						className="listView next"
						onClick={grid ? goNext : () => scrollimg(+500)}>
						<RiArrowRightLine size={15} />
					</div>
				</div>
			</div>
		);
	}
};

export default ImageGallery;
