import "./Getintouch.css"
import { useForm } from "react-hook-form"
import { db } from "../../../../../services/firebase"
import { addDoc, collection } from "firebase/firestore"
import { useSnackbar } from "notistack"

const Getintouch = () => {
	const { enqueueSnackbar } = useSnackbar()
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ mode: "onChange" })

	const onFormSubmit = async (message) => {
		try {
			await addDoc(collection(db, "messages"), message).then(() => {
				enqueueSnackbar("message sent successfully", {
					variant: "success",
				})
				reset({
					name: "",
					email: "",
					phone: "",
					subject: "",
					message: "",
				})
			})
		} catch (err) {
			enqueueSnackbar("error sending message", {
				variant: "error",
			})
		}
	}

	const formOptions = {
		name: { required: "Name is required" },
		email: { required: "Email is required" },
		phone: {
			required: "Phone is required",
			pattern: {
				value: /^(?:\+\d{2})?\d{10}(?:,(?:\+\d{2})?\d{10})*$/i,
				message: "Please enter a valid phone number",
			},
		},
		subject: { required: "Subject is required" },
		message: { required: "Message is required" },
	}

	return (
		<div className="getintouch">
			<p className="heading">Get in Touch</p>
			<p className="sub_heading">Ready to Get Started</p>
			<form className="form" onSubmit={handleSubmit(onFormSubmit)}>
				<div className="split_input">
					<div>
						<input
							type="text"
							name="name"
							placeholder="Name"
							{...register("name", formOptions.name)}
						/>
						<small className="text-danger">
							{errors?.name && errors.name.message}
						</small>
					</div>

					<div>
						<input
							type="email"
							name="email"
							placeholder="Email"
							{...register("email", formOptions.email)}
						/>
						<small className="text-danger">
							{errors?.email && errors.email.message}
						</small>
					</div>
				</div>

				<div className="split_input">
					<div>
						<input
							type="number"
							placeholder="Phone"
							name="phone"
							{...register("phone", formOptions.phone)}
						/>
						<small className="text-danger">
							{errors?.phone && errors.phone.message}
						</small>
					</div>
					<div>
						<input
							type="text"
							name="subject"
							placeholder="Subject"
							{...register("subject", formOptions.subject)}
						/>
						<small className="text-danger">
							{errors?.subject && errors.subject.message}
						</small>
					</div>
				</div>

				<div>
					<textarea
						className="message"
						rows={10}
						columns={12}
						name="message"
						placeholder="Type Message"
						{...register("message", formOptions.message)}
					/>
					<small className="text-danger">
						{errors?.message && errors.message.message}
					</small>
				</div>
				<div className="submit-button-div">
					<button className="formSubmit">Send Message</button>
				</div>
			</form>
		</div>
	)
}

export default Getintouch
