import "./FavCartPage.css"
import { useEffect } from "react"
import Headline from "../HomePage/Components/Headline/Headline"
import { useCart } from "../../hooks/useCart"
import EmptyCart from "../CartPage/Components/EmptyCart"
import FavCartList from "./FavCartList/FavCartList"

const FavCartPage = () => {
	const { favItems } = useCart()

	useEffect(() => {
		window.scrollTo(0, 0)
	})

	return (
		<>
			{favItems.length ? (
				<Headline
					bgText="Favourite"
					mainText="Favourite Cart"
					subText=""
				/>
			) : null}

			{favItems.length > 0 ? (
				<div className="shopping-cart">
					<FavCartList />
				</div>
			) : (
				<EmptyCart />
			)}
		</>
	)
}

export default FavCartPage
