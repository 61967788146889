import { Outlet } from "react-router-dom"
import Layout from "./Layout/Layout"

const AdminTab = () => {
	return (
		<div>
			<Layout>
				<Outlet />
			</Layout>
		</div>
	)
}

export default AdminTab
