import { useContext } from "react"
import { ProductsContext } from "../contexts/ProductContext/ProductContext"

export const useProducts = () => {
	const ctx = useContext(ProductsContext)

	return {
		...ctx,
	}
}
